import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import ShowIF from "../../Utils/ShowIF";
import { useTranslation } from "react-i18next";
import { convertCount2ToSeconds } from "../../Utils/MeetingFunctions";

function addIframesToLinks(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const links = Array.from(doc.querySelectorAll("a"));

  links.forEach((linkElement) => {
    const linkURL = linkElement.getAttribute("href");
    const iframe = createIframeForLink(linkURL);

    // Replace the link element with the iframe
    linkElement.parentNode.replaceChild(iframe, linkElement);
  });

  return doc.documentElement.outerHTML;
}

function createIframeForLink(linkURL) {
  const iframe = document.createElement("iframe");
  iframe.src = linkURL;
  iframe.width = "100%";
  iframe.height = "500px"; // Adjust the height as needed
  iframe.title = "Embedded Content";
  iframe.style.scrollSnapType = "none";
  iframe.style.border = "none";
  iframe.style.textAlign = "center";
  return iframe;
}

const ViewMeeting = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [t] = useTranslation("global");
  const [inputData, setInputData] = useState({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [slideTime, setSlideTime] = useState("");
  const [stepTitle, setStepTitle] = useState("");
  const [stepTitle1, setStepTitle1] = useState("");
  const [nextSlideTime, setNextSlideTime] = useState("");
  const [showNextCounter, setShowNextCounter] = useState(false);
  const [htmlString, setHtmlString] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getMeetingById = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/meetings/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response) {
          setInputData(response.data?.data);
          setHtmlString(
            response.data?.data?.steps[currentStepIndex]?.editor_content
          );
          setLoading(false);
        }
      } catch (error) {
        // console.error(error);
      }
    };
    getMeetingById();
  }, [id]);
  // Utility function to convert count2 to seconds
  function convertToSeconds(count, unit) {
    switch (unit) {
      case "hours":
        return count * 3600; // 1 hour = 3600 seconds
      case "minutes":
        return count * 60; // 1 minute = 60 seconds
      case "seconds":
        return count; // already in seconds
      default:
        throw new Error(`Unknown time unit: ${unit}`);
    }
  }
  useEffect(() => {
    if (inputData && inputData.steps && inputData.steps.length > 0) {
      if (
        inputData.steps[currentStepIndex] &&
        inputData.steps[currentStepIndex].time
      ) {
        const formattedStartTime = new Date(
          inputData.steps[currentStepIndex].time
        ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        setStartTime(formattedStartTime);

        setSlideTime(
          convertToSeconds(
            inputData.steps[currentStepIndex].count2,
            inputData.steps[currentStepIndex].time_unit
          )
        );

        setNextSlideTime(
          currentStepIndex < inputData.steps.length - 1
            ? inputData.steps[currentStepIndex + 1]?.count2 || ""
            : ""
        );
      }
      setStepTitle(
        currentStepIndex < inputData.steps.length - 1
          ? inputData.steps[currentStepIndex + 1]?.title || ""
          : ""
      );
      setStepTitle1(
        currentStepIndex < inputData.steps.length
          ? inputData.steps[currentStepIndex].title || ""
          : ""
      );
    }
  }, [inputData, currentStepIndex]);

  // Show preview of Links in Iframe:
  useEffect(() => {
    if (inputData && inputData.steps && inputData.steps.length > 0) {
      // const originialHtml = inputData.steps[currentStepIndex].editor_content;
      const originialHtml =
        inputData.steps[currentStepIndex]?.editor_content === null
          ? ""
          : inputData.steps[currentStepIndex].editor_content;
      const modifiedHtml = addIframesToLinks(originialHtml);
      setInputData((prevData) => ({
        ...prevData,
        steps: prevData.steps.map((step, index) => {
          if (index === currentStepIndex) {
            return {
              ...step,
              editor_content: modifiedHtml,
            };
          }
          return step;
        }),
      }));
    }
  }, [htmlString, currentStepIndex]);
  const previousPage = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handlenextPage = () => {
    if (currentStepIndex < inputData.steps.length - 1) {
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
      setShowNextCounter(false);
    } else {
      window.history.back();
    }
  };

  const GradientSvg = (
    <svg>
      <defs>
        <linearGradient id="your-unique-id" x1="1" y1="0" x2="1" y2="1">
          <stop offset="10%" stopColor="#F2E358" />
          <stop offset="90%" stopColor="#CB690F" />
        </linearGradient>
      </defs>
    </svg>
  );
  const GradientSlideSvg = (
    <svg>
      <defs>
        <linearGradient id="slide-unique-id" x1="0" y1="0" x2="1" y2="1">
          <stop offset="10%" stopColor="#5882F2" />
          <stop offset="90%" stopColor="#0FB8CB" />
        </linearGradient>
      </defs>
    </svg>
  );
  const goBackToMeeting = () => {
    window.history.back();
  };

  // function formatMinutesToMMSS(totalMinutes) {
  //   const minutes = Math.floor(totalMinutes);
  //   const seconds = Math.round((totalMinutes % 1) * 60);
  //   const formattedMinutes = minutes.toString().padStart(2, "0");
  //   const formattedSeconds = seconds.toString().padStart(2, "0");

  //   return `${formattedMinutes}:${formattedSeconds}`;
  // }

  const formatTimeDDHH = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const remainingSeconds = seconds % (24 * 60 * 60);
    const hours = Math.floor(remainingSeconds / (60 * 60));

    const formattedDays = days > 0 ? String(days).padStart(2, "0") : "00";
    const formattedHours = hours > 0 ? String(hours).padStart(2, "0") : "00";

    return `${formattedDays}d:${formattedHours}h`;
  };
  function formatTimeHHMM(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}h:${formattedMinutes}`;
  }

  function formatTimeMMSS(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Add leading zeros if needed
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  useEffect(() => {
    if (inputData) {
      const timeString = inputData?.status === "in_progress" ? inputData?.starts_at : inputData?.start_time
      // const timeString = inputData?.starts_at || inputData?.start_time;
      if (timeString) {
        const [h, m] = timeString.split(":").map(Number);
        setHours(h);
        setMinutes(m);
      }
    }
  }, [inputData]);

  const parseTimeTaken = (timeTaken) => {
    if (!timeTaken) {
      return;
    }
    let totalSeconds = 0;

    const parts = timeTaken.split(" - ");

    parts.forEach((part) => {
      const [value, unit] = part?.split(" ");

      switch (unit) {
        case "days":
        case "day":
          totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
          break;
        case "hours":
        case "hour":
          totalSeconds += parseInt(value, 10) * 3600;
          break;
        case "mins":
        case "min":
          totalSeconds += parseInt(value, 10) * 60;
          break;
        case "secs":
        case "sec":
          totalSeconds += parseInt(value, 10);
          break;
        default:
          totalSeconds += parseInt(value, 10) * 60;
          break;
      }
    });

    return totalSeconds;
  };
  const calculateEndDate = (steps, currentDate, startTime) => {
    if (!steps || !currentDate || !startTime) {
      return;
    }

    let totalDurationInMinutes = 0;

    steps?.forEach((step) => {
      if (step.time_taken !== "0 sec") {
        const totalSeconds = parseTimeTaken(step.time_taken);
        totalDurationInMinutes += totalSeconds / 60;
      } else {
        totalDurationInMinutes += step.count2; // assuming count2 is in minutes
      }
    });

    // Combine currentDate and startTime into a single moment object
    const startDateTime = moment(
      `${currentDate} ${startTime}`,
      "YYYY-MM-DD hh:mm:ss A" // Adjusting format to include AM/PM
    );

    // Add totalDurationInMinutes to the startDateTime
    const endDate = startDateTime.add(totalDurationInMinutes, "minutes");

    return endDate.format("YYYY-MM-DD"); // Formatting to include hours, minutes, and AM/PM
  };

  function calculateTotalStepTime(steps) {
    if(!steps) return
    return steps?.reduce((total, step) => {
      return total + convertCount2ToSeconds(step.count2,step?.time_unit);
    }, 0);
  }

  function addTimeToMeeting(meeting, totalStepTimeInSeconds) {
    if (!meeting || !totalStepTimeInSeconds) return null;
    const startDate = new Date(meeting?.date);
    let startTime = meeting?.starts_at || meeting?.start_time;

    // Convert start time to seconds and add to the date
    const [hours, minutes, seconds] = startTime?.split(":").map(Number);
    startDate.setHours(hours, minutes, seconds);

    // Add the total step time
    startDate.setSeconds(startDate.getSeconds() + totalStepTimeInSeconds);

    return startDate;
  }

  const totalStepTimeInSeconds = calculateTotalStepTime(inputData?.steps);
  const newMeetingStartTime = addTimeToMeeting(
    inputData,
    totalStepTimeInSeconds
  );
  function formatTime(date) {
    if (!date) return;
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}h${minutes}`;
  }

  const formatEndTime = (timeString) => {
    if(!timeString)return
    // Extract hours and minutes from the time string
    const [hours, minutes] = timeString.split(':');
    
    return `${hours}h${minutes}`;
  };
  const estimatedEndDate = inputData && inputData?.estimate_time?.split('T')[0]
  console.log('estimatedEndDate', estimatedEndDate)
  const timeOnly = inputData && inputData?.estimate_time?.split('T')[1]
  
  const estimatedEndTime = formatEndTime(timeOnly)
  console.log('estimatedEndTime', estimatedEndTime)
  // Format the date to a string for rendering
  const formattedMeetingStartTime = formatTime(newMeetingStartTime);

  return (
    <div className="preview">
      {loading ? (
        <Spinner
          animation="border"
          role="status"
          className="center-spinner"
        ></Spinner>
      ) : (
        <div className="container-fluid mx-auto card mt-3 mb-5 py-3">
          <div className="row">
            <button className="btn1 btn-primary" onClick={goBackToMeeting}>
              <AiOutlineArrowLeft /> &nbsp; {t("sortir")}
            </button>
          </div>
          <div className="text-center mb-4">
            <h3>{t("meetingViewTitle")}</h3> <br />
            <h4>
              {inputData.objective}&nbsp; {">"} &nbsp; {inputData.title}&nbsp;
              {" >"} &nbsp; {stepTitle1} &nbsp;{" "}
            </h4>
          </div>
          <div className="row justify-content-center ">
            <div className="col-md-8 ">
              <div className="card mt-4 mb-4">
                <ShowIF
                  condition={
                    inputData.steps[currentStepIndex].editor_type === "File"
                  }
                >
                  <div>
                    <iframe
                      src={
                        Assets_URL +
                        "/" +
                        inputData.steps[currentStepIndex].file
                      }
                      width="100%"
                      height="500px"
                    />
                  </div>
                </ShowIF>
                <ShowIF
                  condition={
                    inputData.steps[currentStepIndex].editor_type !== "File"
                  }
                >
                  <div className="card-body ">
                    <div
                      className="rendered-content"
                      dangerouslySetInnerHTML={{
                        __html:
                          // inputData &&
                          // inputData.steps &&
                          // inputData.steps[currentStepIndex] &&
                          // inputData.steps[currentStepIndex].editor_content
                          //   ? inputData.steps[currentStepIndex].editor_content
                          //   : "",
                          (inputData &&
                            inputData.steps &&
                            inputData.steps[currentStepIndex] &&
                            inputData.steps[currentStepIndex].editor_content !==
                              null) ||
                          inputData.steps[currentStepIndex].editor_content !==
                            ""
                            ? inputData.steps[currentStepIndex].editor_content
                            : " ",
                      }}
                    />
                  </div>
                </ShowIF>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center p-3">
                <div
                  className="pt-3"
                  style={{
                    zIndex: 0,
                  }}
                >
                  <CountdownCircleTimer
                    size={95}
                    strokeWidth={4}
                    isPlaying={true}
                    colors="url(#your-unique-id)"
                    onComplete={() => {}}
                  >
                    {({ remainingTime }) => (
                      <div className="text-center">
                        {/* <span className="start-at">{t("Start At")}</span> <br />
                        <h5>
                          {moment(inputData.start_time, "HH:mm").format(
                            "HH[h]mm"
                          )}

                        </h5> */}
                        <div className="justify-content-center flex-column d-flex align-items-center">
                          <span
                            className="start-at"
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            {inputData?.status === "in_progress" ? inputData?.current_date : inputData?.date}
                          </span>
                          <span
                            className="start-at"
                            style={{ fontSize: "10px" }}
                          >
                            {t("Start At")}
                          </span>
                          {hours}h{minutes > 9 ? minutes : "0" + minutes}
                        </div>
                      </div>
                    )}
                  </CountdownCircleTimer>
                </div>

                <div
                  className=""
                  style={{
                    zIndex: 5,
                  }}
                >
                  <CountdownCircleTimer
                    size={130}
                    strokeWidth={5}
                    isPlaying={true}
                    colors="url(#slide-unique-id)"
                    onComplete={() => {}}
                  >
                    {({ remainingTime }) => (
                      <div className="text-center">
                        <h4>
                          {/* {formatMinutesToMMSS(slideTime)} */}
                          {inputData &&
                          inputData.steps &&
                          inputData?.steps[currentStepIndex]?.time_unit ===
                            "days"
                            ? formatTimeDDHH(slideTime)
                            : inputData &&
                              inputData?.steps &&
                              inputData?.steps[currentStepIndex]?.time_unit ===
                                "hours"
                            ? formatTimeHHMM(slideTime)
                            : formatTimeMMSS(slideTime)}
                        </h4>
                        {/* <h6>min</h6> */}
                      </div>
                    )}
                  </CountdownCircleTimer>
                </div>

                <div className="pt-3">
                  <CountdownCircleTimer
                    size={95}
                    strokeWidth={4}
                    isPlaying={true}
                    colors="url(#your-unique-id)"
                    onComplete={() => {}}
                  >
                    {({ remainingTime }) => (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <span
                          className="start-at"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {/* {calculateEndDate(
                            inputData?.steps,
                            inputData?.current_date,
                            inputData?.start_time
                          )} */}
                          {estimatedEndDate}
                        </span>
                        <span className="start-at">
                          {t("Estimated End At")}
                        </span>{" "}
                        {/* <br /> */}
                        <h5>
                          {formattedMeetingStartTime}
                          {/* {moment(inputData.start_time, "HH:mm")
                            .add(inputData.total_time, "minutes")
                            .format("HH[h]mm")} */}
                        </h5>
                      </div>
                    )}
                  </CountdownCircleTimer>
                </div>
              </div>
              <br />
              {currentStepIndex !== inputData?.steps?.length - 1 ? (
                <>
                  <div className="mb-3">
                    <label className="form-label mb-3">
                      <img
                        src="/Assets/Ellipse 8.png"
                        className="img-fluid pb-1"
                        alt="circle"
                      />{" "}
                      &nbsp; {t("nextStep")}
                    </label>
                    <input
                      type="text"
                      className="form-control txt"
                      readOnly
                      value={stepTitle}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label mb-3">
                      <img
                        src="/Assets/Ellipse 8.png"
                        className="img-fluid pb-1"
                        alt="circle"
                      />{" "}
                      &nbsp; {t("nextStepTime")}
                    </label>
                    <input
                      type="text"
                      className="form-control txt"
                      readOnly
                      value={
                        Math.abs(nextSlideTime) +
                        " " +
                        t(
                          `time_unit.${
                            inputData?.steps[currentStepIndex + 1]?.time_unit
                          }`
                        )
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-center text-center mb-3 ">
                    {currentStepIndex > 0 && (
                      <button
                        className="btn btn-primary"
                        onClick={previousPage}
                      >
                        {t("Previous")}
                      </button>
                    )}{" "}
                    &nbsp; &nbsp; &nbsp;
                    <button
                      className={` btn ${
                        currentStepIndex === inputData?.steps?.length - 1
                          ? "btn-danger"
                          : "btn-primary"
                      }`}
                      onClick={handlenextPage}
                    >
                      {currentStepIndex === inputData?.steps?.length - 1
                        ? "Exit Preview"
                        : `${t("Next")}`}
                    </button>
                  </div>
                  <div className="mb-3">
                    <label className="form-label mb-3">
                      <img
                        src="/Assets/Ellipse 8.png"
                        className="img-fluid pb-1"
                        alt="circle"
                      />{" "}
                      &nbsp; {t("meetingViewNotes")}
                    </label>
                    <textarea className="form-control txt" rows="3"></textarea>
                  </div>
                </>
              ) : (
                <div className="mb-3">
                  <div className="d-flex justify-content-center text-center mb-3">
                    {currentStepIndex > 0 && (
                      <button
                        className="btn btn-primary"
                        onClick={previousPage}
                      >
                        {t("Previous")}
                      </button>
                    )}{" "}
                    &nbsp; &nbsp; &nbsp;
                    <button
                      className={` btn ${
                        currentStepIndex === inputData?.steps?.length - 1
                          ? "btn-danger"
                          : "btn-primary"
                      }`}
                      onClick={handlenextPage}
                    >
                      {currentStepIndex === inputData?.steps?.length - 1
                        ? "Exit Preview"
                        : "Suivant"}
                    </button>
                  </div>
                  <label className="form-label mb-3">
                    <img
                      src="/Assets/Ellipse 8.png"
                      className="img-fluid pb-1"
                      alt="circle"
                    />{" "}
                    &nbsp; {t("meetingViewNotes")}
                  </label>
                  <textarea className="form-control txt" rows="11"></textarea>
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      )}
      <div className="">
        {GradientSvg}
        {GradientSlideSvg}
      </div>
    </div>
  );
};
export default ViewMeeting;
