import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../Apicongfig";
import { Spinner, Tab } from "react-bootstrap";
import { useHeaderTitle } from "../../../../context/HeaderTitleContext";
import { useTranslation } from "react-i18next";
import Moments from "./Moments";
import Participants from "./Participants";
import AddDestination from "../AddDestination";
import moment from "moment";
import { MdEdit } from "react-icons/md";

const Tabs = () => {
  const { id } = useParams();
  const tabsRef = useRef(null);
  const { searchTerm } = useOutletContext();

  const { resetHeaderTitle } = useHeaderTitle();

  React.useEffect(() => {
    resetHeaderTitle();
  }, []);
  const location = useLocation();
  const [t] = useTranslation("global");
  const [isSticky, setIsSticky] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [destination, setDestination] = useState();
  const [participants, setParticipants] = useState([]);
  console.log("meetings by destination id-->", meetings);
  const [isLoading, setIsLoading] = useState(false);
  console.log("meetings by participants id-->", participants);
  const [show, setShow] = useState(false);
  const [firstMeetingDate, setFirstMeetingDate] = useState(null);
  const [lastMeetingDate, setLastMeetingDate] = useState(null);
  const toggle = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const getMeetingsByDestinationId = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-destination-with-meeting/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data;
        console.log('data', data);

        setDestination(data);
        setMeetings(data?.meetings);

        // Sort meetings by created_at date
        const sortedMeetings = data?.meetings?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        // Get the first created meeting
        const firstMeeting = sortedMeetings[0];
        setFirstMeetingDate(firstMeeting?.created_at);

        // Sort meetings by created_at date in descending order for newest meeting
        const sortedMeetingsDesc = data?.meetings?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        // Get the newest created meeting
        const newestMeeting = sortedMeetingsDesc[0];
        setLastMeetingDate(newestMeeting?.created_at);

        // Extract participants from each meeting and filter out creators
        // const allParticipants = data?.meetings?.flatMap((meeting) =>
        //   meeting.participants.filter(
        //     (participant) => participant.isCreator !== 1
        //   )
        // );

        // // Combine participants for meetings with the same objective and remove duplicates
        // const combinedParticipants = allParticipants?.reduce(
        //   (acc, participant) => {
        //     const key = `${participant.email}`;
        //     if (!acc[key]) {
        //       acc[key] = { ...participant, meeting_count: 1 };
        //     } else {
        //       acc[key].meeting_count += 1;
        //     }
        //     return acc;
        //   },
        //   {}
        // );
// Check if any meeting has a status of "draft"
// const hasDraftMeeting = data?.meetings?.some(meeting => meeting.status === "draft");
        const allParticipants = data?.participant?.filter((participant) => participant.isCreator !== 1);
        console.log('allParticipants', allParticipants)
        setParticipants(allParticipants);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const refreshedParticipants = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-destination-with-meeting/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data;
        console.log('data', data);
        setDestination(data);
        setMeetings(data?.meetings);

        // Sort meetings by created_at date
        const sortedMeetings = data?.meetings?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        // Get the first created meeting
        const firstMeeting = sortedMeetings[0];
        setFirstMeetingDate(firstMeeting?.created_at);

        // Sort meetings by created_at date in descending order for newest meeting
        const sortedMeetingsDesc = data?.meetings?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        // Get the newest created meeting
        const newestMeeting = sortedMeetingsDesc[0];
        setLastMeetingDate(newestMeeting?.created_at);

        // Extract participants from each meeting and filter out creators
        // const allParticipants = data?.meetings?.flatMap((meeting) =>
        //   meeting.participants.filter(
        //     (participant) => participant.isCreator !== 1
        //   )
        // );

        // // Combine participants for meetings with the same objective and remove duplicates
        // const combinedParticipants = allParticipants?.reduce(
        //   (acc, participant) => {
        //     const key = `${participant.email}-${participant.post}`;
        //     if (!acc[key]) {
        //       acc[key] = { ...participant, meeting_count: 1 };
        //     } else {
        //       acc[key].meeting_count += 1;
        //     }
        //     return acc;
        //   },
        //   {}
        // );
const allParticipants = data?.participant?.filter(participant => participant.isCreator !== 1);
        setParticipants(allParticipants);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getMeetingsByDestinationId();
    // refreshedParticipants()
  }, [id]);

  const [activeTab, setActiveTab] = useState("Moments");
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  // Filter out meetings that are not closed
  const openMeetings = meetings?.filter(
    (meeting) => meeting.status !== "closed" && meeting.status !== "abort" && meeting.status !== "draft"
  );

  // Determine if any meetings are late

  // const isAnyMeetingLate = openMeetings?.some((meeting) =>
  //   moment().isAfter(
  //     moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
  //   )
  // );
  
  // Determine if any meetings are in progress
const isAnyMeetingInProgress = openMeetings?.some(
  (meeting) => meeting.status === "in_progress"
);

// Determine if all meetings are closed or aborted
const areAllMeetingsClosed = meetings?.every(
  (meeting) => meeting.status === "closed" || meeting.status === "abort" 
);

// Determine if there is only one meeting that is in progress
const isOnlyOneMeetingInProgress = openMeetings?.length === 1 && openMeetings[0].status === "in_progress";

// Determine if any meetings are late
const isAnyMeetingLate = !isAnyMeetingInProgress && openMeetings?.some(
  (meeting) =>
    moment().isAfter(moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm"))
);

// Determine the overall badge to show
const overallBadge = areAllMeetingsClosed
  ? "active"
  : isAnyMeetingInProgress
  ? "inProgress"
  : isAnyMeetingLate
  ? "active"
  : "active";

const badgeText = areAllMeetingsClosed
  ? t("badge.finished")
  : isAnyMeetingInProgress
  ? t("badge.inprogress")
  : isAnyMeetingLate
  ? t("badge.active")
  : t("badge.active");
// const overallBadge = areAllMeetingsClosed
//   ? "finished"
//   : isAnyMeetingLate
//   ? "late"
//   : "active";

// const badgeText = areAllMeetingsClosed
//   ? t("badge.finished")
//   : isAnyMeetingLate
//   ? t("badge.late")
//   : t("badge.active");
  // // Determine if any meetings are late
  // const isAnyMeetingLate = meetings?.some((meeting) =>
  //   moment().isAfter(
  //     moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
  //   )
  // );

  // // Determine the overall badge to show
  // const overallBadge = isAnyMeetingLate ? "late" : "active";
  // const badgeText = isAnyMeetingLate ? t("badge.late") : t("badge.active");

  const meetingsCount = meetings?.filter(item=>item.status !== "draft")?.length;
  const participantsCount = participants?.length;

  // Determine if there is only one meeting and it is in draft
const isSingleDraftMeeting =
meetings?.length === 1 && meetings[0].status === "draft";
  return (
    <>
      {isLoading ? (
        <>
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        </>
      ) : (
        <div className="destination-to-meeting-container container-fluid">
          <div className="destination-data">
            <div className="row first-row">
              <div className="col-md-8">
                <span className="d-flex align-items-center">
                  {destination?.destination_name}
                  {meetingsCount > 0 && (
                    <>
                    {/* // <span className="destination_status"> */}
                      <span
                        className={`badge ms-2 ${overallBadge}`}
                        // style={{ padding: "3px 8px 3px 8px" }}
                      >
                        {badgeText}
                      </span>
                    </>

                    // {/* </span> */}
                  )}
                </span>
              </div>
              {/* <div
                className="col-md-4 d-flex justify-content-end"
                style={{ height: "53px" }}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggle(destination);
                  }}
                >
                  <MdEdit size={20}/>
                </button>
              </div> */}
            </div>
            <div className="row second-row">
              <div className="col-md-12">
              {/* {(destination?.meeting_start_date || destination?.meeting_end_date) &&  <span>
                  {destination?.meeting_start_date} -{" "}
                  {destination?.meeting_end_date}
                </span>} */}
                {!isSingleDraftMeeting &&
                (destination?.meeting_start_date ||
                  destination?.meeting_end_date) && (
                  <span>
                    {destination?.meeting_start_date} -{" "}
                    {destination?.meeting_end_date}
                  </span>
                )}
              </div>
            </div>
            <div className="row third-row">
              <div className="col-md-12">
                <p>{destination?.destination_description}</p>
              </div>
            </div>
          </div>

          <div className="destination-tabs-container">
            <div
              ref={tabsRef}
              className={`tabs-header ${isSticky ? "sticky" : ""}`}
            >
              {/* <h4 className="meeting-title">{t("destination.title")}</h4> */}
              <div className="row">
                <div
                  className="col-md-12 col-12 destination-tab-row"
                  style={{ borderBottom: "2px solid #F2F2F2" }}
                >
                  <div className="tabs">
                    <div>
                      <button
                        className={`tab ${
                          activeTab === "Moments" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("Moments")}
                      >
                        {t("destination.destinationToMeeting.meetings")}
                        <span
                          className={
                            activeTab === "Moments" ? "future" : "draft"
                          }
                        >
                          {meetingsCount}
                        </span>
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "Participants" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("Participants")}
                      >
                        {t("destination.destinationToMeeting.participants")}
                        <span
                          className={
                            activeTab === "Participants" ? "future" : "draft"
                          }
                        >
                          {participantsCount}
                        </span>
                      </button>
                      {/* <button
                    className={`tab ${
                      activeTab === "Actions" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Actions")}
                  >
                    {t("destination.destinationToMeeting.actions")}
                  </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              {activeTab === "Moments" && (
                <div>
                  {
                    <Moments
                      meetings={meetings}
                      isLoading={isLoading}
                      refreshedMeetings={getMeetingsByDestinationId}
                      // allCurrentDestinations={filteredCurrentDestinations}
                    />
                  }
                </div>
              )}
              {activeTab === "Participants" && (
                <div>
                  <Participants
                    participants={participants}
                    refreshedParticipants={refreshedParticipants}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
          </div>

          {
            <AddDestination
              show={show}
              currentItem={destination}
              handleClose={handleClose}
              refreshedDestination={getMeetingsByDestinationId}
            />
          }
        </div>
      )}
    </>
  );
};

export default Tabs;
