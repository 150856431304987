import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import { Editor } from "@tinymce/tinymce-react";

function ModalEditor(props) {
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;

  return (
    <Modal
      {...props}
      size="lg"

      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: "500px",
          }}
        >
          {/* <ReactQuill
            className="h-75"
            value={props.defaultValue}
            onChange={(value) => {
              props.onChange(value);
            }}
          /> */}
          <Editor
            apiKey={TINYMCEAPI}
            value={props.defaultValue}
            // value={stepNotes?.join(" ")}
            init={{
              statusbar:false,

              branding: false,
              height: 500,
              menubar: true,
              language: "fr_FR",
              // language: "en_EN",
              plugins:
                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
              toolbar:
                "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
              image_advtab: true,
              file_picker_types: "image",

              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype === "image") {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");

                  input.onchange = function () {
                    const file = input.files[0];
                    const reader = new FileReader();

                    reader.onload = function (e) {
                      const img = new Image();
                      img.src = e.target.result;

                      img.onload = function () {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");
                        const maxWidth = 700;
                        const maxHeight = 394;

                        let newWidth = img.width;
                        let newHeight = img.height;

                        if (img.width > maxWidth) {
                          newWidth = maxWidth;
                          newHeight = (img.height * maxWidth) / img.width;
                        }

                        if (newHeight > maxHeight) {
                          newHeight = maxHeight;
                          newWidth = (img.width * maxHeight) / img.height;
                        }

                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        const resizedImageData = canvas.toDataURL(file.type);

                        // Pass the resized image data to the callback function
                        callback(resizedImageData, {
                          alt: file.name,
                        });
                      };

                      img.src = e.target.result;
                    };

                    reader.readAsDataURL(file);
                  };

                  input.click();
                }
              },
            }}
            onEditorChange={(value) => {
              props.onChange(value);
            }}
          />
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Cloturer</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ModalEditor;
