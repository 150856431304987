// import React from "react";
// import { useState } from "react";
// import Sidebar from "./Sidebar";
// import Header from "./Header";
// import { Outlet, useLocation, useNavigate } from "react-router-dom";

// function Base(props) {
//   const [isSidebarOpen, setSidebarOpen] = useState(true);
//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };
//   const location = useLocation();
//   const navigate = useNavigate();
//   console.log("location-base", location);
//   let fromMeeting = false;
//   if (location?.state?.from === "meeting") {
//     fromMeeting = true;
//   }
//   // URLs where the sidebar should be hidden
//   const hiddenSidebarPaths = [
//     "/ContractLinkEnterprises",
//     "/ContractToTeam",
//     "/ContractToUser",
//     "/EntreprisesToTeam",
//     "/EntreprisesToUsers",
//     "/view",
//     "/PlayMeeting",
//     "/play",
//     "/users",
//     "/ModifierUser",
//     "/updateMeeting",
//     "/copyMeeting",
//     "/participantToAction",
//     "/invite",
//     "/destination",
//   ];

//   // Determine if the current URL matches any in the hiddenSidebarPaths array
//   const shouldHideSidebar = hiddenSidebarPaths.some((path) =>
//     window.location.href.includes(path)
//   );
//   const [searchTerm, setSearchTerm] = useState("");

//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };

//   return (
//     <div className="dashboard-container">
//       <div
//         className="sidebar-container"
//         style={{
//           display: fromMeeting || !shouldHideSidebar ? "block" : "none",
//         }}
//       >
//         <Sidebar
//           isSidebarOpen={isSidebarOpen}
//           toggleSidebar={toggleSidebar}
//           isAuthenticated={props.isAuthenticated}
//           onLogin={props.onLogin}
//           onLogout={props.onLogout}
//           onRemove={props.onRemove}
//         />
//       </div>
//       <div
//         className={`main-content ${isSidebarOpen ? "" : "closed-sidebar"} ${
//           window?.location?.href?.includes("/ContractLinkEnterprises") ||
//           window?.location?.href?.includes("/ContractToTeam") ||
//           window?.location?.href?.includes("/ContractToUser") ||
//           window?.location?.href?.includes("/EntreprisesToTeam") ||
//           window?.location?.href?.includes("/EntreprisesToUsers") ||
//           window?.location?.href?.includes("/play") ||
//           window?.location?.href?.includes("/PlayMeeting") ||
//           window.location.href.includes("/view") ||
//           window.location.href.includes("/users") ||
//           window.location.href.includes("/ModifierUser") ||
//           window.location.href.includes("/updateMeeting") ||
//           window.location.href.includes("/copyMeeting") ||
//           window.location.href.includes("/participantToAction") ||
//           window.location.href.includes("/destination") ||
//           window.location.href.includes("/invite") && !fromMeeting
//             ? "mr-link"
//             : "mr-0"
//         }`}
//         style={{ marginLeft: fromMeeting && window.location.href.includes !== "/invite" && "60px ", backgroundColor: window.location.href.includes("customer-support") && "white" }}
//       >
//         <Header
//           onLogout={props.onLogout}
//           isAuthenticated={props.isAuthenticated}
//           onLogin={props.onLogin}
//           onRemove={props.onRemove}
//           onSearch={handleSearch}
//         />
//         <Outlet context={{ searchTerm }}/>
//       </div>
//     </div>
//   );
// }

// export default Base;
import React from "react";
import { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function Base(props) {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location-base", location);
  let fromMeeting = false;
  if (location?.state?.from === "meeting") {
    fromMeeting = true;
  }
  // URLs where the sidebar should be hidden
  const hiddenSidebarPaths = [
    "/ContractLinkEnterprises",
    "/ContractToTeam",
    "/ContractToUser",
    "/EntreprisesToTeam",
    "/EntreprisesToUsers",
    "/view",
    "/PlayMeeting",
    "/play",
    "/users",
    "/ModifierUser",
    "/updateMeeting",
    "/copyMeeting",
    "/participantToAction",
    "/invite",
    "/destination",
    "/heroes",
  ];

  // Determine if the current URL matches any in the hiddenSidebarPaths array
  const shouldHideSidebar = hiddenSidebarPaths.some((path) =>
    window.location.href.includes(path)
  );
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div className="dashboard-container">
      <div
        className="sidebar-container"
        style={{
          display: fromMeeting || !shouldHideSidebar ? "block" : "none",
        }}
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          isAuthenticated={props.isAuthenticated}
          onLogin={props.onLogin}
          onLogout={props.onLogout}
          onRemove={props.onRemove}
        />
      </div>
      <div
        className={`main-content ${isSidebarOpen ? "" : "closed-sidebar"} ${
          window?.location?.href?.includes("/ContractLinkEnterprises") ||
          window?.location?.href?.includes("/ContractToTeam") ||
          window?.location?.href?.includes("/ContractToUser") ||
          window?.location?.href?.includes("/EntreprisesToTeam") ||
          window?.location?.href?.includes("/EntreprisesToUsers") ||
          window?.location?.href?.includes("/play") ||
          window?.location?.href?.includes("/PlayMeeting") ||
          window.location.href.includes("/view") ||
          window.location.href.includes("/users") ||
          window.location.href.includes("/ModifierUser") ||
          window.location.href.includes("/updateMeeting") ||
          window.location.href.includes("/copyMeeting") ||
          window.location.href.includes("/participantToAction") ||
          window.location.href.includes("/destination") ||
          window.location.href.includes("/heroes") ||
          window.location.href.includes("/invite") && !fromMeeting
            ? "mr-link"
            : "mr-0"
        }`}
        style={{ marginLeft: fromMeeting && window.location.href.includes !== "/invite" && "60px ", backgroundColor: window.location.href.includes("customer-support") && "white" }}
      >
        <Header
          onLogout={props.onLogout}
          isAuthenticated={props.isAuthenticated}
          onLogin={props.onLogin}
          onRemove={props.onRemove}
          onSearch={handleSearch}
        />
        <Outlet context={{ searchTerm }}/>
      </div>
    </div>
  );
}

export default Base;