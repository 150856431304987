export const formatStepDate = (dateString) => {
  // Convert the date string into a Date object
  const dateObject = new Date(dateString);

  // Extract day, month, and year
  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = dateObject.getFullYear();

  // Return the formatted date string in dd/mm/yyyy format
  return `${day}/${month}/${year}`;
};

export const convertCount2ToSeconds = (count2, timeUnit) => {
  if (!count2 || !timeUnit) return;
  switch (timeUnit) {
    case "hours":
      return count2 * 3600;
    case "minutes":
      return count2 * 60;
    case "seconds":
      return count2;
    default:
      return 0;
  }
};

export const convertSecondsToDHMSFormat = (seconds) => {
  if (isNaN(seconds)) {
    console.error("seconds is NaN");
    return "NaN";
  }

  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${String(days).padStart(2, "0")}days:${String(hours).padStart(
    2,
    "0"
  )}hours:${String(minutes).padStart(2, "0")}mins:${String(seconds).padStart(
    2,
    "0"
  )}secs`;
};

export const formatTimeDifference = (difference) => {
  if (!difference) return;
  // Split the difference into days, hours, minutes, and seconds
  const [daysPart, hoursPart, minutesPart, secondsPart] = difference.split(":");
  const [days] = daysPart.split("days").map(Number);
  const [hours] = hoursPart.split("hours").map(Number);
  const [minutes] = minutesPart.split("mins").map(Number);
  const [seconds] = secondsPart.split("secs").map(Number);

  let result = "";

  if (days > 0) {
    result += `${days} days `;
    result += hours > 0 ? `${hours} hours` : "";
  } else if (hours > 0) {
    result += `${hours} hours `;
    result += minutes > 0 ? `${minutes} mins` : "";
  } else if (minutes > 0) {
    result += `${minutes} mins `;
    result += seconds > 0 ? `${seconds} secs` : "";
  } else if (seconds > 0) {
    result += `${seconds} secs`;
  }

  return result.trim();
};
export const calculateTimeDifference = (steps, starts_at, current_date) => {
  if (!starts_at || !current_date || !steps) return;

  const [time, meridiem] = starts_at.split(" ");
  let [currentHours, currentMinutes, currentSeconds] = time
    .split(":")
    .map(Number);

  if (meridiem) {
    if (meridiem.toLowerCase() === "pm" && currentHours < 12)
      currentHours += 12;
    if (meridiem.toLowerCase() === "am" && currentHours === 12)
      currentHours = 0;
  }

  const [day, month, year] = current_date.split("-").map(Number);
  const myDate = `${day}/${month}/${year}`;
  const currentDateTime = new Date(myDate);

  currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);

  const realCurrentTime = new Date();
  const diffInMilliseconds = realCurrentTime - currentDateTime;
  let diffInSeconds = 0;

  let count2InSeconds = 0;
  let timeTakenInSeconds = 0;
  steps.forEach((step) => {
    const { time_taken, step_status, count2, time_unit } = step;
    count2InSeconds = convertCount2ToSeconds(count2, time_unit);
    timeTakenInSeconds = parseTimeTaken(time_taken);
    if (step_status === "completed") {
      if (time_taken) {
        diffInSeconds += parseTimeTaken(time_taken);
      }
    } else if (step_status === "in_progress") {
      count2InSeconds = convertCount2ToSeconds(count2, time_unit);
      timeTakenInSeconds = parseTimeTaken(step?.time_taken);

      if (count2InSeconds > timeTakenInSeconds) {
        diffInSeconds += convertCount2ToSeconds(count2, time_unit);
      } else if (time_taken) {
        diffInSeconds += parseTimeTaken(time_taken);
      }
    } else {
      diffInSeconds += convertCount2ToSeconds(count2, time_unit);
    }
  });
  console.log("diffInSeconds", diffInSeconds);
  const formattedDifference = convertSecondsToDHMSFormat(diffInSeconds);
  console.log("formatting difference", formattedDifference);
  const unqiueFormat = formatTimeDifference(formattedDifference);

  return ` (${unqiueFormat}) `;
};
export const parseTimeTaken = (timeTaken) => {
  if (!timeTaken) {
    return;
  }
  let totalSeconds = 0;

  const parts = timeTaken.split(" - ");

  parts.forEach((part) => {
    const [value, unit] = part?.split(" ");

    switch (unit) {
      case "days":
      case "day":
        totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
        break;
      case "hours":
      case "hour":
        totalSeconds += parseInt(value, 10) * 3600;
        break;
      case "mins":
      case "min":
        totalSeconds += parseInt(value, 10) * 60;
        break;
      case "secs":
      case "sec":
        totalSeconds += parseInt(value, 10);
        break;
      default:
        totalSeconds += parseInt(value, 10) * 60;
        break;
    }
  });

  return totalSeconds;
};


export const getOptions = (t) => [
  {
    value: "Atelier",
    label: t("meeting.newMeeting.options.activityTypes.businessPresentation"),
  },
  {
    value: "Comité",
    label: t("meeting.newMeeting.options.activityTypes.committee"),
  },
  {
    value: "Conférence",
    label: t("meeting.newMeeting.options.activityTypes.conference"),
  },
  {
    value: "Entretien individuel",
    label: t("meeting.newMeeting.options.activityTypes.individualInterview"),
  },
  {
    value: "Embauche",
    label: t("meeting.newMeeting.options.activityTypes.jobInterview"),
  },
  {
    value: "Pomodoro",
    label: t("meeting.newMeeting.options.activityTypes.Pomodoro"),
  },
  {
    value: "Formation",
    label: t("meeting.newMeeting.options.activityTypes.training"),
  },
  {
    value: "Intégration",
    label: t("meeting.newMeeting.options.activityTypes.integration"),
  },
  {
    value: "Partage d'informations",
    label: t("meeting.newMeeting.options.activityTypes.informationSharing"),
  },
  {
    value: "Présentation",
    label: t("meeting.newMeeting.options.activityTypes.pitchPresentation"),
  },
  {
    value: "Quiz",
    label: t("meeting.newMeeting.options.activityTypes.quiz"),
  },
  {
    value: "Réseautage",
    label: t("meeting.newMeeting.options.activityTypes.collaborativeMeeting"),
  },
  {
    value: "Rituel agile",
    label: t("meeting.newMeeting.options.activityTypes.Agile ritual"),
  },
  {
    value: "Résolution de problème",
    label: t("meeting.newMeeting.options.activityTypes.problemResolution"),
  },
  {
    value: "Réunion commerciale",
    label: t("meeting.newMeeting.options.activityTypes.oneOnOne"),
  },
  {
    value: "Suivi de projet",
    label: t("meeting.newMeeting.options.activityTypes.projectFollowup"),
  },
  {
    value: "Séminaire",
    label: t("meeting.newMeeting.options.activityTypes.seminar"),
  },
  {
    value: "Suivi d’accompagnement",
    label: t("meeting.newMeeting.options.activityTypes.supportFollowup"),
  },
  {
    value: "Task",
    label: t("meeting.newMeeting.options.activityTypes.task"),
  },
  // { value: "Autre", label: t("meeting.newMeeting.options.activityTypes.other") }
];


export const openGoogleMeet = (link) => {
  window.open(link, '_blank');
};
