import React, { useEffect, useState } from "react";
import { API_BASE_URL, Assets_URL } from "../../../Apicongfig";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSteps } from "../../../../context/Step";
import copy from "copy-to-clipboard";
import StepEditChart from "../StepEditChart";
import { useSidebarContext } from "../../../../context/SidebarContext";
import { Editor } from "@tinymce/tinymce-react";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { IoCopyOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { PiFilePdfLight } from "react-icons/pi";
import { formatStepDate } from "../../../Utils/MeetingFunctions";

const ReportStepCard = ({ data, startTime, users, fromMeeting, meeting1 }) => {
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const { steps, updateSteps } = useSteps();
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [meeting, setMeeting] = useState();
  const navigate = useNavigate();
  // const id = data?.id
  // console.log('id a gaye',id)

  const { id } = useParams();
  const params = useParams();
  const { meeting_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggle } = useSidebarContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const [stepId, setStepId] = useState(null);
  const [stepIndex, setStepIndex] = useState(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    toggle(true);
  };
  const [doneMomentsModal, setDoneMomentsModal] = useState(null);

  useEffect(() => {
    const getMeeting = async () => {
      // setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/get-meeting/${id || meeting_id}`
        );
        if (response.status) {
          updateSteps(response?.data?.data?.steps);
          setMeeting(response.data?.data);
        }
      } catch (error) {
        console.log("error while fetching meeting data", error);
      } finally {
        // setIsLoading(false);
      }
    };
    getMeeting();
  }, [id, meeting_id, doneMomentsModal]);

  const handleCloseDoneMomentsModal = () => {
    setDoneMomentsModal(null);
  };

  // const [showModal, setShowModal] = useState(null); // Track which item's modal is open

  const toggleModal = (item, id) => {
    setEditorNote(item?.note);
    setDoneMomentsModal((prev) => (prev === id ? null : id));
  };
  const [editorNote, setEditorNote] = useState("");

  const handleEditorChange = (content, editor) => {
    setEditorNote(content);
  };
  const handleSave = async (item) => {
    try {
      const updatedSteps = [...(data?.steps || [])];
      console.log("updated steps", updatedSteps);
      //   const selectedStep = updatedSteps[selectedIndex];
      //   console.log("selectedStep", selectedStep);
      const payload = {
        ...item,
        note: editorNote,
        // actions: tableData ? tableData : [],
      };

      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${item?.id}/step-note-and-action`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        // setStepNotes((prevNotes) =>
        //   prevNotes.map((note, index) =>
        //     index === selectedIndex ? response.data.data.note : note
        //   )
        // );
        // setOpen(false);
        setDoneMomentsModal(null);
      }
    } catch (error) {
      console.log("error while save step", error);
    }
  };

  return (
    <div
      className="row"
      style={{ marginBottom: "6rem", gap: fromMeeting ? "4px" : "" }}
    >
      {steps?.map((item, index) => {
        console.log("item-->", item);
        let editorContent = item.editor_content;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = editorContent;
        const firstImageTag = tempDiv.querySelector("img");
        const firstImageUrl = firstImageTag
          ? firstImageTag.getAttribute("src")
          : "";
        let stepTime = item.count2;
        console.log("stepTime: ", stepTime);
        console.log("startTime: ", startTime);

        let [time, modifier] = startTime.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (modifier === "PM" && hours < 12) {
          hours += 12;
        }
        if (modifier === "AM" && hours === 12) {
          hours = 0;
        }

        let startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(0);
        startDate.setMinutes(startDate.getMinutes() + stepTime);

        const handleClick = (item, index) => {
          toggleModal(item, index);
        };
        const localizeTimeTaken = (timeTaken) => {
          if (!timeTaken) return '';
        
          // Retrieve localized time units
          const timeUnits = t("time_unit", { returnObjects: true });
        
          // Split the timeTaken string by " - " to separate time components
          const timeParts = timeTaken.split(" - ");
        
          // Initialize variables for each time component
          let days = null;
          let hours = null;
          let minutes = null;
          let seconds = null;
        
          // Iterate over each part and assign it to the corresponding variable
          timeParts.forEach(part => {
            if (part.includes("day")) {
              days = part;
            } else if (part.includes("hour")) {
              hours = part;
            } else if (part.includes("min")) {
              minutes = part;
            } else if (part.includes("sec")) {
              seconds = part;
            }
          });
        
          // Check if days are present
          const hasDays = Boolean(days);
        
          // Determine what to show based on the presence of days
          let result = '';
          if (hasDays) {
            // Show days and hours if days are present
            result = [days, hours].filter(Boolean).join(" - ");
          } else if (hours) {
            // Show only hours and minutes if hours and minutes are present
            result = [hours, minutes].filter(Boolean).join(" - ");
          } else if (minutes) {
            // Show minutes only if no days or hours are present
            // result = minutes;
            result = [minutes, seconds].filter(Boolean).join(" - ");
          } else{
            result = seconds
          }
        
          // Return empty string if result is undefined or empty
          if (!result) return '';
        
          // Localize and return the result
          return result
            .split(" ")
            .map(part => isNaN(part) ? (timeUnits[part] || part) : part)
            .join(" ");
        };
        // const localizeTimeTaken = (timeTaken) => {
        //   if (!timeTaken) return;
        //   const timeUnits = t("time_unit", { returnObjects: true });
        //   return timeTaken
        //     .split(" - ")
        //     .map((part) => {
        //       const [count, ...unitParts] = part.split(" ");
        //       const unit = unitParts.join(" "); // Handle cases like "days" and "hours"
        //       console.log("units", unit);
        //       return `${count} ${timeUnits[unit] || unit}`; // Fallback to original if unit not found
        //     })
        //     .join(" - ");
        // };

        // Helper function to format date into dd/mm/yyyy
        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        };

        // Function to calculate step dates based on meeting date and step times
        const calculateStepDates = (steps, meetingDate) => {
          if (!steps || !meetingDate) {
            console.error("Steps or meetingDate is null or undefined");
            return [];
          }
          const stepsWithDates = [];
          let currentDate = new Date(meetingDate); // Initialize with meeting date

          steps.forEach((step, index) => {
            if (index === 0) {
              // For the first step, use the meeting date
              stepsWithDates.push(formatDate(currentDate));
            } else {
              // For subsequent steps, add the time_taken of the previous step to the current date
              const previousStep = steps[index - 1];

              // Parse time_taken based on its format
              let timeToAdd = 0;
              if (previousStep.time_taken?.includes("day")) {
                timeToAdd =
                  parseInt(previousStep?.time_taken) * 24 * 60 * 60 * 1000; // Convert days to milliseconds
              } else if (previousStep.time_taken?.includes("hour")) {
                timeToAdd = parseInt(previousStep?.time_taken) * 60 * 60 * 1000; // Convert hours to milliseconds
              } else if (previousStep.time_taken?.includes("minute")) {
                timeToAdd = parseInt(previousStep?.time_taken) * 60 * 1000; // Convert minutes to milliseconds
              } else if (previousStep.time_taken?.includes("second")) {
                timeToAdd = parseInt(previousStep?.time_taken) * 1000; // Convert seconds to milliseconds
              }

              // Add timeToAdd to currentDate
              currentDate = new Date(currentDate.getTime() + timeToAdd);
              stepsWithDates.push(formatDate(currentDate));
            }
          });

          return stepsWithDates;
        };
        const dates = calculateStepDates(steps, meeting1?.date);

        console.log("dates", dates);

        return (
          <>
            {fromMeeting ? (
              <div className="col-12 ste" key={index}>
                <Card
                  className="mt-4 step-card-meeting"
                  onClick={() => {
                    handleClick(item, index);
                  }}
                >
                  <Card.Body className="step-card-body">
                    <div className="step-number-container">
                      <span className="step-number">
                        {index < 9 ? "0" : " "}
                        {index + 1}
                      </span>
                    </div>
                    <div className="step-body">
                      <div className="step-data">
                        <div className="step-header">
                          <Card.Title className="step-card-heading">
                            {item?.title}
                          </Card.Title>
                          {!window.location.href.includes("/present/invite") &&
                            // meeting.status === "in_progress" &&
                            (item.step_status === "completed" ? (
                              <span className="status-badge-completed">
                                Completed
                                {t("badge.completed")}
                              </span>
                            ) : item.step_status === "in_progress" ? (
                              <span
                                className={
                                  meeting1?.delay >= "00d:00h:01m"
                                    ? "status-badge-red"
                                    : "status-badge-inprogress"
                                }
                              >
                                {t("badge.inprogress")}
                              </span>
                            ) : (
                              // null
                              <span className="status-badge-upcoming">
                                {/* Upcoming */}
                                {t("badge.future")}
                              </span>
                            ))}
                        </div>
                        <div className="step-content">
                          <Card.Subtitle className="step-card-subtext">
                            {item?.image ? (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                src={
                                  item?.image?.startsWith("users/")
                                    ? Assets_URL + "/" + item?.image
                                    // : item?.image?.startsWith(
                                    //     "users/"
                                    //   )
                                    // ? Assets_URL + "/" + item.assigned_to_image
                                    : item?.image
                                }
                                // src={
                                //     item?.assigned_to_image
                                // }
                                alt="img"
                              />
                            ) : (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                // src={`${users?.participant_image}`}
                                src={
                                  users?.image?.startsWith('users/')
                                    ? Assets_URL + "/" + users.image
                                    : users?.image
                                }
                                alt="img"
                              />
                            )}
                            <span>
                              {item?.assigned_to_name ||
                                `${users?.firstName} ${users?.lastName}`}
                            </span>
                          </Card.Subtitle>
                          <Card.Text className="step-card-content">
                            <img
                              height="16px"
                              width="16px"
                              src="/Assets/ion_time-outline.svg"
                            />
                            {/* <span className="me-2">
                              {item?.step_time}
                            </span> */}
                            {window.location.href.includes(
                              "/present/invite"
                            ) ? (
                              <span className="me-2">
                                {formatStepDate(item?.start_date) +
                                  " " +
                                  "at" +
                                  " " +
                                  // localizeTimeTaken(item?.step_time)}
                                  item?.step_time }
                              </span>
                            ) : (
                              //Active wala

                              <span className="me-2">
                                {/* {item?.step_time ? item.step_time :item.total_step_time} */}
                                {/* <span>{item.step_status === null || item.time_taken === '0 sec'  ? item.total_step_time : item.step_time}</span> */}
                                <span>
                                  {item.step_status === null
                                    ? item?.total_step_time
                                    : item.step_time}
                                </span>
                                {item.step_status === "completed" ||
                                item.step_status === "in_progress" ? (
                                  <span className="ms-2">
                                    {item?.start_date && item?.start_date}
                                  </span>
                                ) : null}
                              </span>
                            )}{" "}
                            <img
                              height="16px"
                              width="16px"
                              src="/Assets/alarm-invite.svg"
                            />
                            {window.location.href.includes(
                              "/present/invite"
                            ) ? (
                              <>
                                {/* // <span>{item?.time_taken}</span> */}
                                <span>
                                  {/* {localizeTimeTaken(item?.time_taken)} */}
                                {localizeTimeTaken(item?.time_taken?.replace('-', ''))}

                                </span>
                                <span> &nbsp; / &nbsp;{item?.count2 + " " + t(`time_unit.${item.time_unit}`)}</span>
                              </>
                            ) : (
                              <>
                                <span>
                                  {/* {localizeTimeTaken(item?.time_taken)} */}
                                {localizeTimeTaken(item?.time_taken?.replace('-', ''))}

                                </span>
                              </>
                            )}{" "}
                          </Card.Text>
                        </div>
                      </div>
                      {item.editor_content &&
                      item.editor_content.trim() !==
                        "<html><head></head><body></body></html>" ? (
                        <div className="step-img-container">
                          {firstImageUrl ? (
                            <Card.Img
                              className="step-img"
                              src={firstImageUrl}
                            />
                          ) : (
                            <div className="fallback-img-container">
                              {/* <img
                                src="/Assets/Tek.png"
                                className="fallback-img"
                                alt="Fallback Image"
                              /> */}
                              <FiEdit
                                className="file-img img-fluid"
                                style={{ padding: "15px" }}
                              />
                            </div>
                          )}
                        </div>
                      ) : item.file ? (
                        <div className="file-img-container">
                          {/* <Card.Img
                            className="file-img img-fluid"
                            src="/Assets/pdf-svgrepo-com.svg"
                          /> */}
                          <PiFilePdfLight
                            className="file-img img-fluid"
                            style={{ padding: "15px" }}
                          />
                        </div>
                      ) : item.url ? (
                        <div className="link-img-container">
                          <IoCopyOutline
                            className="file-img img-fluid"
                            style={{ padding: "15px" }}
                          />
                        </div>
                      ) : (
                        <div
                          className="fallback-img-container"
                          style={{
                            height: "160px",
                          }}
                        >
                           <FiEdit
                                className="file-img img-fluid"
                                style={{ padding: "15px" }}
                              />
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ) : (
              <div className="col-12 email-step" key={index}>
                <Card className="mt-4 step-card">
                  <Card.Body className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="step-number-container">
                        <span className="step-number">
                          {index < 10 ? "0" : " "}
                          {index + 1}
                        </span>
                      </div>
                      {/* <span className="step-number">{index + 1}.</span> */}
                      {item.editor_content ? (
                        <div className="step-img-container">
                          {firstImageUrl ? (
                            <Card.Img
                              className="step-img"
                              src={firstImageUrl}
                            />
                          ) : (
                            <div className="fallback-img-container">
                              <img
                                src="/Assets/Tek.png"
                                className="fallback-img"
                                alt="Fallback Image"
                              />
                            </div>
                          )}
                        </div>
                      ) : item.file ? (
                        <div className="file-img-container">
                          <Card.Img
                            className="file-img img-fluid"
                            src="/Assets/pdf-svgrepo-com.svg"
                          />
                        </div>
                      ) : item.url ? (
                        <div className="link-img-container">
                          <Card.Img
                            className="link-img"
                            src={`/Assets/link-removebg.png`}
                          />
                        </div>
                      ) : (
                        <div className="fallback-img-container">
                          <Card.Img
                            className="fallback-img"
                            src={`/Assets/Tek.png`}
                          />
                        </div>
                      )}
                    </div>
                    <div className="ms-3 d-flex justify-content-center flex-column step-data">
                      <Card.Title className="step-card-heading">
                        {item?.title}
                      </Card.Title>
                      <Card.Subtitle className="step-card-subtext">
                        {/* {item?.assigned_to_image ? (
                          <img
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "9px",
                              borderRadius: "20px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            src={
                                item?.assigned_to_image
                            }
                            // src={
                            //   item?.image
                            //     ? `${Assets_URL}/${item?.image}`
                            //     : item?.assigned_to_image
                            // }
                            alt="img"
                          />
                        ) : (
                          <img
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "9px",
                              borderRadius: "20px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            src={`${Assets_URL}/${users?.image}`}
                            alt="img"
                          />
                        )} */}
                        {item?.image ? (
                          <img
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "9px",
                              borderRadius: "20px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            // src={
                            //   item?.image
                            //     ? `${Assets_URL}/${item?.image}`
                            //     : item?.assigned_to_image
                            // }
                            src={
                              item?.image?.startsWith("users/")
                                ? Assets_URL + "/" + item?.image
                                : item?.image
                            }
                            // src={
                            //     item?.assigned_to_image
                            // }
                            alt="img"
                          />
                        ) : (
                          <img
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "9px",
                              borderRadius: "20px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            // src={`${users?.participant_image}`}
                            src={
                              users?.image?.startsWith('users/')
                                ? Assets_URL + "/" + users.image
                                : users?.image
                            }
                          
                            alt="img"
                          />
                        )}
                        <span>
                          {item?.assigned_to_name ||
                            `${users?.firstName} ${users?.lastName}`}
                        </span>
                      </Card.Subtitle>
                      <Card.Text className="step-card-content">
                        <img
                          height="16px"
                          width="16px"
                          style={{ width: "auto", marginRight: "9px" }}
                          src="/Assets/ion_time-outline.svg"
                        />
                        <span className="me-2">{item?.total_step_time}</span>
                        <img
                          height="16px"
                          width="16px"
                          style={{ width: "auto", marginRight: "9px" }}
                          src="/Assets/alarm-invite.svg"
                        />
                        <span>{item?.count2 + " " + "Mins"}</span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}

            {isModalOpen && (
              <div className="new-meeting">
                <StepEditChart
                  meetingId={meeting1?.id}
                  show={isModalOpen}
                  stepId={stepId}
                  stepIndex={stepIndex}
                  closeModal={handleCloseModal}
                />
              </div>
            )}
            {doneMomentsModal === index && (
              <>
                <Modal
                  show={doneMomentsModal === index}
                  onHide={() => setDoneMomentsModal(false)}
                  size="xl"
                  centered
                  className="modal-fade report-steps-note-modal"
                >
                  <Modal.Body
                    style={{
                      padding: "25px 53px",
                    }}
                  >
                    <div>
                      {item?.editor_type === "File" ? (
                        <div>
                          <iframe
                            src={
                              Assets_URL +
                              "/" +
                              (item?.file + "#toolbar=0&view=fitH")
                            }
                            width="100%"
                            height="500px"
                          />
                        </div>
                      ) : (
                        <div
                          className="rendered-content-report-step-chart"
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.editor_content !== null ||
                              item?.editor_content !== ""
                                ? item?.editor_content
                                : " ",
                          }}
                        />
                      )}
                      <h3>{item?.title}</h3>
                    </div>
                    <div>
                      <>
                        <Editor
                          onBlur={(value) => {
                            console.log("value", value);
                          }}
                          onEditorChange={handleEditorChange}
                          apiKey={TINYMCEAPI}
                          value={editorNote}
                          init={{
                            statusbar: false,
                            branding: false,
                            height: 400,
                            menubar: true,
                            language: "fr_FR",
                            // language: "en_EN",
                            plugins:
                              "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                            toolbar:
                              "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                            image_advtab: true,
                            file_picker_types: "image",

                            file_picker_callback: function (
                              callback,
                              value,
                              meta
                            ) {
                              if (meta.filetype === "image") {
                                const input = document.createElement("input");
                                input.setAttribute("type", "file");
                                input.setAttribute("accept", "image/*");

                                input.onchange = function () {
                                  const file = input.files[0];
                                  const reader = new FileReader();

                                  reader.onload = function (e) {
                                    const img = new Image();
                                    img.src = e.target.result;

                                    img.onload = function () {
                                      const canvas =
                                        document.createElement("canvas");
                                      const ctx = canvas.getContext("2d");
                                      const maxWidth = 700;
                                      const maxHeight = 394;

                                      let newWidth = img.width;
                                      let newHeight = img.height;

                                      if (img.width > maxWidth) {
                                        newWidth = maxWidth;
                                        newHeight =
                                          (img.height * maxWidth) / img.width;
                                      }

                                      if (newHeight > maxHeight) {
                                        newHeight = maxHeight;
                                        newWidth =
                                          (img.width * maxHeight) / img.height;
                                      }

                                      canvas.width = newWidth;
                                      canvas.height = newHeight;

                                      ctx.drawImage(
                                        img,
                                        0,
                                        0,
                                        newWidth,
                                        newHeight
                                      );

                                      const resizedImageData = canvas.toDataURL(
                                        file.type
                                      );

                                      // Pass the resized image data to the callback function
                                      callback(resizedImageData, {
                                        alt: file.name,
                                      });
                                    };

                                    img.src = e.target.result;
                                  };

                                  reader.readAsDataURL(file);
                                };

                                input.click();
                              }
                            },
                          }}
                        />
                      </>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="justify-content-center">
                    <Button
                      className="save-note-btn"
                      onClick={() => handleSave(item)}
                    >
                      Modifier
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => setDoneMomentsModal(false)}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </>
        );
      })}
    </div>
  );
};

export default ReportStepCard;
