import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL } from "../../Apicongfig";
import { useTranslation } from "react-i18next";
import Autosuggest from "react-autosuggest";
import { Button, Modal, Spinner } from "react-bootstrap";
// import { validateParticipants } from "./UpdateMeeting";
import lodash from "lodash"; // Import lodash debounce function
import ShowIF from "../../Utils/ShowIF";
import LoadingButton from "../../Utils/LoadingButton";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StepChart from "./StepChart";
import { MdDelete, MdEdit } from "react-icons/md";
import StepEditChart from "./StepEditChart";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { getOptions } from "../../Utils/MeetingFunctions";

const NewMeeting = ({ setActiveTab }) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const { steps } = useSteps();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [discription, setDiscription] = useState("");
  const [type, setType] = useState("");
  const [priority, setPriority] = useState("");
  const [alarm, setAlarm] = useState(null);
  const today = new Date().toISOString().split("T")[0];
  const [meetingId, setMeetingId] = useState();
  const [buttonText, setButtonText] = useState("Valider");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [stepBtnDisabled, setStepBtnDisabled] = useState(false);
  const [prise_de_notes, setPrise_de_notes] = useState(null);
  const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [show, setShow] = useState(false);
  const [repitition, setRepitition] = useState("single");
  // console.log("repitition: ", repitition);
  const [participants, setParticipants] = useState([]);
  const [checkId, setCheckId] = useState(null);

  //Add Steps Functionality
  const [inputGroups, setInputGroups] = useState([]);
  const [now, setNow] = useState(false);
  function validateParticipants(participants) {
    for (let participant of participants) {
      // Skip validation for participants where isCreator is 1
      if (participant.isCreator === 1) {
        continue;
      }
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(participant.email);
      if (!isValidEmail) {
        toast.error(t("meeting.chart.error.validEmail"));
        return false; // Return false if any of the emails are invalid
      }
      if (
        participant.first_name === null ||
        participant.first_name === "" ||
        participant.last_name === null ||
        participant.last_name === "" ||
        participant.email === null ||
        participant.email === "" ||
        participant.post === null ||
        participant.post === ""
      ) {
        toast.error(t("meeting.newMeeting.participants"));
        return false; // Return false if any of the fields are null or empty
      }
    }
    return true; // Return true if all participants are valid
  }
  // ==============================================> PARTICIPANTS FUNCTIONS START <=====================================
  const handleAddParticipants = lodash.debounce(
    async () => {
      if (!validateParticipants(participants)) {
        return;
      }
      if (meetingData?.id === undefined) {
        toast.error("Veuillez d'abord remplir les champs ci-dessus"); // Please fill the above fields first
        return;
      }
      try {
        setLoadingParticipants(true);
        const response = await axios.post(
          `${API_BASE_URL}/participants`,
          { meeting_id: checkId },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        const newParticipant = response.data.data;
        if (response.status) {
          setParticipants([...participants, newParticipant]);
          setLoadingParticipants(false);
        }
      } catch (error) {
        setLoadingParticipants(false);
        // console.error("Error while adding participant:", error);
      }
      // CALLBACK FUNCTION ENDS.
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );
  const handleCancelParticipants = async (item, indexToRemove) => {
    if (!item.id) {
      setParticipants((prevParticipants) => {
        const updatedParticipants = prevParticipants.filter(
          (participant, index) => index !== indexToRemove
        );
        return updatedParticipants;
      });
      return;
    }
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/participants/${item?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setParticipants((prevParticipants) => {
          const updatedParticipants = prevParticipants.filter(
            (participant, index) => index !== indexToRemove
          );
          return updatedParticipants;
        });
      } else {
        // console.error("Failed to delete participant");
      }
    } catch (error) {
      // console.error("Error deleting participant", error);
    }
  };

  const handleParticipantChange = (index, field, value) => {
    const tempParticipants = [...participants];
    tempParticipants[index][field] = value;
    setParticipants(tempParticipants);
  };

  const handleParticipantBlur = async (index, field, value) => {
    let URL = `${API_BASE_URL}/participants`;
    const participant = participants[index];

    if (participant.id) {
      URL = `${API_BASE_URL}/participants/${participant.id}`;
      participant._method = "put";
    }

    try {
      // Update the field value in the participant object
      participant[field] = value;
      const response = await axios.post(URL, participant, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      // console.log("Response:", response);
      if (response.status === 200) {
        const newlySavedParticipant = response.data.data;
        // Update the participant in the participants array
        let participantsCopy = [...participants];
        participantsCopy[index] = newlySavedParticipant;
        setParticipants(participantsCopy);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {}, [participants]);
  // ==============================================> END ADD PARTICIPANTS <=====================================

  // ==============================================> STEPS FUNCTIONS START <=====================================

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // const [show2, setShow2] = useState(false); // Assuming repetition is mandatory
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [id, setId] = useState(null);
  const [isValidate, setIsValidate] = useState(false);
  const stepModal = async () => {
    if (meetingId === null || checkId === null) {
      toast.error(t("messages.aboveFields"));
      return;
    } else {
      const payload = {
        title: null,
        count1: 0,
        count2: 0,
        time: 0,
        editor_type: null,
        editor_content: null,
        file: null,
        assigned_to: null,
        //   order_no: stepOrder,
        meeting_id: checkId,
        _method: "post",
      };
      try {
        setIsValidate(true);
        const response = await axios.post(`${API_BASE_URL}/steps`, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        if (response.status) {
          console.log("step created successfully", response.data.data);
          setId(response.data?.data?.id);
          setShowModal(true);
          setIsValidate(false);
          // closeModal();
        }
      } catch (error) {
        console.log("error while validating step", error);
      } finally {
      }
    }
  };
  const [steps, setSteps] = useState([]);
  const [timeUnitsTotal, setTimeUnitsTotal] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const getMeeting = async () => {
      if (!checkId) return;
      try {
        const response = await axios.get(
          `${API_BASE_URL}/meetings/${checkId}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log("response", response.data.data);
        if (response.status === 200) {
          setSteps(response.data?.data?.steps);
        }
      } catch (error) {
        console.log("error while getting steps", error);
      }
    };
    getMeeting();
  }, [showModal, showEditModal]);
  const handleAddStep = lodash.debounce(
    async () => {
      if (meetingData?.id === undefined) {
        toast.error("Veuillez d'abord remplir les champs ci-dessus");
        return;
      }
      try {
        setLoadingSteps(true);
        const response = await axios.post(
          `${API_BASE_URL}/steps`,
          { meeting_id: checkId, count2: 0 },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        const newStep = response?.data?.data;
        if (response.status) {
          setInputGroups([...inputGroups, newStep]);
          setLoadingSteps(false);
        }
      } catch (error) {
        setLoadingSteps(false);
        // console.error("Error while adding step:", error);
      }
      // CALLBACK FUNCTION ENDS.
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  const handleCancelStep = async (item, index) => {
    if (index === 0) {
      return;
    } else {
      try {
        const updatedGroups = [...inputGroups];
        updatedGroups.splice(index, 1);
        // Update the sequence for each group
        updatedGroups.forEach((group, i) => {
          group.step = i + 1;
        });

        setInputGroups(updatedGroups);
        const response = await axios.delete(
          `${API_BASE_URL}/steps/${item?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status) {
          // console.log("Step deleted successfully", response.data.data);
        }
      } catch (error) {
        // console.log("Error deleting step", error);
      }
    }
  };

  const handleInputChangeStep = (item, event) => {
    const temp = JSON.parse(JSON.stringify(inputGroups));
    temp.forEach((step, index) => {
      if (step.id === item.id) {
        step.title = event.target.value;
      }
    });
    setInputGroups(temp);
  };

  const handleCountChange = (item, event) => {
    const temp = JSON.parse(JSON.stringify(inputGroups));
    temp.forEach((step) => {
      if (step.id === item.id) {
        step.count2 = parseInt(event.target.value, 10) || 0;
        step.time = step.count2;
      }
    });
    setInputGroups(temp);
  };

  const handleDecrement = async (index, item) => {
    if (isTitleBlurred) {
      const temp = JSON.parse(JSON.stringify(inputGroups));
      temp.forEach((step) => {
        if (step.id === item.id && step.count2 > 1) {
          step.count2 = Math.max(temp[index].count2 - 1, 1);
          step.time = step.count2;
        }
      });
      setInputGroups(temp);
    }
  };

  const handleIncrement = async (index, item) => {
    if (isTitleBlurred) {
      // Update count2 only if title is blurred
      const temp = JSON.parse(JSON.stringify(inputGroups));
      temp.forEach((step) => {
        if (step.id === item.id) {
          step.count2 += 1;
          step.time = step.count2;
        }
      });
      setInputGroups(temp);
      // setIsTitleBlurred(false); // Reset flag
    }
  };
  const [isTitleBlurred, setIsTitleBlurred] = useState(false);
  const handleStepBlur = async (index, field, value) => {
    setIsTitleBlurred(true); // Set blurred flag
    console.log("index", index);
    console.log("field", field);
    console.log("value", value);
    let URL = `${API_BASE_URL}/steps`;
    const step = inputGroups[index];

    if (step.id) {
      console.log("blur wali");
      URL = `${API_BASE_URL}/steps/${step.id}`;
      step._method = "put";
    }
    try {
      step[field] = value;
      // step.title = value;
      step.time = step.count2 || 0;
      const response = await axios.post(URL, step, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        const updatedStep = response.data.data;
        // Update the count2 value in the inputGroups state
        setInputGroups((prevInputGroups) => {
          const newInputGroups = [...prevInputGroups];
          newInputGroups[index] = updatedStep;
          return newInputGroups;
        });
      } else {
        // console.error("Failed to update step:", response.statusText);
      }
    } catch (error) {
      // console.error("Error updating step:", error);
    }
  };

  let total_time = 0;
  inputGroups.forEach((group) => {
    total_time += parseInt(group.count2) || 0; // Add each count to totalCount
  });

  // ==============================================> STEPS FUNCTIONS END <=====================================

  const [meetingData, setMeetingData] = useState({});

  const nextPage = async () => {
    if (
      // objective.trim() === "" ||
      value.trim() === "" ||
      title.trim() === "" ||
      date.trim() === "" ||
      startTime.trim() === ""
    ) {
      toast.error(t("messages.emptyFields"));
      return;
    }
    // Check if any required field is empty
    // const requiredFields = [
    //   { name: "objective", label: t("meeting.newMeeting.labels.Destinations") },
    //   { name: "type", label: t("meeting.newMeeting.labels.exchangeType") },
    //   // { name: "title", label: t("meeting.newMeeting.labels.objective") },
    //   { name: "priority", label: t("meeting.newMeeting.labels.priority") },
    //   { name: "description", label: t("meeting.newMeeting.labels.context") },
    //   { name: "date", label: t("meeting.newMeeting.labels.date") },
    //   { name: "start_time", label: t("meeting.newMeeting.labels.startTime") },
    // ];

    // let hasEmptyField = false;

    // requiredFields.forEach((field) => {
    //   if (!inputGroups[field.name]) {
    //     toast.error(`${t("messages.field")} "${field.label}"`);
    //     hasEmptyField = true;
    //   }
    // });

    // if (hasEmptyField) {
    //   // If any required field is empty, return without further processing
    //   return;
    // }
    const steps = inputGroups?.map((group, index) => {
      const isFirstStep = index === 0;
      let count1 = isFirstStep ? 0 : inputGroups[index - 1].count2;
      let stepName = group.title;
      return {
        // title: `Step ${group.step}`,
        title: stepName,
        // time: (count1 + group.count) * 60, //convert to seconds
        time: group.count2,
        count1: count1,
        count2: group.count2,
      };
    });
    // Check for duplicate step names
    const stepNames = steps.map((step) => step.title);
    const duplicateStepNames = stepNames.filter(
      (stepName) =>
        stepNames.indexOf(stepName) !== stepNames.lastIndexOf(stepName)
    );
    if (duplicateStepNames.length > 0) {
      toast.error(t("messages.stepNames"));
      setButtonDisabled(false);
      return;
    }
    // Check for count
    const stepCounts = steps.map((step) => step.count2);
    const zeroCounts = stepCounts.filter(
      (stepName) =>
        stepCounts.indexOf(stepName) !== stepCounts.lastIndexOf(stepCounts)
    );
    if (zeroCounts.length > 0) {
      toast.error(t("messages.stepTImeZero"));
      setButtonDisabled(false);
      return;
    }

    const inputData = {
      objective: value,
      title,
      date,
      start_time: `${startTime}:00`,
      type: type,
      description: discription,
      steps: steps,
      alarm: alarm,
      prise_de_notes: prise_de_notes,
      total_time: time,
      priority: priority,
      participants: participants,
      status: "draft",
      meeting_id: meetingId,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/meetings`, inputData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        setMeetingId(response.data?.data?.id);
        setMeetingData(response.data?.data);
        setButtonDisabled(false);
        navigate(`/validateMeeting/${response.data?.data?.id}`, {
          state: { inputData: inputData },
        });
      }
    } catch (error) {
      toast.error(error?.response.data.message);
      setButtonDisabled(false);
    }
  };

  const validate = async () => {
    if (meetingId === null) {
      nextPage();
    } else {
      console.log("else wali ", token);
      // updateMeetingPage();
      token !== null ? updateMeetingPage() : login();
    }
  };

  const handleDraft = async () => {
    const slides = [];
    let prevCount = 0;

    for (let i = 0; i < inputGroups.length; i++) {
      const currentStep = inputGroups[i];
      const counts = [prevCount, prevCount + currentStep.count2];

      slides.push({
        value: currentStep.title,
        counts: counts,
      });

      prevCount = counts[1];
    }
    // const steps = inputGroups.map((group, index) => {
    //   const isFirstStep = index === 0;
    //   let count1 = isFirstStep ? 0 : inputGroups[index - 1].count2;
    //   let stepName = group.title;
    //   return {
    //     order_no: 1,
    //     // title: `Step ${group.step}`,
    //     title: stepName,
    //     time: count1 + group.count2,
    //     count1: count1,
    //     count2: group.count2,
    //   };
    // });

    const inputData = {
      objective: value,
      type: type,
      title,
      date,
      start_time: `${startTime}`,
      description: discription,
      steps: inputGroups,
      alarm: alarm === true ? true : alarm === null ? false : false,
      total_time: time,
      prise_de_notes:
        prise_de_notes === true
          ? "Automatic"
          : prise_de_notes === null
          ? "Manual"
          : "Manual",
      priority: priority,
      // repitition,
      participants: participants,
      meeting_id: meetingId,
      status: "draft",
    };
    try {
      const response = await axios.post(`${API_BASE_URL}/meetings`, inputData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status) {
        setCheckId(response.data?.data?.id);
        setMeetingData(response.data?.data);
        if (response.data.data.steps.length > 0) {
          setInputGroups(response.data.data.steps);
        }
        if (response.data.data.participants.length > 0) {
          setParticipants(response.data?.data?.participants);
        }
        toast.success(t("messages.draftSaved"));
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  const handleInputBlur = async () => {
    const formFields = [
      value,
      type,
      title,
      discription,
      date,
      startTime,
      alarm,
      // repitition
    ];
    const hasText = formFields.some((field) => {
      if (typeof field === "string") {
        // Check if field is a string
        return field.trim() !== "";
      }
      return false;
    });
    if (hasText) {
      if (checkId === null) {
        handleDraft();
      } else {
        updateDraft();
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("is_logged_in") === "true"
  );
  const [token, setToken] = useState(null);

  console.log("token", token);
  const updateMeetingPage = async () => {
    // if (!isLoggedIn) {
    //   // If not logged in, show login modal
    //   setOpen(true);
    //   setLoading(false);

    //   return;
    // }
    setLoading(true);
    if (
      value.trim() === "" ||
      title.trim() === "" ||
      date.trim() === "" ||
      startTime.trim() === ""
    ) {
      toast.error(t("messages.emptyFields"));
      setLoading(false);

      return;
    }
    // CREATOR
    let participantArrayWithCreatorAdded = [
      ...participants,
      {
        first_name: JSON.parse(sessionStorage.getItem("user")).name,
        last_name: JSON.parse(sessionStorage.getItem("user")).last_name,
        email: JSON.parse(sessionStorage.getItem("user")).email,
        post: JSON.parse(sessionStorage.getItem("user")).post,
        isCreator: true,
      },
    ];

    setButtonDisabled(true);

    // Check for duplicate step names
    const stepNames = steps?.map((step) => step.title);
    const duplicateStepNames = stepNames?.filter(
      (stepName) =>
        stepNames.indexOf(stepName) !== stepNames.lastIndexOf(stepName)
    );
    if (duplicateStepNames.length > 0) {
      toast.error(t("messages.stepNames"));
      setButtonText("Valider");

      setButtonDisabled(false);
      setLoading(false);

      return;
    }

    if (steps?.length === 0) {
      toast.error(t("messages.emptySteps"));
      // setButtonText("Valider");
      setButtonDisabled(false);
      setLoading(false);
      return;
    }
    // Parse the start time string
    const [hour, minute] = startTime.split(":").map(Number);

    // Add one hour to the hour component
    let endHour = hour + 1;

    // If end hour is greater than or equal to 24, subtract 24
    if (endHour >= 24) {
      endHour -= 24;
    }

    // Format the end time as a string
    const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;

    console.log("endTime str->", endTimeStr);
    const inputData = {
      ...meetingData,
      objective: value,
      type: type,
      title,
      date,
      start_time: `${startTime}`,
      end_time: endTimeStr,
      description: discription,
      priority: priority,
      alarm: alarm,
      prise_de_notes: prise_de_notes,
      timezone: userTimeZone,
      // total_time: total_time,
      total_time: time,
      steps: steps,
      participants: participantArrayWithCreatorAdded,
      // access_token: token,
      user_id: userId,
      status: "active",
      _method: "put",
    };
    console.log("update meeting validator-->", inputData);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${checkId}`,
        inputData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        sessionStorage.setItem("meetingId", response.data?.data?.id);
        setMeetingId(response.data?.data?.id);
        setMeetingData(response.data?.data);
        toast.success(t("messages.activeSaved"));
        // setParticipants(response.data?.data?.participants);
        setButtonText("Valider");
        setButtonDisabled(false);
        navigate("/meeting");
        // navigate(`/validateMeeting/${response.data?.data?.id}`, {
        //   state: { inputData: inputData },
        // });
        // if(isLoggedIn){
        // setActiveTab("Evènements programmés");
        // }
        setOpen(true);
        setLoading(false);
      }
    } catch (error) {
      // console.log("error", error);
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };

  const updateDraft = async (isAddStepButtonClicked) => {
    const formFields = [value, type, title, discription, date, startTime];
    const hasText = formFields.some((field) => field.trim() !== "");
    if (!hasText) {
      toast.error("Veuillez d'abord remplir les champs ci-dessus");
      return;
    }
    const inputData = {
      ...meetingData,
      objective: value,
      type: type,
      title,
      date,
      start_time: `${startTime}`,
      description: discription,
      priority: priority,
      alarm: alarm,
      prise_de_notes: prise_de_notes,
      total_time: time,
      // repitition,
      participants: participants,
      steps: inputGroups,
      status: "draft",
      _method: "put",
      meeting_id: checkId,
    };

    // console.log("inputData--->", inputData);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${checkId}`,
        inputData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        const allSteps = response.data.data.steps.sort((a, b) => a.id - b.id);
        if (response.data.data.steps.length > 0) {
          setInputGroups(allSteps);
          //--//--
          if (isAddStepButtonClicked) {
            setInputGroups((prev) => {
              return [
                ...prev,
                {
                  order_no: inputGroups.length + 1,
                  step: inputGroups.length + 1,
                  title: "",
                  count1: 0,
                  count2: 0,
                  time: 0,
                },
              ];
            }); //--//--
          }
        }
        if (response.data.data.participants.length > 0) {
          setParticipants(response.data?.data?.participants);
        }

        setMeetingData({
          ...response.data?.data,
          steps: allSteps,
          participants: response.data?.data?.participants,
        });
      }
    } catch (error) {
    } finally {
      setStepBtnDisabled(false);
    }
    // }, 3000);
  };

  const [meetings, setMeetings] = useState([]);
  const [objectives, setObjectives] = useState([]);
  console.log("objectives", objectives);

  const getObjectives = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-objectives/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log("objectives", response);
      if (response.status) {
        const objectives = response?.data?.data;
        setObjectives(objectives);
      }
    } catch (error) {
      // console.error(error);
    }
  };
  const getMeetings = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/meetings`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        const meetingsData = response.data?.data;
        setMeetings(meetingsData);
        // const objectivesData = meetingsData.map((meeting) => meeting.objective);
        // setObjectives(objectivesData);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    getMeetings();
    getObjectives();
  }, []);

  const lowerCasedObjectives = objectives?.map((obj) => {
    if (obj) {
      return {
        name: obj?.toLowerCase(),
      };
    }
    return null;
  });

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(value) {
    return lowerCasedObjectives?.filter((obj) =>
      obj?.name?.includes(value?.trim()?.toLowerCase())
    );
  }

  const [stepId, setStepId] = useState(null);
  const [stepIndex, setStepIndex] = useState(null);
  const handleEdit = (id, index) => {
    console.log("id", id);
    setStepId(id);
    setStepIndex(index);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const [time, setTime] = useState(0);
  console.log("time", time);
  const getMeeting = async () => {
    if (checkId === null) {
      return false;
    } else {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/meetings/${checkId}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          const stepsData = response.data?.data?.steps;
          setSteps(stepsData);
          // Calculate cumulative time for each time unit
          const totals = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
          // Calculating cumulative time of all steps
          const cumulativeTime = stepsData?.reduce(
            (totalTime, step) => totalTime + step.time,
            0
          );
          setTime(cumulativeTime);
          stepsData.forEach((step) => {
            switch (step.time_unit) {
              case "days":
                totals.days += step.time;
                break;
              case "hours":
                totals.hours += step.time;
                break;
              case "minutes":
                totals.minutes += step.time;
                break;
              case "seconds":
                totals.seconds += step.time;
                break;
              default:
                break;
            }
          });

          setTimeUnitsTotal(totals);
        }
      } catch (error) {
        console.log("Error getting meeting", error);
      }
    }
  };
  useEffect(() => {
    getMeeting();
  }, [checkId, showModal, showEditModal]);
  // Log the time after it's updated
  // useEffect(() => {
  //   console.log("time", time);
  // }, [time]);
  const handleDelete = async (id) => {
    // askPermission("Are you sure you want to delete?")
    const userConfirmed = window.confirm(t("meeting.newMeeting.confirmation"));
    if (userConfirmed) {
      try {
        const response = await axios.delete(`${API_BASE_URL}/steps/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status) {
          console.log("step deleted successfully");
          getMeeting();
          // try {
          //   const response = await axios.get(
          //     `${API_BASE_URL}/meeting/${checkId}`,
          //     {
          //       headers: {
          //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          //       },
          //     }
          //   );
          //   setSteps(response.data.data.steps);
          // } catch (error) {
          //   console.log("step failed to retrieve", error);
          // }
        }
      } catch (error) {
        console.log("error while deleting step", error);
      }
    }
  };
  // const handleDragEnd = (result) => {
  //   console.log("result", result);
  //   if (!result.destination) {
  //     return;
  //   }

  //   const reorderedSteps = Array.from(inputGroups);
  //   const [removed] = reorderedSteps.splice(result.source.index, 1);
  //   reorderedSteps.splice(result.destination.index, 0, removed);

  //   // Update state with new order of steps
  //   // Assuming setInputChangeGroups is a function to update state
  //   setInputGroups(reorderedSteps);
  // };

  // --------------------REPITITION------------------
  const [show1, setShow1] = useState(false); // Assuming repetition is mandatory
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [selectedFrequency, setSelectedFrequency] = useState("daily");
  const [selectedDays, setSelectedDays] = useState([]);

  const handleClose = () => setShow1(false);
  const userId = sessionStorage.getItem("user_id");
  const handleSave = async () => {
    // Handle saving logic here (e.g., send data to server)
    const payload = {
      meeting_id: checkId, //meeting_id
      user_id: userId,
      start_date: startDate,
      end_date: endDate,
      repetition: selectedNumber,
      repetition_frequency: selectedFrequency,
      repetition_days: selectedDays,
    };
    console.log("payload", payload);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/meeting-recurring`,
        payload,
        {
          // const response = await axios.post(`https://stage-tektime.digitalisolutions.net/api/meeting-recurring`, payload, {
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log("response-->", response.data.data);
      if (response.status) {
        // toast.success(t("messages.repetitionSaved"));
        toast.success("Data is Saved");
        setShow1(false);
      }
    } catch (error) {
      console.log("error while saving data", error);
    } finally {
      setShow1(false);
    }
  };

  const handleNumberChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  const handleFrequencyChange = (event) => {
    setSelectedFrequency(event.target.value);
    // Update selectedDays based on frequency change
    if (event.target.value === "daily") {
      setSelectedDays([...days]); // Select all days for daily frequency
    } else {
      setSelectedDays([]); // Clear selected days for other frequencies
    }
  };

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleDayClick = (day) => {
    const newSelectedDays = [...selectedDays]; // Create a copy to avoid mutation
    if (newSelectedDays.includes(day)) {
      const index = newSelectedDays.indexOf(day);
      newSelectedDays.splice(index, 1);
    } else {
      newSelectedDays.push(day);
    }
    setSelectedDays(newSelectedDays);
  };

  const renderDay = (day, index) => {
    const isSelected = selectedDays.includes(day);
    const firstChar = day.charAt(0);
    const styles = {
      backgroundColor: isSelected ? "rgb(10 167 255)" : "#dfdfdf",
      borderRadius: "50%",
      border: "none",
      padding: "6px 14px",
      textAlign: "center",
      cursor: "pointer",
      color: isSelected ? "white" : "black",
    };

    return (
      <div key={index} style={styles} onClick={() => handleDayClick(day)}>
        {firstChar}
      </div>
    );
  };
  useEffect(() => {
    if (show1 && selectedFrequency === "daily") {
      setSelectedDays([...days]);
    }
  }, [show1, selectedFrequency]);

  useEffect(() => {
    // Open modal when switching to "multiple" mode
    if (repitition === "multiple") {
      setShow1(true);
    }
  }, [repitition]);

  console.log("token is in session", token);
  useEffect(() => {
    // setToken(sessionStorage.getItem("access_token"));
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      setToken(accessToken);
      setIsLoggedIn(sessionStorage.getItem("is_logged_in") === "true");
      setIsLoggedIn(true);
      // updateMeetingPage(); // Call this directly if token exists
    }
  }, [token]);
  // const user = sessionStorage.getItem("access_token");
  // useEffect(() => {
  //   setIsLoggedIn(sessionStorage.getItem("is_logged_in") === "true");
  //   if (token) {
  //     // setOpen(false);
  //     setIsLoggedIn(true);
  //   }
  // }, [token]);

  const [expiresIn, setExpiresIn] = useState(null);
  console.log("expiresIn", expiresIn);

  // // Function to refresh the access token
  // function refreshAccessToken() {
  //   const refreshToken = sessionStorage.getItem("refresh_token");

  //   if (!refreshToken) {
  //     console.error("No refresh token available");
  //     return;
  //   }

  //   axios
  //     .post(
  //       `http://localhost:5000/api/auth/refresh`,
  //       {
  //         refresh_token: refreshToken,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log("Refresh API Response:", response.data);
  //       if (response) {
  //         const newAccessToken = response.data.access_token;
  //         const newExpiresIn = response.data.expires_in; // e.g., 3598 seconds

  //         // Update the stored tokens and expiration time
  //         sessionStorage.setItem("access_token", newAccessToken);
  //         const newExpirationTime = Date.now() + newExpiresIn * 1000;
  //         sessionStorage.setItem("token_expiration_time", newExpirationTime);

  //         // Set a new timeout to refresh the access token again
  //         setTimeout(refreshAccessToken, (newExpiresIn - 60) * 1000); // Refresh 1 minute before expiration
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Refresh API Error:", error);
  //       // Optionally handle token refresh errors (e.g., log out user)
  //     });
  // }

  const userid = sessionStorage.getItem("user_id");
  const onSuccess = (response) => {
    console.log("response", response);
    const { code } = response;
    console.log("code:", code);
    axios
      .post(
        `${API_BASE_URL}/auth/google`,
        {
          code: code,
          user_id: userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(async (response) => {
        console.log("API Response:", response.data);
        if (response.data) {
          setOpen(false);
          // setActiveTab("Evènements programmés");
          setIsLoggedIn(true);
          await updateMeetingPage();
          sessionStorage.setItem("is_logged_in", "true");
          sessionStorage.setItem("access_token", response?.data?.access_token);
          sessionStorage.setItem(
            "refresh_token",
            response?.data?.refresh_token
          );
          localStorage.setItem("access_token", response?.data?.access_token);
          localStorage.setItem("refresh_token", response?.data?.refresh_token);

          const expiresIn = response.data?.expires_in; // e.g., 3598 seconds
          const expirationTime = Date.now() + expiresIn * 1000; // current time + expires_in in milliseconds
          sessionStorage.setItem("token_expiration_time", expirationTime);
          localStorage.setItem("token_expiration_time", expirationTime);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  // Function to check token expiration and refresh
  // function checkTokenExpiration() {
  //   const tokenExpirationTime = sessionStorage.getItem("token_expiration_time");

  //   if (!tokenExpirationTime) return;

  //   const currentTime = Date.now();
  //   if (currentTime > tokenExpirationTime) {
  //     console.log("Access token expired. Refreshing...");
  //     sessionStorage.removeItem("access_token");
  //     sessionStorage.removeItem("token_expiration_time");
  //     refreshAccessToken();
  //   } else {
  //     console.log("not expired now!");
  //   }
  // }

  // // Set an interval to check token expiration every minute
  // setInterval(checkTokenExpiration, 60 * 1000);

  // const onSuccess = async (response) => {
  //   console.log("response", response);
  //   const { access_token } = response; // Extract the authorization code
  //   console.log("access_token:", access_token);
  //   setExpiresIn(response?.expires_in);
  //   setLoading(true);
  //   if (
  //     value.trim() === "" ||
  //     title.trim() === "" ||
  //     date.trim() === "" ||
  //     startTime.trim() === ""
  //   ) {
  //     toast.error(t("messages.emptyFields"));
  //     setLoading(false);
  //     return;
  //   }
  //   // CREATOR
  //   let participantArrayWithCreatorAdded = [
  //     ...participants,
  //     {
  //       first_name: JSON.parse(sessionStorage.getItem("user")).name,
  //       last_name: JSON.parse(sessionStorage.getItem("user")).last_name,
  //       email: JSON.parse(sessionStorage.getItem("user")).email,
  //       post: JSON.parse(sessionStorage.getItem("user")).post,
  //       isCreator: true,
  //     },
  //   ];

  //   setButtonDisabled(true);

  //   // Check for duplicate step names
  //   const stepNames = steps?.map((step) => step.title);
  //   const duplicateStepNames = stepNames?.filter(
  //     (stepName) =>
  //       stepNames.indexOf(stepName) !== stepNames.lastIndexOf(stepName)
  //   );
  //   if (duplicateStepNames.length > 0) {
  //     toast.error(t("messages.stepNames"));
  //     setButtonText("Valider");

  //     setButtonDisabled(false);
  //     setLoading(false);

  //     return;
  //   }

  //   if (steps?.length === 0) {
  //     toast.error(t("messages.emptySteps"));
  //     // setButtonText("Valider");
  //     setButtonDisabled(false);
  //     setLoading(false);
  //     return;
  //   }
  //   // Parse the start time string
  //   const [hour, minute] = startTime.split(":").map(Number);

  //   // Add one hour to the hour component
  //   let endHour = hour + 1;

  //   // If end hour is greater than or equal to 24, subtract 24
  //   if (endHour >= 24) {
  //     endHour -= 24;
  //   }

  //   // Format the end time as a string
  //   const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
  //     minute
  //   ).padStart(2, "0")}`;

  //   console.log("endTime str->", endTimeStr);
  //   const inputData = {
  //     ...meetingData,
  //     objective: value,
  //     type: type,
  //     title,
  //     date,
  //     start_time: `${startTime}`,
  //     end_time: endTimeStr,
  //     description: discription,
  //     priority: priority,
  //     alarm: alarm,
  //     prise_de_notes: prise_de_notes,
  //     // total_time: total_time,
  //     total_time: time,
  //     steps: steps,
  //     access_token: access_token
  //       ? access_token
  //       : token,
  //       user_id: userId,
  //     participants: participantArrayWithCreatorAdded,
  //     status: "active",
  //     _method: "put",
  //   };
  //   console.log("update meeting validator-->", inputData);

  //   try {
  //     const response = await axios.post(
  //       `${API_BASE_URL}/meetings/${checkId}`,
  //       inputData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       sessionStorage.setItem("meetingId", response.data?.data?.id);
  //       sessionStorage.setItem("access_token", access_token);
  //       setMeetingId(response.data?.data?.id);
  //       setMeetingData(response.data?.data);
  //       toast.success(t("messages.draftSaved"));
  //       // setParticipants(response.data?.data?.participants);
  //       setButtonText("Valider");
  //       setButtonDisabled(false);
  //       // navigate(`/validateMeeting/${response.data?.data?.id}`, {
  //       //   state: { inputData: inputData },
  //       // });
  //       // if(isLoggedIn){
  //       // setActiveTab("Evènements programmés");
  //       // }
  //       setOpen(true);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     // console.log("error", error);
  //     setLoading(false);

  //     toast.error(error?.response.data.message);
  //   }

  //   // Make a POST request to the token endpoint to get the access token
  //   //  axios.post(
  //   //       `${API_BASE_URL}/get-access-token`,
  //   //       {
  //   //         access_token: access_token,
  //   //         user_id: userid,
  //   //       },
  //   //       {
  //   //         headers: {
  //   //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //   //         },
  //   //       }
  //   //     )
  //   //   .then((response) => {
  //   //     console.log("API Response:", response.data);
  //   //     if (response.data.success === true) {
  //   //       setOpen(false);
  //   //       // setActiveTab("Evènements programmés");
  //   //       sessionStorage.setItem("is_logged_in", "true");
  //   //       setIsLoggedIn(true);
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("API Error:", error);
  //   //   });
  // };

  const onFailure = (response) => {
    console.error("Login failed:", response);
  };

  const loggedInUserMail = sessionStorage.getItem("email");
  console.log("loggedInUserMail", loggedInUserMail);
  const login = useGoogleLogin({
    onSuccess,
    onFailure,
    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    // Prevent auto-login if user is already logged in
    flow: "auth-code",
    hint: loggedInUserMail,
    access_type: "offline",
    onNonOAuthError: (response) => {
      console.error("Non-OAuth Error:", response);
      // Handle non-OAuth-related errors
    },
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const userMail = user?.email;

  

  const options = getOptions(t);
  const sortedOptions = options?.sort((a, b) => a.label.localeCompare(b.label));
  return (
    <div className="new-meeting">
      <div className="py-3 container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="p-5 card">
              <div className="mb-4">
                <label className="form-label">
                  {t("meeting.newMeeting.labels.Destinations")}
                </label>
                <Autosuggest
                  className="form-control"
                  suggestions={suggestions}
                  onSuggestionsClearRequested={() => setSuggestions([])}
                  onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    setSuggestions(getSuggestions(value));
                  }}
                  onSuggestionSelected={(_, { suggestionValue }) =>
                    console.log("Selected: " + suggestionValue)
                  }
                  getSuggestionValue={(suggestion) => suggestion.name}
                  renderSuggestion={(suggestion) => (
                    <span>{suggestion.name}</span>
                  )}
                  inputProps={{
                    placeholder: t(
                      "meeting.newMeeting.placeholders.destination"
                    ),
                    value: value,
                    onChange: (_, { newValue, method }) => {
                      setValue(newValue);
                    },
                    onBlur: () => {
                      handleInputBlur();
                    },
                  }}
                  highlightFirstSuggestion={true}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">
                  {t("meeting.newMeeting.labels.exchangeType")}
                </label>
                <br />
                <select
                  className="select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  onBlur={handleInputBlur}
                >
                  <option value="">
                    {t("meeting.newMeeting.placeholders.activityType")}
                  </option>

                  {sortedOptions?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                  <option value="Autre">
                    {t("meeting.newMeeting.options.activityTypes.other")}
                  </option>
                </select>
              </div>
              <div className="mb-4">
                <label className="form-label">
                  {" "}
                  {t("meeting.newMeeting.labels.objective")}
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={handleInputBlur}
                  placeholder={t("meeting.newMeeting.placeholders.objective")}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">
                  {" "}
                  {t("meeting.newMeeting.labels.priority")}
                </label>
                <br />
                <select
                  className="select"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  onBlur={handleInputBlur}
                >
                  <option value="">
                    {t("meeting.newMeeting.options.priorities.priority")}
                  </option>
                  <option value="Obligatoire">
                    {" "}
                    {t("meeting.newMeeting.options.priorities.critical")}
                  </option>
                  <option value="Majeure">
                    {t("meeting.newMeeting.options.priorities.major")}
                  </option>
                  <option value="Moyenne">
                    {t("meeting.newMeeting.options.priorities.medium")}
                  </option>
                  <option value="Mineure">
                    {t("meeting.newMeeting.options.priorities.minor")}
                  </option>
                </select>
              </div>

              <div className="mb-4">
                <label className="form-label">
                  {" "}
                  {t("meeting.newMeeting.labels.context")}
                </label>
                <textarea
                  type="text"
                  required
                  className="form-control"
                  rows={5}
                  onChange={(e) => setDiscription(e.target.value)}
                  onBlur={handleInputBlur}
                  placeholder={t("meeting.newMeeting.placeholders.context")}
                />
              </div>
              <div className="mb-4">
                <div className="d-flex ">
                  <label className="form-label">Date</label>
                  {/* <div
                    className="mb-4 d-flex align-items-center gap-2"
                    style={{
                      marginLeft: "12px",
                    }}
                  >
                    <div className="d-flex gap-1">
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          name="repitition"
                          value={repitition}
                          defaultChecked={true}
                          onChange={() => setRepitition("single")}
                        />
                        <span>
                          {" "}
                          {t("meeting.newMeeting.repitition.single")}
                        </span>
                      </label>
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          name="repitition"
                          value={repitition}
                          onChange={() => setRepitition("multiple")}
                        />
                        <span>
                          {" "}
                          {t("meeting.newMeeting.repitition.multiple")}
                        </span>
                      </label>
                    </div>
                  </div> */}
                </div>
                <input
                  type="date"
                  lang="fr"
                  min={today}
                  required
                  className="form-control"
                  onChange={(e) => setDate(e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">
                  {t("meeting.newMeeting.labels.startTime")}
                </label>
                <input
                  type="time"
                  required
                  className="form-control"
                  onChange={(e) => setStartTime(e.target.value)}
                  onBlur={handleInputBlur}
                />
              </div>

              <div className="mb-4 d-flex align-items-center gap-2">
                <label className="form-label mb-0">
                  {t("meeting.newMeeting.labels.notetaking")}
                </label>
                <div className="d-flex gap-1">
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      name="prise_de_notes"
                      value={"Automatic"}
                      onChange={(e) => setPrise_de_notes(e.target.value)}
                      onBlur={handleInputBlur}
                    />
                    <span>
                      {" "}
                      {t("meeting.newMeeting.options.notetaking.automatic")}
                    </span>
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      name="prise_de_notes"
                      value={"Manual"}
                      onChange={(e) => setPrise_de_notes(e.target.value)}
                      onBlur={handleInputBlur}
                      defaultChecked={true}
                    />
                    <span>
                      {" "}
                      {t("meeting.newMeeting.options.notetaking.manual")}
                    </span>
                  </label>
                </div>
              </div>

              {/* PARTICIPANTS FORM */}
              <div className="mb-4">
                {participants
                  ?.filter((item) => item.email !== userMail)
                  .map((item, index) => (
                    <div key={index} className="mb-2">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="">{`Invité ${index + 1}`}</label>

                        {/* {index >= 1 && ( */}
                        <div>
                          <AiOutlineClose
                            size={"18px"}
                            color="#ff0000"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCancelParticipants(item, index)
                            }
                          />
                        </div>
                        {/* )} */}
                      </div>
                      <input
                        autoComplete="off"
                        aria-autocomplete="none"
                        type="text"
                        className="form-control mb-2"
                        placeholder={t(
                          "meeting.newMeeting.placeholders.firstName"
                        )}
                        value={item.first_name}
                        onChange={(e) =>
                          handleParticipantChange(
                            index,
                            "first_name",
                            e.target.value
                          )
                        }
                        onBlur={(e) => {
                          handleParticipantBlur(
                            index,
                            "first_name",
                            e.target.value
                          );
                        }}
                      />
                      <input
                        autoComplete="off"
                        aria-autocomplete="none"
                        type="text"
                        className="form-control mb-2"
                        placeholder={t(
                          "meeting.newMeeting.placeholders.lastName"
                        )}
                        value={item.last_name}
                        onChange={(e) =>
                          handleParticipantChange(
                            index,
                            "last_name",
                            e.target.value
                          )
                        }
                        onBlur={(e) => {
                          handleParticipantBlur(
                            index,
                            "last_name",
                            e.target.value
                          );
                        }}
                      />
                      <input
                        autoComplete="off"
                        aria-autocomplete="none"
                        type="email"
                        className="form-control mb-2"
                        placeholder="Email"
                        value={item.email}
                        onChange={(e) =>
                          handleParticipantChange(
                            index,
                            "email",
                            e.target.value
                          )
                        }
                        onBlur={(e) => {
                          handleParticipantBlur(index, "email", e.target.value);
                        }}
                      />
                      <input
                        autoComplete="off"
                        aria-autocomplete="none"
                        type="text"
                        className="form-control mb-2"
                        placeholder={t("meeting.newMeeting.placeholders.post")}
                        value={item.post}
                        // onChange={(event) =>
                        //   handleFourthChangeParticipants(item, event)
                        // }
                        onChange={(e) =>
                          handleParticipantChange(index, "post", e.target.value)
                        }
                        onBlur={(e) => {
                          handleParticipantBlur(index, "post", e.target.value);
                        }}
                      />
                    </div>
                  ))}
                <ShowIF condition={loadingParticipants === false}>
                  <button
                    className="btn btn-primary createbtn px-5"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      handleAddParticipants();
                    }}
                    // disabled={show}
                  >
                    {t("meeting.newMeeting.labels.addGuest")}
                  </button>
                </ShowIF>
                <ShowIF condition={loadingParticipants === true}>
                  <LoadingButton
                    className="btn btn-primary createbtn px-5 w-100"
                    loading={loadingParticipants}
                  ></LoadingButton>
                </ShowIF>
              </div>
              {/* STEPPPPPPPPPS FORM */}
              {/* <div>
                {inputGroups?.map((group, index) => (
                  <div key={index}>
                    <div className="d-flex justify-content-between">
                      <label className="form-label">{`${t(
                        "meeting.newMeeting.labels.step"
                      )} ${index + 1}`}</label>
                      {index >= 1 && (
                        <div>
                          <AiOutlineClose
                            size={"18px"}
                            color="#ff0000"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCancelStep(group, index)}
                          />
                        </div>
                      )}
                    </div>
                    <h6>{t("meeting.newMeeting.labels.title")}</h6>
                    <input
                      className="mb-3 form-control"
                      required
                      type="text"
                      value={group.title}
                      onChange={(event) => {
                        handleInputChangeStep(group, event);
                      }}
                      name="title"
                      // onMouseOut={(e) =>
                      //   handleStepBlur(index, "title", e.target.value)
                      // }
                      onMouseOut={() => setIsTitleBlurred(true)}
                    />

                    <div class="input-group">
                      <input
                        type="button"
                        value="-"
                        className="button-minus"
                        data-field="quantity"
                        onClick={() => {
                          handleDecrement(index, group);
                        }}
                        onMouseOut={() =>
                          handleStepBlur(index, "count2", group?.count2)
                        }
                        style={{
                          border: "none",
                          fontSize: "40px",
                          padding: "0px",
                          color: "#339ede",
                        }}
                      />
                      <input
                        className="count-field"
                        value={group.count2}
                        name="count2"
                        onChange={(event) => handleCountChange(group, event)}
                        onKeyDown={(event) => {
                          if (event.key === "+" || event.key === "-") {
                            event.preventDefault(); // Prevent the default behavior for '+' and '-' keys
                          }
                        }}
                        onWheel={(event) => {
                          if (event.deltaY < 0 && parseInt(group.count2) <= 0) {
                            event.preventDefault(); // Prevent decreasing below zero
                          }
                        }}
                        onBlur={() => {
                          handleStepBlur(index, "count2", group);
                        }}
                        style={{ borderRadius: "13px", margin: "10px 16px" }}
                      />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "12px",
                          marginLeft: "-10px",
                        }}
                      >
                        min
                      </span>
                      <input
                        type="button"
                        value="+"
                        className="button-plus"
                        data-field="quantity"
                        onClick={() => {
                          handleIncrement(index, group);
                        }}
                        onMouseOut={() => {
                          handleStepBlur(index, "count2", group?.count2);
                        }}
                        style={{
                          border: "none",
                          fontSize: "40px",
                          padding: "0px",
                          color: "#339ede",
                        }}
                      />
                    </div>
                  </div>
                ))}
                <ShowIF condition={loadingSteps === false}>
                  <button
                    className="px-5 mb-4 btn btn-primary createbtn"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      if (
                        inputGroups[inputGroups.length - 1].title === "" ||
                        inputGroups[inputGroups.length - 1].count2 === 0
                      ) {
                        toast.error(
                          "Veuillez d'abord remplir le titre et l'heure de l'étape "
                        );
                        return;
                      }
                      handleAddStep();
                    }}
                  >
                    {t("meeting.newMeeting.labels.addStep")}
                  </button>
                </ShowIF>
                <ShowIF condition={loadingSteps === true}>
                  <LoadingButton
                    className="btn btn-primary createbtn px-5 w-100"
                    loading={loadingSteps}
                  ></LoadingButton>
                </ShowIF>
              </div> */}
              <div>
                {steps?.length > 0 && (
                  <>
                    {steps?.map((item, index) => {
                      console.log("index", index);
                      return (
                        <div key={index}>
                          <div className="d-flex justify-content-between">
                            <div className="">
                              <label className="form-label">{`${t(
                                "meeting.newMeeting.labels.step"
                              )} ${index + 1}`}</label>
                            </div>
                            <div className="d-flex gap-2">
                              <span className="d-flex justify-content-end my-2">
                                <MdEdit
                                  size={"18px"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleEdit(item.id, index)}
                                />
                              </span>
                              <span className="d-flex justify-content-end my-2">
                                <MdDelete
                                  size={"18px"}
                                  color="#ff0000"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(item.id)}
                                />
                              </span>
                            </div>
                          </div>
                          <input
                            className="mb-3 form-control"
                            type="text"
                            value={item.title}
                            disabled
                          />
                          <input
                            className="count-field"
                            value={item.count2}
                            name="count2"
                            style={{
                              borderRadius: "13px",
                              margin: "10px 7px",
                            }}
                            disabled
                          />
                          {/* <span>{item.time_unit}</span> */}
                          <span>{t(`time_unit.${item.time_unit}`)}</span>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              <div>
                {isValidate ? (
                  <>
                    {" "}
                    <Button
                      variant="dark"
                      disabled
                      style={{
                        backgroundColor: "#3aa5ed",
                        border: "none",
                      }}
                      className="w-100"
                    >
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    </Button>
                  </>
                ) : (
                  <button
                    className="px-5 mb-4 btn btn-primary createbtn"
                    style={{ width: "100%" }}
                    onClick={() => stepModal()}
                  >
                    {t("meeting.newMeeting.labels.addStep")}
                  </button>
                )}
              </div>
              {/* <br /> */}
              <div className="mb-4 d-flex align-items-center gap-2">
                <label className="form-label mb-0">
                  {t("meeting.newMeeting.labels.alarm")}
                </label>
                <div className="d-flex gap-1">
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      name="alarm"
                      value={true}
                      onChange={() => setAlarm(true)}
                      onBlur={handleInputBlur}
                    />
                    <span> {t("meeting.newMeeting.alarmText.yes")}</span>
                  </label>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      name="alarm"
                      value={false}
                      onChange={() => setAlarm(false)}
                      onBlur={handleInputBlur}
                      defaultChecked={true}
                    />
                    <span> {t("meeting.newMeeting.alarmText.no")}</span>
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h6> {t("meeting.newMeeting.labels.plannedTime")}</h6>
                </div>
                <div>
                  <h6>{t("meeting.newMeeting.labels.realTimeDuration")}</h6>
                  {/* <span>{time} </span> */}
                  <span>
                    {timeUnitsTotal.days
                      ? timeUnitsTotal.days + ` ${t("days")} `
                      : ""}{" "}
                    {timeUnitsTotal.hours
                      ? timeUnitsTotal.hours + ` ${t("hours")}`
                      : ""}{" "}
                    {timeUnitsTotal.minutes
                      ? timeUnitsTotal.minutes + " mins "
                      : ""}{" "}
                    {timeUnitsTotal.seconds
                      ? " " + timeUnitsTotal.seconds + ` ${t("secs")} `
                      : ""}
                  </span>
                </div>
              </div>
              <div className="pt-3 d-flex justify-content-center">
                {loading ? (
                  <>
                    <Button
                      variant="dark"
                      disabled
                      style={{
                        backgroundColor: "#3aa5ed",
                        border: "none",
                      }}
                      className="w-100"
                    >
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    </Button>
                  </>
                ) : (
                  <button
                    className="px-5 form-control btn btn-primary createbtn"
                    onClick={() => {
                      if (!validateParticipants(participants)) {
                        return;
                      }
                      validate();
                    }}
                  >
                    {t("meeting.newMeeting.labels.validate")}
                    {/* {buttonText} */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <>
          <StepChart
            meetingId={meetingId || checkId}
            id={id}
            show={showModal}
            closeModal={handleCloseModal}
          />
        </>
      )}

      {showEditModal && (
        <>
          <StepEditChart
            meetingId={meetingId || checkId}
            show={showEditModal}
            stepId={stepId}
            stepIndex={stepIndex}
            closeModal={handleCloseEditModal}
          />
        </>
      )}

      {open && !isLoggedIn && (
        <Modal show={open} onHide={() => setOpen(false)}>
          <Modal.Header
            closeButton
            style={{
              bordebottom: "none",
            }}
          >
            <Modal.Title>Login With Google</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <GoogleLogin
      onSuccess={handleLoginSuccess}
      onError={handleLoginError}
      scope="https://www.googleapis.com/auth/calendar"
    /> */}
            <button onClick={() => login()}>Sign in with Google 🚀</button>;
          </Modal.Body>
        </Modal>
      )}

      {/* {repitition === "multiple" && (
        <Modal show={show1} onHide={handleClose}>
          <Modal.Header
            closeButton
            style={{
              bordebottom: "none",
            }}
          >
            <Modal.Title>Repitition</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ alignItems: "baseline" }}>
              <div className="col-md-3">
                <label htmlFor="" className="mb-1">
                  Debut
                </label>
              </div>
              <div className="col-md-6">
                <input
                  type="date"
                  lang="fr"
                  min={new Date().toISOString().slice(0, 10)} // Assuming today as minimum
                  required
                  className="form-control"
                  onChange={(e) => setStartDate(e.target.value)}
                  // onBlur={handleInputBlur}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="row align-items-center">
                  <div className="col-md-2">
                    <FaRotate />
                  </div>
                  <div className="col-md-6 p-0">
                    <span>Repeter Chaque</span>
                  </div>
                  <div className="col-md-4 position-relative">
                    <select
                      id="numberSelect"
                      className="form-select"
                      value={selectedNumber}
                      onChange={handleNumberChange}
                      style={{ border: "none" }}
                    >
                      {[...Array(10).keys()].map((num) => (
                        <option
                          key={num + 1}
                          value={num + 1}
                          data-icon="bi bi-funnel-fill"
                        >
                          {num + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <select
                  id="frequencySelect"
                  className="form-select w-auto"
                  value={selectedFrequency}
                  onChange={handleFrequencyChange}
                  style={{ border: "none" }}
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div
                  className="days-circle d-flex gap-1 align-items-center justify-content-evenly"
                  style={{ cursor: "pointer" }}
                >
                  {days.map(renderDay)}
                </div>
              </div>
            </div>
            <div className="row mt-4" style={{ alignItems: "baseline" }}>
              <div className="col-md-3">
                <label htmlFor="" className="mb-1">
                  Date de fin
                </label>
              </div>
              <div className="col-md-6">
                <input
                  type="date"
                  lang="fr"
                  min={new Date().toISOString().slice(0, 10)}
                  required
                  className="form-control"
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ background: "rgb(10 167 255)" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )} */}
    </div>
  );
};

export default NewMeeting;
