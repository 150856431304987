import React, { useEffect, useState } from "react";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSteps } from "../../../context/Step";
import StepEditChart from "./StepEditChart";
import { useSidebarContext } from "../../../context/SidebarContext";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { PiFilePdfLight } from "react-icons/pi";

import { IoCopyOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  convertCount2ToSeconds,
  formatStepDate,
  openGoogleMeet,
} from "../../Utils/MeetingFunctions";
import InviteStepChartModal from "./InviteStepChartModal";
const StepCard = ({ data, startTime, users, fromMeeting, meeting1 }) => {
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const { steps, updateSteps } = useSteps();
  const [t] = useTranslation("global");
  const [meeting, setMeeting] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const params = useParams();
  const { meeting_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggle, show } = useSidebarContext();
  const [stepId, setStepId] = useState(null);
  const [stepIndex, setStepIndex] = useState(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    toggle(true);
  };
  useEffect(() => {
    const getMeeting = async () => {
      // setIsLoading(true);
      try {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const seconds = currentTime.getSeconds();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
        // Format date
        const year = currentTime.getFullYear();
        const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
        const day = currentTime.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        const response = await axios.get(
          `${API_BASE_URL}/get-meeting/${
            id || meeting_id
          }?current_time=${formattedTime}&current_date=${formattedDate}`
        );
        if (response.status) {
          updateSteps(response?.data?.data?.steps);
          setMeeting(response.data?.data);
        }
      } catch (error) {
        console.log("error while fetching meeting data", error);
      } finally {
        // setIsLoading(false);
      }
    };
    // const sendCurrentTime = async () => {
    //   // if (meeting?.status !== "in_progress") {
    //   //   return;
    //   // }
    //   // setIsLoading(true);
    //   // Collect step_id for steps that are in progress
    //   const inProgressStep = meeting?.steps?.find(
    //     (step) => step.status === "in_progress"
    //   );
    //   console.log("inprogress step-->", inProgressStep);

    //   // If no step is in progress, exit the function
    //   // if (!inProgressStep) {
    //   //   return;
    //   // }
    //   // if(meeting?.status !== "in_progress") {
    //   //   return
    //   // }
    //   const inProgressStepId = inProgressStep?.id;
    //   console.log("inProgress step--> id", inProgressStepId);
    //   try {
    //     const currentTime = new Date();
    //     const hours = currentTime.getHours();
    //     const minutes = currentTime.getMinutes();
    //     const seconds = currentTime.getSeconds();
    //     const ampm = hours >= 12 ? "PM" : "AM";
    //     const formattedHours = hours % 12 || 12;
    //     const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    //     const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    //     const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    //     // Format date
    //     const year = currentTime.getFullYear();
    //     const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    //     const day = currentTime.getDate().toString().padStart(2, "0");
    //     const formattedDate = `${year}-${month}-${day}`;
    //     const payload = {
    //       current_time: formattedTime,
    //       current_date: formattedDate,
    //       meeting_id: id,
    //       step_id: inProgressStepId,
    //     };
    //     const response = await axios.post(
    //       `${API_BASE_URL}/update-time-taken`,
    //       payload,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //         },
    //       }
    //     );
    //     if (response?.status) {
    //       console.log("Time sent successfully", response.data);
    //     }
    //   } catch (error) {
    //     console.log("error while fetching meeting data", error);
    //   } finally {
    //     // setIsLoading(false);
    //   }
    // };
    // sendCurrentTime();
    getMeeting();
    getMeeting();
  }, [id, meeting_id, toggle]);

  // const changeStatusAndPlay = async (item) => {
  //   console.log("change->", item);
  //   let current_time;
  //   let current_date;
  //   let end_date;

  //   /*
  // jo step in_progress ma ha us ka end_time ly lia ha k wo kis time play hua tha or phir us end_time ko real_time sy minus kia ha delay find krny k liye
  // */
  //   // Find the step with step_status "in_progress"
  //   for (const step of item?.steps || []) {
  //     if (step?.step_status === "in_progress" && step?.current_time) {
  //       current_date = step.current_date;
  //       current_time = step.current_time;
  //       end_date = step?.end_date;
  //       break;
  //     }
  //   }

  //   if (!current_time || !current_date) {
  //     console.log("No in-progress step with current_time found.");
  //     return;
  //   }

  //   console.log("current_time from in-progress step:", current_time);
  //   console.log("end_date from in-progress step:", end_date);

  //   const [currentHours, currentMinutes, currentSeconds] = current_time
  //     ?.split(":")
  //     .map(Number);

  //   // Split the date string and create a new Date object
  //   const [day, month, year] = current_date?.split("-").map(Number);
  //   console.log("Parsed year:", year, "month:", month, "day:", day);
  //   const myDate = `${day}/${month}/${year}`;
  //   const currentDateTime = new Date(myDate);
  //   // Update the Date object with the extracted hours, minutes, and seconds
  //   currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);
  //   console.log("currentDateTime:", currentDateTime);

  //   const realCurrentTime = new Date();
  //   console.log("realCurrentTime:", realCurrentTime);

  //   const differenceInMilliseconds = realCurrentTime - currentDateTime;
  //   const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  //   console.log(
  //     "difference in seconds: -> end_time - real_time",
  //     differenceInSeconds
  //   ); //dubugging successfull

  //   if (isNaN(differenceInSeconds)) {
  //     console.error("differenceInSeconds is NaN");
  //     return;
  //   }

  //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //   try {
  //     let totalNegativeTime = 0;
  //     const convertSecondsToDDHHMMSS = (seconds) => {
  //       if (isNaN(seconds)) {
  //         console.error("seconds is NaN");
  //         return "NaN";
  //       }

  //       const days = Math.floor(seconds / (24 * 3600));
  //       seconds %= 24 * 3600;
  //       const hours = Math.floor(seconds / 3600);
  //       seconds %= 3600;
  //       const minutes = Math.floor(seconds / 60);
  //       seconds %= 60;
  //       return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
  //         2,
  //         "0"
  //       )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
  //         2,
  //         "0"
  //       )}s`;
  //     };

  //     const delay = convertSecondsToDDHHMMSS(differenceInSeconds);

  //     console.log("formatted delay:", delay); // debugging successfull
  //     // ------------------------------------delay tak toh sahi ha calculation

  //     const updatedSteps = item?.steps?.map((step) => {
  //       console.log("my step--->", step);
  //       if (step.step_status === "in_progress") {
  //         const savedTime = Number(step.savedTime) || 0;
  //         // -------------------------------------------------------CORRECT-----------------------------
  //         const parseTimeTaken = (timeTaken) => {
  //           if (!timeTaken) {
  //             return;
  //           }
  //           let totalSeconds = 0;

  //           const parts = timeTaken.split(" - ");

  //           parts.forEach((part) => {
  //             const [value, unit] = part?.split(" ");

  //             switch (unit) {
  //               case "days":
  //               case "day":
  //                 totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
  //                 break;
  //               case "hours":
  //               case "hour":
  //                 totalSeconds += parseInt(value, 10) * 3600;
  //                 break;
  //               case "mins":
  //               case "min":
  //                 totalSeconds += parseInt(value, 10) * 60;
  //                 break;
  //               case "secs":
  //               case "sec":
  //                 totalSeconds += parseInt(value, 10);
  //                 break;
  //               default:
  //                 totalSeconds += parseInt(value, 10) * 60;
  //                 break;
  //             }
  //           });

  //           return totalSeconds;
  //         };

  //         const totalSeconds = parseTimeTaken(step.time_taken);
  //         console.log("totalSeconds: ", totalSeconds);
  //         const time_taken_delay = convertSecondsToDDHHMMSS(totalSeconds);
  //         console.log("time_taken_delay", time_taken_delay);
  //         // Calculate actual step time in days if the time_unit is in days
  //         let actualStepTime = 0;
  //         if (step.time_unit === "days") {
  //           actualStepTime = Number(step.count2 * 86400); // if count2 is in days convert into seconds
  //         } else if (step.time_unit === "hours") {
  //           actualStepTime = Number(step.count2 * 3600); // convert hour into seconds
  //           // actualStepTime = Number(step.count2 * 60); // convert minute into seconds
  //         } else if (step.time_unit === "minutes") {
  //           actualStepTime = Number(step.count2 * 60); // convert minute into seconds
  //         } else {
  //           actualStepTime = Number(step.count2); // convert minute into seconds
  //         }

  //         console.log("actualStepTime", actualStepTime);
  //         // --------------------------------------------------CORRECT ----------------------------------
  //         const negativeTime = Number(step.negative_time) || 0;
  //         console.log("saved time: ", savedTime);
  //         console.log("negative time: ", negativeTime);

  //         // step savedTime - differenceInSeconds(step?.current_time - real_time)
  //         let newSavedTime = savedTime - differenceInSeconds; //---> differenceInSeconds means step_start_time - real_time
  //         // 106 - 55 = 51 //correct
  //         let newSavedTimeAbsolute = Math.abs(savedTime - differenceInSeconds);

  //         // let newSavedTime2 = savedTime - differenceInSecondsSave;
  //         let newSavedTimeAfterDiff = Math.abs(
  //           actualStepTime - differenceInSeconds
  //         );
  //         // let newSavedTimeAfterDiff =
  //         //   actualStepTimeInDays - differenceInSeconds;
  //         //120 - 55 = 65

  //         // let newSavedTimeAfterDiff2 =
  //         //   actualStepTimeInDays - differenceInSecondsSave;

  //         let newNegativeTime = negativeTime;

  //         if (newSavedTime >= 0) {
  //           //agr 0 sy bara ha or newSavedTime -ve ma ha tb bhi
  //           console.log("0 sy greater than or equal wali condition");
  //           newNegativeTime = 0;
  //           step.delay = null;
  //           step.savedTime = newSavedTime;
  //           // newNegativeTime += Math.abs(actualStepTimeInDays);
  //           // actualStepTimeInDays = 0;
  //         } else {
  //           step.negative_time = "99";
  //           step.savedTime = 0;
  //           newNegativeTime += Math.abs(actualStepTime);
  //           console.log("newNegativeTime->", newNegativeTime);
  //           actualStepTime = 0;
  //         }
  //         // if (newSavedTime < 0) {
  //         //   newNegativeTime += Math.abs(actualStepTimeInDays);
  //         //   actualStepTimeInDays = 0;
  //         // } else if (newSavedTime === 0) {
  //         //   newNegativeTime += differenceInSeconds;
  //         // }

  //         console.log("new saved time1: ", newSavedTime);
  //         console.log("newSavedTimeAfterDiff: ", newSavedTimeAbsolute);
  //         // console.log("new saved time2: ", newSavedTime2);
  //         console.log("new negative time: ", newNegativeTime);

  //         const currentTime = new Date();
  //         const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //         const formattedCurrentTine = currentTime.toLocaleString("en-GB", {
  //           timeZone: userTimeZone,
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           second: "2-digit",
  //           hour12: false,
  //         });
  //         const formattedCurrentDate = currentTime.toISOString().split("T")[0];
  //         return {
  //           ...step,
  //           // savedTime: newSavedTime,
  //           // savedTime: newSavedTimeAfterDiff,
  //           savedTime: newSavedTime >= 0 ? newSavedTimeAfterDiff : 0,
  //           negative_time: newNegativeTime > 0 ? "99" : 0,
  //           // delay: newSavedTime === 0 && delay,
  //           // delay: step.savedTime === 0 || (step.savedTime === null && delay),
  //           delay: actualStepTime === 0 ? delay : null,
  //           // new_current_time:
  //           //   actualStepTime === 0 ? formattedCurrentTine : null,
  //           // new_current_date:
  //           //   actualStepTime === 0 ? formattedCurrentDate : null,
  //         };
  //       } else {
  //         return {
  //           ...step,
  //         };
  //       }
  //     });

  //     console.log("updatedSteps->", updatedSteps);

  //     const payload = {
  //       ...item,
  //       _method: "put",
  //       end_time: item.end_time, // Ensure this is still set correctly
  //       delay: delay,
  //       steps: updatedSteps,
  //     };

  //     const response = await axios.post(
  //       `${API_BASE_URL}/meetings/${item.id}`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     if (response.status) {
  //       navigate(`/play/${item.id}`);
  //     }
  //   } catch (error) {
  //     console.log("error while updating meeting status", error);
  //   }
  // };
  const userId = parseInt(sessionStorage.getItem("user_id"));
  const changeStatusAndPlay = async (item) => {
    if (parseInt(item.user_id) !== userId) {
      return;
    }
    console.log("change->", item);
    let current_time;
    let current_date;
    let end_date;

    // Find the step with step_status "in_progress"
    const inProgressStep = item?.steps?.find(
      (step) => step?.step_status === "in_progress"
    );
    // Find the step with step_status "in_progress"
    for (const step of item?.steps || []) {
      if (step?.step_status === "in_progress" && step?.step_time) {
        current_date = step.current_date || step?.start_date;
        current_time = step.step_time;
        end_date = step?.end_date;
        break;
      }
    }

    if (!current_time || !current_date) {
      console.log("No in-progress step with current_time found.");
      return;
    }

    console.log("step_time from in-progress step:", current_time);
    console.log("end_date from in-progress step:", end_date);

    // const [currentHours, currentMinutes, currentSeconds] = current_time
    //   ?.split(":")
    //   .map(Number);

    // Parse time with AM/PM
    const [time, meridiem] = current_time.split(" ");
    let [currentHours, currentMinutes, currentSeconds] = time
      .split(":")
      .map(Number);
    if (meridiem.toLowerCase() === "pm" && currentHours < 12) {
      currentHours += 12;
    } else if (meridiem.toLowerCase() === "am" && currentHours === 12) {
      currentHours = 0;
    }
    // Split the date string and create a new Date object
    const [day, month, year] = current_date?.split("-").map(Number);
    console.log("Parsed year:", year, "month:", month, "day:", day);
    const myDate = `${day}/${month}/${year}`;
    const currentDateTime = new Date(myDate);

    // Update the Date object with the extracted hours, minutes, and seconds
    currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);
    console.log("currentDateTime:", currentDateTime);

    const realCurrentTime = new Date();
    console.log("realCurrentTime:", realCurrentTime);

    const count2InSeconds = convertCount2ToSeconds(
      inProgressStep?.count2,
      inProgressStep?.time_unit
    );

    const differenceInMilliseconds = realCurrentTime - currentDateTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    console.log(
      "difference in seconds: -> end_time - real_time",
      differenceInSeconds
    );

    // Subtract count2InSeconds from differenceInSeconds
    const adjustedDifferenceInSeconds = differenceInSeconds - count2InSeconds;

    console.log("Adjusted difference in seconds:", adjustedDifferenceInSeconds);

    localStorage.setItem("difference", differenceInSeconds);
    if (isNaN(differenceInSeconds)) {
      console.error("differenceInSeconds is NaN");
      return;
    }

    const convertSecondsToDDHHMMSS = (seconds) => {
      if (isNaN(seconds)) {
        console.error("seconds is NaN");
        return "NaN";
      }

      const days = Math.floor(seconds / (24 * 3600));
      seconds %= 24 * 3600;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
        2,
        "0"
      )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
        2,
        "0"
      )}s`;
    };

    const delay = convertSecondsToDDHHMMSS(adjustedDifferenceInSeconds);
    console.log("formatted delay:", delay);

    const updatedSteps = item?.steps?.map((step) => {
      console.log("my step--->", step);
      if (step.step_status === "in_progress") {
        let savedTime = Number(step.savedTime) || 0;

        let actualStepTime = 0;
        if (step.time_unit === "days") {
          actualStepTime = Number(step.count2 * 86400);
        } else if (step.time_unit === "hours") {
          actualStepTime = Number(step.count2 * 3600);
        } else if (step.time_unit === "minutes") {
          actualStepTime = Number(step.count2 * 60);
        } else {
          actualStepTime = Number(step.count2);
        }

        console.log("actualStepTime", actualStepTime);
        const negativeTime = Number(step.negative_time) || 0;
        console.log("saved time: ", savedTime);
        console.log("negative time: ", negativeTime);

        let newSavedTime = savedTime - differenceInSeconds;
        let newNegativeTime = negativeTime;

        let newTime = actualStepTime - differenceInSeconds;
        localStorage.setItem("newTime", newTime);
        // Adjust savedTime
        if (newTime >= 0) {
          savedTime = newTime;
          if (newTime < 0) savedTime = 0;
        }
        if (differenceInSeconds <= actualStepTime) {
          console.log("difference smaller than actualStepTime");
          step.delay = null;
          step.savedTime = savedTime;
          step.negative_time = newNegativeTime;
        } else {
          console.log("difference greater than actualStepTime");
          step.delay = delay;
          step.savedTime = 0;
          step.negative_time = "99";
        }

        console.log("new saved time1: ", newSavedTime);
        console.log("new negative time: ", newNegativeTime);

        return {
          ...step,
          delay: step.delay,
          savedTime: step.savedTime,
          negative_time: step.negative_time,
        };
      } else {
        return {
          ...step,
        };
      }
    });

    console.log("updatedSteps->", updatedSteps);

    const payload = {
      ...item,
      _method: "put",
      end_time: item.end_time,
      delay: delay,
      steps: updatedSteps,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${item.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        navigate(`/play/${item.id}`);
        if (item?.meet_link) {
          openGoogleMeet(item?.meet_link);
        }
      }
    } catch (error) {
      console.log("error while updating meeting status", error);
    }
  };

  const [doneMomentsModal, setDoneMomentsModal] = useState(null);

  const toggleModal = async (id) => {
    setDoneMomentsModal((prev) => (prev === id ? null : id));
  };

  const localizeTimeTaken = (timeTaken) => {
    if (!timeTaken) return;

    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    // Split timeTaken string by spaces and iterate over each segment
    return timeTaken
      .split(" ")
      .map((part) => {
        // Check if the part is numeric or text
        if (!isNaN(part)) {
          return part; // Return the number as is
        }
        // Otherwise, it's a unit; look up its localized version
        return timeUnits[part] || part; // Fallback to original if no localization
      })
      .join(" ");
  };
  const localizeTimeTakenActive = (timeTaken) => {
    if (!timeTaken) return "";

    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    // Split the timeTaken string by " - " to separate time components
    const timeParts = timeTaken.split(" - ");

    // Initialize variables for each time component
    let days = null;
    let hours = null;
    let minutes = null;
    let seconds = null;

    // Iterate over each part and assign it to the corresponding variable
    timeParts.forEach((part) => {
      if (part.includes("day")) {
        days = part;
      } else if (part.includes("hour")) {
        hours = part;
      } else if (part.includes("min")) {
        minutes = part;
      } else if (part.includes("sec")) {
        seconds = part;
      }
    });

    // Check if days are present
    const hasDays = Boolean(days);

    // Determine what to show based on the presence of days
    let result = "";
    if (hasDays) {
      // Show days and hours if days are present
      result = [days, hours].filter(Boolean).join(" - ");
    } else if (hours) {
      // Show only hours and minutes if hours and minutes are present
      result = [hours, minutes].filter(Boolean).join(" - ");
    } else if (minutes) {
      // Show minutes only if no days or hours are present
      // result = minutes;
      result = [minutes, seconds].filter(Boolean).join(" - ");
    } else {
      result = seconds;
    }

    // Return empty string if result is undefined or empty
    if (!result) return "";

    // Localize and return the result
    return result
      .split(" ")
      .map((part) => (isNaN(part) ? timeUnits[part] || part : part))
      .join(" ");
  };

  const RemoveTime = (time_taken, time_unit) => {
    if (!time_taken) return;
    if (time_unit === "days") {
      const newTimeTaken = localizeTimeTaken(time_taken);
      // Split the time_taken string by ' - ' and only keep the first two parts (days and hours)
      const parts = newTimeTaken?.split(" - ");
      if (parts?.length > 2) {
        return parts.slice(0, 2).join(" - ");
      }
    }
    return localizeTimeTaken(time_taken);
  };
  const calculateNewDateForStep = (baseDate, daysToAdd) => {
    if (!baseDate || !daysToAdd) return;
    let date = new Date(baseDate);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split("T")[0]; // returns the date in 'YYYY-MM-DD' format
  };
  // // Assuming you have an array of steps with dates (stepsWithDates) and meetingData
  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateStepDates = (steps, meetingDate, meetingTime) => {
    if (!steps || !meetingDate || !meetingTime) {
      console.error("Steps, meetingDate, or meetingTime is null or undefined");
      return [];
    }

    const stepsWithDates = [];
    let currentDate = new Date(`${meetingDate}T${meetingTime}`);

    steps.forEach((step, index) => {
      const stepStartDate = new Date(currentDate);
      stepsWithDates.push(formatDate(stepStartDate));

      // Parse time_taken based on its format
      let timeToAdd = 0;
      if (step.time_taken?.includes("day")) {
        timeToAdd = parseInt(step.time_taken) * 24 * 60 * 60 * 1000; // Convert days to milliseconds
      } else if (step.time_taken?.includes("hour")) {
        timeToAdd = parseInt(step.time_taken) * 60 * 60 * 1000; // Convert hours to milliseconds
      } else if (step.time_taken?.includes("minute")) {
        timeToAdd = parseInt(step.time_taken) * 60 * 1000; // Convert minutes to milliseconds
      } else if (step.time_taken?.includes("second")) {
        timeToAdd = parseInt(step.time_taken) * 1000; // Convert seconds to milliseconds
      }

      // Add timeToAdd to currentDate
      currentDate = new Date(currentDate.getTime() + timeToAdd);
    });

    // Log the last step's end date
    console.log("Last Step End Date: ", formatDate(currentDate));

    return stepsWithDates;
  };

  const dates = calculateStepDates(steps, meeting?.date, meeting?.start_time);

  console.log("dates", dates);
  console.log("steps", steps);

  return (
    <div
      className="row"
      style={{ marginBottom: "6rem", gap: fromMeeting ? "4px" : "" }}
    >
      {steps?.map((item, index) => {
        let editorContent = item.editor_content;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = editorContent;
        const firstImageTag = tempDiv.querySelector("img");
        const firstImageUrl = firstImageTag
          ? firstImageTag.getAttribute("src")
          : "";
        let stepTime = item.count2;

        let [time, modifier] = startTime.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (modifier === "PM" && hours < 12) {
          hours += 12;
        }
        if (modifier === "AM" && hours === 12) {
          hours = 0;
        }

        let startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(0);
        startDate.setMinutes(startDate.getMinutes() + stepTime);

        const calculateTimeDifference = (end_time, current_time) => {
          if (!end_time || !current_time) {
            return null;
          }

          const endTime = new Date(`1970-01-01T${end_time}Z`);
          const currentTime = new Date(`1970-01-01T${current_time}Z`);

          let diff = (endTime - currentTime) / 1000; // difference in seconds

          const days = Math.floor(diff / (24 * 3600));
          diff -= days * 24 * 3600;
          const hours = Math.floor(diff / 3600);
          diff -= hours * 3600;
          const minutes = Math.floor(diff / 60);
          const seconds = Math.floor(diff % 60); // ensure the seconds are integers

          return { days, hours, minutes, seconds };
        };

        const formatTimeDifference = (diff, timeUnit) => {
          if (!diff || !timeUnit) {
            return "";
          }

          switch (timeUnit) {
            case "seconds":
              return `${
                diff.hours * 3600 + diff.minutes * 60 + diff.seconds
              } seconds`;
            case "minutes":
              return `${diff.minutes} minutes ${diff.seconds} seconds`;
            case "hours":
              return `${diff.hours} hours ${diff.minutes} minutes ${diff.seconds} seconds`;
            case "days":
              return `${diff.days} days ${diff.hours} hours ${diff.minutes} minutes ${diff.seconds} seconds`;
            default:
              return `${diff.seconds} seconds`;
          }
        };

        const timeDifference = calculateTimeDifference(
          item?.end_time,
          item?.current_time
        );
        const formattedTimeDifference = formatTimeDifference(
          timeDifference,
          item?.time_unit
        );

        const handleClick = (item, index) => {
          // const selectedStep = data?.steps[index];
          if (item?.step_status === "completed") {
            // handleViewDoneMoments(meeting1, item);
            toggleModal(index);
            toggle(true);
            console.log("complete");
          } else if (item?.step_status === "in_progress") {
            changeStatusAndPlay(meeting1);
            toggle(true);
          } else {
            toggle(false);
            setIsModalOpen(true);
            setStepId(item.id);
            setStepIndex(index);
          }
        };

        const initialDate =
          index === 0
            ? meeting1?.date
            : calculateNewDateForStep(
                meeting1?.date,
                steps
                  ?.slice(0, index)
                  .reduce((acc, curr) => acc + curr?.count2, 0)
              );
        const initialDateEmail =
          index === 0
            ? meeting?.date
            : calculateNewDateForStep(
                meeting?.date,
                steps
                  ?.slice(0, index)
                  .reduce((acc, curr) => acc + curr?.count2, 0)
              );

        return (
          <>
            {fromMeeting ? (
              <div className="col-12 ste" key={index}>
                <Card
                  className="mt-4 step-card-meeting"
                  onClick={() => {
                    toggle(false);
                    handleClick(item, index);
                  }}
                >
                  <Card.Body className="step-card-body">
                    <div className="step-number-container">
                      <span className="step-number">
                        {index < 9 ? "0" : " "}
                        {index + 1}
                      </span>
                    </div>
                    <div className="step-body">
                      <div className="step-data">
                        <div className="step-header">
                          <Card.Title className="step-card-heading">
                            {item?.title}
                          </Card.Title>
                          {!window.location.href.includes("/present/invite") &&
                            // meeting.status === "in_progress" &&
                            (item.step_status === "completed" ? (
                              <span className="status-badge-completed">
                                {/* Completed */}
                                {t("badge.completed")}
                              </span>
                            ) : item.step_status === "in_progress" ? (
                              <span
                                className={
                                  item?.delay >= "00d:00h:01m"
                                    ? "status-badge-red"
                                    : "status-badge-inprogress"
                                }
                              >
                                {t("badge.inprogress")}
                              </span>
                            ) : item.step_status === "paused" ? (
                              <span className={"status-badge-red"}>
                                {t("badge.paused")}
                              </span>
                            ) : (
                              // null
                              <span className="status-badge-upcoming">
                                {t("badge.future")}
                                {/* Upcoming */}
                              </span>
                            ))}
                        </div>
                        <div className="step-content">
                          <Card.Subtitle className="step-card-subtext">
                            {item?.image ? (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                src={
                                  item?.image?.startsWith("users/")
                                    ? Assets_URL + "/" + item?.image
                                    : // : item?.image?.startsWith("users/")
                                      // ? Assets_URL + "/" + item.image
                                      item?.image
                                }
                                // src={
                                //     item?.assigned_to_image
                                // }
                                alt="img"
                              />
                            ) : (
                              <img
                                height="24px"
                                width="24px"
                                style={{
                                  marginRight: "9px",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                // src={`${users?.participant_image}`}
                                // src={
                                //   // users?.image
                                //   //   ? Assets_URL + "/" + users.image
                                //     // :
                                //      users?.participant_image
                                // }
                                src={
                                  users?.image?.startsWith("users/")
                                    ? Assets_URL + "/" + users?.image
                                    : users?.image
                                }
                                alt="img"
                              />
                            )}
                            <span>
                              {item?.assigned_to_name ||
                                `${users?.firstName} ${users?.lastName}`}
                            </span>
                          </Card.Subtitle>
                          <Card.Text className="step-card-content">
                            <img
                              height="16px"
                              width="16px"
                              src="/Assets/ion_time-outline.svg"
                            />
                            {/* <span className="me-2">
                              {item?.step_time}
                            </span> */}
                            {window.location.href.includes(
                              "/present/invite"
                            ) ? (
                              <>
                                <span className="me-2">{item?.step_time}</span>
                              </>
                            ) : (
                              //Active wala

                              <span
                                className={`${
                                  item?.time_unit === "days" ? "me-2" : "me-2"
                                }`}
                              >
                                {/* {item?.step_time ? item.step_time :item.total_step_time} */}
                                {/* <span>{item.step_status === null || item.time_taken === '0 sec'  ? item.total_step_time : item.step_time}</span> */}
                                {/* {item?.time_unit === "days" ? null : <span>
                                  {item.step_status === null
                                    ? item?.total_step_time
                                    : item.step_time}
                                </span>} */}
                                {item?.time_unit === "days" ? (
                                  <>
                                    {item.step_status === null
                                      ? formatStepDate(item.start_date) +
                                        " " +
                                        "at" +
                                        " " +
                                        item?.total_step_time
                                      : formatStepDate(item.start_date) +
                                        " " +
                                        "at" +
                                        " " +
                                        item.start_date?.split("T")[0]}
                                  </>
                                ) : (
                                  <>
                                    {item?.step_status === null
                                      ? formatStepDate(item?.start_date) +
                                        " " +
                                        "at" +
                                        " " +
                                        item?.step_time
                                      : formatStepDate(item?.start_date) +
                                        " " +
                                        "at" +
                                        " " +
                                        item?.step_time}
                                  </>
                                )}
                                {/* {item.step_status === "completed" ||
                                item.step_status === "in_progress" && item?.time_unit === "days" ? (
                                  <span className="">
                                    {item?.start_date && item?.start_date}
                                  </span>
                                ) : meeting1?.date} */}
                              </span>
                            )}{" "}
                            <span className="">
                              <img
                                height="16px"
                                width="16px"
                                src="/Assets/alarm-invite.svg"
                              />
                            </span>
                            {window.location.href.includes(
                              "/present/invite"
                            ) ? (
                              <span>
                                {/* {localizeTimeTaken(item?.time_taken)} */}
                                {localizeTimeTaken(
                                  item?.time_taken?.replace("-", "")
                                )}
                              </span>
                            ) : (
                              <>
                                {/* {item.time_unit === "days" ? (
                                  <span>
                                    {RemoveTime(
                                      item?.time_taken,
                                      item?.time_unit
                                    )}
                                  </span>
                                ) : (
                                  <span> */}
                                {item?.step_status === null
                                  ? item.count2 +
                                    " " +
                                    t(`time_unit.${item.time_unit}`)
                                  : localizeTimeTakenActive(
                                      item?.time_taken?.replace("-", "")
                                    )}
                                {/* </span>
                                )}
                                {""} */}
                                {item?.step_status !== null && (
                                  <span>
                                    &nbsp; /{" "}
                                    {item.count2 +
                                      " " +
                                      t(`time_unit.${item.time_unit}`)}
                                  </span>
                                )}
                              </>
                              // <span>
                              //   {item?.step_status === null
                              //     ? item?.count2 + " " + "Mins"
                              //     : item?.time_taken}
                              // </span>
                            )}{" "}
                          </Card.Text>
                        </div>
                      </div>
                      {item.editor_content &&
                      item.editor_content.trim() !==
                        "<html><head></head><body></body></html>" ? (
                        <div className="step-img-container">
                          {firstImageUrl ? (
                            <Card.Img
                              className="step-img"
                              src={firstImageUrl}
                            />
                          ) : (
                            <div className="fallback-img-container">
                              {/* <img
                                src="/Assets/Tek.png"
                                className="fallback-img"
                                alt="Fallback Image"
                              /> */}
                              <FiEdit
                                className="file-img img-fluid"
                                style={{ padding: "12px" }}
                              />
                            </div>
                          )}
                        </div>
                      ) : item.file ? (
                        <div className="file-img-container">
                          {/* <Card.Img
                            className="file-img img-fluid"
                            src="/Assets/pdf-svgrepo-com.svg"
                          /> */}
                          <PiFilePdfLight
                            className="file-img img-fluid"
                            style={{ padding: "12px" }}
                          />
                        </div>
                      ) : item.url ? (
                        <div className="link-img-container">
                          {/* <Card.Img
                            className="link-img"
                            src={`/Assets/link-removebg.png`}
                          /> */}
                          <IoCopyOutline
                            className="file-img img-fluid"
                            style={{ padding: "12px" }}
                          />
                        </div>
                      ) : (
                        <div className="fallback-img-container">
                          <FiEdit
                            className="file-img img-fluid"
                            style={{ padding: "12px" }}
                          />
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ) : (
              <div className="col-12 email-step" key={index}>
                <Card className="mt-4 step-card">
                  <Card.Body className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="step-number-container">
                        <span className="step-number">
                          {index < 9 ? "0" : " "}
                          {index + 1}
                        </span>
                      </div>
                      {/* <span className="step-number">{index + 1}.</span> */}
                      {item.editor_content &&
                      item.editor_content.trim() !==
                        "<html><head></head><body></body></html>" ? (
                        <div className="step-img-container">
                          {firstImageUrl ? (
                            <Card.Img
                              className="step-img"
                              src={firstImageUrl}
                            />
                          ) : (
                            <div className="fallback-img-container">
                              <FiEdit
                                className="file-img img-fluid"
                                style={{ padding: "40px" }}
                              />
                            </div>
                          )}
                        </div>
                      ) : item.file ? (
                        <div className="file-img-container">
                          <PiFilePdfLight
                            className="file-img img-fluid"
                            style={{ padding: "40px" }}
                          />
                        </div>
                      ) : item.url ? (
                        <div className="link-img-container">
                          <IoCopyOutline
                            className="file-img img-fluid"
                            style={{ padding: "40px" }}
                          />
                        </div>
                      ) : (
                        <div className="fallback-img-container">
                          <FiEdit
                            className="file-img img-fluid"
                            style={{ padding: "40px" }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="ms-3 d-flex justify-content-center flex-column step-data">
                      <Card.Title className="step-card-heading">
                        {item?.title}
                      </Card.Title>
                      <Card.Subtitle className="step-card-subtext">
                        {item?.image ? (
                          <img
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "9px",
                              borderRadius: "20px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            // src={
                            //   item?.image
                            //     ? `${Assets_URL}/${item?.image}`
                            //     : item?.assigned_to_image
                            // }
                            // src={

                            //   item?.image?.startsWith("users/")
                            //   ? Assets_URL + "/" + item?.image
                            //   : item?.image?.startsWith("users/")
                            //   ? Assets_URL + "/" + item.image
                            //   : item?.image
                            // }
                            src={
                              item?.image?.startsWith("users/")
                                ? Assets_URL + "/" + item?.image
                                : // : item?.image?.startsWith("users/")
                                  // ? Assets_URL + "/" + item.image
                                  item?.image
                            }
                            alt="img"
                          />
                        ) : (
                          <img
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "9px",
                              borderRadius: "20px",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            // src={`${users?.participant_image}`}
                            // src={
                            //     users?.participant_image
                            // }
                            src={
                              users?.image?.startsWith("users/")
                                ? Assets_URL + "/" + users?.image
                                : users?.image
                            }
                            alt="img"
                          />
                        )}
                        <span>
                          {item?.assigned_to_name ||
                            `${users?.firstName} ${users?.lastName}`}
                        </span>
                      </Card.Subtitle>
                      <Card.Text className="step-card-content">
                        <img
                          height="16px"
                          width="16px"
                          style={{ width: "auto", marginRight: "9px" }}
                          src="/Assets/ion_time-outline.svg"
                        />
                        {/* <span className="me-2">{item?.total_step_time}</span> */}
                        {item?.time_unit === "days" ? (
                          <>
                            {item?.step_status === null
                              ? initialDateEmail
                              : item?.start_date}
                          </>
                        ) : (
                          <>
                            {item?.step_status === null
                              ? formatStepDate(item.start_date) +
                                " " +
                                "at" +
                                " " +
                                item?.step_time
                              : formatStepDate(item.start_date) +
                                " " +
                                "at" +
                                " " +
                                item?.step_time}
                          </>
                        )}
                        <img
                          height="16px"
                          width="16px"
                          style={{
                            width: "auto",
                            marginRight: "9px",
                            marginLeft: "1rem",
                          }}
                          src="/Assets/alarm-invite.svg"
                        />
                        {/* <span>{item?.count2 + " " + item?.time_unit}</span> */}
                        {/* {item.count2 + " " + t(`time_unit.${item.time_unit}`)} */}
                        {/* {localizeTimeTakenActive(item?.time_taken?.replace('-', ''))} */}
                        {item?.step_status === null
                          ? item.count2 + " " + t(`time_unit.${item.time_unit}`)
                          : localizeTimeTakenActive(
                              item?.time_taken?.replace("-", "")
                            )}
                        {/* {localizeTimeTakenActive(item?.time_taken?.replace('-',''))} */}

                        {/* <span>{item?.time_taken}</span> */}
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}

            {isModalOpen && (
              <div className="new-meeting">
                {/* <InviteStepChartModal
                  meetingId={meeting1?.id}
                  steps={meeting1?.steps}
                  data={meeting1}
                /> */}
                <InviteStepChartModal
                  meetingId={meeting1?.id}
                  show={isModalOpen}
                  stepId={stepId}
                  meeting={meeting}
                  stepIndex={stepIndex}
                  closeModal={handleCloseModal}
                />
              </div>
            )}
            {doneMomentsModal === index && (
              <>
                <Modal
                  show={doneMomentsModal === index}
                  onHide={() => setDoneMomentsModal(false)}
                  size="xl"
                  centered
                  className="modal-fade report-steps-note-modal"
                >
                  <Modal.Body
                    style={{
                      padding: "25px 53px",
                    }}
                  >
                    <div>
                      {item?.editor_type === "File" ? (
                        <div>
                          <iframe
                            src={
                              Assets_URL +
                              "/" +
                              (item?.file + "#toolbar=0&view=fitH")
                            }
                            width="100%"
                            height="500px"
                          />
                        </div>
                      ) : (
                        <div
                          className="rendered-content-report-step-chart"
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.editor_content !== null ||
                              item?.editor_content !== ""
                                ? item?.editor_content
                                : " ",
                          }}
                        />
                      )}
                      <h3>{item?.title}</h3>
                    </div>
                    <div>
                      <>
                        <Editor
                          onBlur={(value) => {
                            console.log("value", value);
                          }}
                          // disabled
                          // key={selectedIndex}
                          apiKey={TINYMCEAPI}
                          // value={inputData?.steps[selectedIndex].note}
                          value={item?.note}
                          init={{
                            statusbar: false,
                            branding: false,
                            height: 400,
                            menubar: true,
                            language: "fr_FR",
                            // language: "en_EN",
                            plugins:
                              "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                            toolbar:
                              "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                            image_advtab: true,
                            file_picker_types: "image",

                            file_picker_callback: function (
                              callback,
                              value,
                              meta
                            ) {
                              if (meta.filetype === "image") {
                                const input = document.createElement("input");
                                input.setAttribute("type", "file");
                                input.setAttribute("accept", "image/*");

                                input.onchange = function () {
                                  const file = input.files[0];
                                  const reader = new FileReader();

                                  reader.onload = function (e) {
                                    const img = new Image();
                                    img.src = e.target.result;

                                    img.onload = function () {
                                      const canvas =
                                        document.createElement("canvas");
                                      const ctx = canvas.getContext("2d");
                                      const maxWidth = 700;
                                      const maxHeight = 394;

                                      let newWidth = img.width;
                                      let newHeight = img.height;

                                      if (img.width > maxWidth) {
                                        newWidth = maxWidth;
                                        newHeight =
                                          (img.height * maxWidth) / img.width;
                                      }

                                      if (newHeight > maxHeight) {
                                        newHeight = maxHeight;
                                        newWidth =
                                          (img.width * maxHeight) / img.height;
                                      }

                                      canvas.width = newWidth;
                                      canvas.height = newHeight;

                                      ctx.drawImage(
                                        img,
                                        0,
                                        0,
                                        newWidth,
                                        newHeight
                                      );

                                      const resizedImageData = canvas.toDataURL(
                                        file.type
                                      );

                                      // Pass the resized image data to the callback function
                                      callback(resizedImageData, {
                                        alt: file.name,
                                      });
                                    };

                                    img.src = e.target.result;
                                  };

                                  reader.readAsDataURL(file);
                                };

                                input.click();
                              }
                            },
                          }}
                          // onEditorChange={(value) => {
                          //   setNotes(value);
                          // }}
                          // onEditorChange={(value) => {
                          //   if (value !== stepNotes[selectedIndex]) {
                          //     setStepNotes((prev) => {
                          //       const newStepNotes = [...prev];
                          //       newStepNotes[selectedIndex] = value;
                          //       return newStepNotes;
                          //     });
                          //   }
                          // }}
                          // onEditorChange={(value) => {
                          //   setStepNotes((prev) => {
                          //     let newStepNotes = [...prev];
                          //     newStepNotes[selectedIndex] = value;
                          //     return newStepNotes;
                          //   });
                          // }}
                        />
                      </>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="justify-content-center">
                    <Button
                      variant="danger"
                      onClick={() => setDoneMomentsModal(false)}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </>
        );
      })}
    </div>
  );
};

export default StepCard;
