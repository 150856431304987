import React, { useEffect, useRef, useState } from "react";
import { API_BASE_URL, Assets_URL } from "../../../Apicongfig";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import { LuFileEdit } from "react-icons/lu";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const ReportDecisionCard = ({ data, meeting,disabled }) => {
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const navigate = useNavigate();
  const [decision, setDecision] = useState([]);

  console.log("decision", decision);
  const [t] = useTranslation("global");
  const [showModal, setShowModal] = useState(null); // Track which item's modal is open

  const toggleModal = (id) => {
    setShowModal((prev) => (prev === id ? null : id));
  };

  const handleClose = () => setShowModal(null);

  const handleSave = async () => {
    try {
      const updatedSteps = meeting;
      console.log("updated steps", updatedSteps);

      //   const selectedStep = updatedSteps[index];
      // console.log('selectedStep', selectedStep)
      const payload = {
        ...updatedSteps,
        step_decision: decision,
        _method: "put",
      };

      const response = await axios.post(
        `${API_BASE_URL}/get-meeting/${meeting?.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        // setStepNotes((prevNotes) =>
        //   prevNotes.map((note, index) =>
        //     index === selectedIndex? response.data.data.note : note
        //   )
        // )
        handleClose();
      }
    } catch (error) {
      console.log("error while save step", error);
    }
  };
  return (
    <div className="d-flex gap-3">
      {data?.map((item, index) => {
        // console.log("item", item);
        if (!item) {
          return null;
        }
        // const isOpen = openStates[item.id] || false; // Check if item's id exists in openStates

        return (
          <>
            <Card
              key={index}
              style={{ width: "416px" }}
              className="mt-4 participant-card"
            >
              <Card.Body>
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="numbers ">
                      <div className="number">{index + 1}</div>
                    </div>
                    <span>
                      <LuFileEdit
                        className="eye-icon"
                        color={showModal === index ? "#20acd4" : "black"}
                        size={18}
                        style={{
                          margin: "2px",
                        }}
                        onClick={() => toggleModal(index)}
                      />
                    </span>
                  </div>

                  <div className="d-flex flex-column align-items-start">
                    {/* <div className="text-center mt-4 card-heading">{item}</div> */}
                    <div
                      className="decision-paragraph"
                      dangerouslySetInnerHTML={{
                        __html: (() => {
                          let filteredDecisions = [];
                          if (item != null) {
                            filteredDecisions.push(item);
                          }
                          return filteredDecisions.join("\n");
                        })(),
                      }}
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <div></div>
                    <div className="team-btn">
                      {meeting.user?.teams?.map((item) => item.name)}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            {showModal === index && (
              <>
              <Modal show={showModal === index} onHide={handleClose} size="lg">
                <Modal.Body>
                  <Editor
                    onBlur={(value) => {
                      console.log("value", value);
                    }}
                    key={index}
                    apiKey={TINYMCEAPI}
                    value={data[index]}
                    init={{
                      statusbar: false,
                      branding: false,
                      height: 400,
                      menubar: true,
                      language: "fr_FR",
                      plugins:
                        "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                      toolbar:
                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                      image_advtab: true,
                      file_picker_types: "image",

                      file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "image") {
                          const input = document.createElement("input");
                          input.setAttribute("type", "file");
                          input.setAttribute("accept", "image/*");

                          input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();

                            reader.onload = function (e) {
                              const img = new Image();
                              img.src = e.target.result;

                              img.onload = function () {
                                const canvas = document.createElement("canvas");
                                const ctx = canvas.getContext("2d");
                                const maxWidth = 700;
                                const maxHeight = 394;

                                let newWidth = img.width;
                                let newHeight = img.height;

                                if (img.width > maxWidth) {
                                  newWidth = maxWidth;
                                  newHeight =
                                    (img.height * maxWidth) / img.width;
                                }

                                if (newHeight > maxHeight) {
                                  newHeight = maxHeight;
                                  newWidth =
                                    (img.width * maxHeight) / img.height;
                                }

                                canvas.width = newWidth;
                                canvas.height = newHeight;

                                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                                const resizedImageData = canvas.toDataURL(
                                  file.type
                                );

                                // Pass the resized image data to the callback function
                                callback(resizedImageData, {
                                  alt: file.name,
                                });
                              };

                              img.src = e.target.result;
                            };

                            reader.readAsDataURL(file);
                          };

                          input.click();
                        }
                      },
                    }}
                    onEditorChange={(value) => {
                      setDecision((prev) => {
                        let newDecision = [...prev];
                        data[index] = value;
                        return newDecision;
                      });
                    }}
                  />
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button variant="primary" onClick={handleSave} disabled={disabled}>
                    Modifier
                  </Button>
                  <Button variant="danger" onClick={() => setShowModal(false)}>
                  {t('buttons.cancel')}

                  </Button>
                </Modal.Footer>
              </Modal>
              </>

            )}
          </>
        );
      })}
    </div>
  );
};

export default ReportDecisionCard;
