import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Editor } from "@tinymce/tinymce-react";
import {
  API_BASE_URL,
  Assets_URL,
  Cloudinary_URL,
  NODE_API,
} from "../../Apicongfig";
import { useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { useTotalTime } from "../../../context/TotalTimeContext";
import cheerio from "cheerio";
import { useTranslation } from "react-i18next";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { MdOutlineModeEdit } from "react-icons/md";
import { LuFileEdit } from "react-icons/lu";
import copy from "copy-to-clipboard";
import { openLinkInNewTab } from "../../Utils/openLinkInNewTab";
import { useSidebarContext } from "../../../context/SidebarContext";
import { convertCount2ToSeconds, openGoogleMeet } from "../../Utils/MeetingFunctions";

// Function to extract base64 image sources from HTML string
function extractBase64SrcFromHTML(htmlString) {
  const base64SrcArray = [];

  // Load the HTML string into cheerio
  const $ = cheerio.load(htmlString);

  // Find all elements with 'src' attribute
  $("[src]").each((index, element) => {
    const srcValue = $(element).attr("src");

    // Check if the src starts with 'data:image'
    if (srcValue.startsWith("data:image")) {
      // If src is a base64 image, push it into base64SrcArray
      base64SrcArray.push(srcValue);
    }
  });

  return base64SrcArray;
}

// Function to replace base64 image sources with cloud URLs in HTML string
function replaceBase64SrcWithLinks(htmlString, imageLinks) {
  // Load the HTML string into cheerio
  const $ = cheerio.load(htmlString);

  // Find all elements with 'src' attribute
  $("[src]").each((index, element) => {
    const srcValue = $(element).attr("src");

    // Check if the src starts with 'data:image'
    if (srcValue.startsWith("data:image")) {
      // Replace the src with the corresponding link from imageLinks
      $(element).attr("src", imageLinks[index]);
    }
  });

  // Return the modified HTML string
  return $.html();
}

//FrontEnd - Cloudinary
export const optimizeEditorContent = async (editorContent) => {
  if (!editorContent) {
    return "";
  }
  //-------- CLOUD LOGIC ------------------------------
  let optimizedEditorContent = "";
  const base64Array = extractBase64SrcFromHTML(editorContent);
  if (!base64Array.length > 0) {
    optimizedEditorContent = editorContent;
    return optimizedEditorContent;
  } else {
    const cloudinaryUploads = base64Array.map(async (base64Image) => {
      try {
        const response = await fetch(`${Cloudinary_URL}`, {
          method: "POST",
          body: JSON.stringify({
            file: base64Image,
            upload_preset: "chat-application",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data.secure_url;
      } catch (error) {
        console.error("Error uploading image to Cloudinary:", error);
        return null;
      }
    });

    const uploadedImageUrls = await Promise.all(cloudinaryUploads);
    const editorContentWithCloudLinks = replaceBase64SrcWithLinks(
      editorContent,
      uploadedImageUrls
    );

    optimizedEditorContent = editorContentWithCloudLinks;
    return optimizedEditorContent;
  }
  //   // ------- CLOUD LOGIC END -------------------------
};

export const image_upload_handler_callback = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    formData.append("upload_preset", "chat-application");
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", `${Cloudinary_URL}`);

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        resolve(response.secure_url); // Assuming Cloudinary returns the secure URL of the uploaded image
      } else {
        reject("Failed to upload image to Cloudinary");
      }
    };

    xhr.onerror = () => {
      reject(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    xhr.send(formData);
  });

//----------------------------------------------

const InviteStepChart = ({
  meetingId,
  puller,
  participants,
  data,
  steps,
  meeting,
}) => {
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  console.log("TINYMCEAPI", TINYMCEAPI);
  // const [steps,setSteps] = useState([])
  console.log("steps", steps);
  const location = window.location.href;
  const navigate = useNavigate();
  const fromReport = location.includes("step-details")
    ? true
    : location.includes("meetingDetail")
    ? true
    : false;
  const [t] = useTranslation("global");
  const { toggle, show } = useSidebarContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const id = useParams().id || meetingId;
  const [inputData, setInputData] = useState([]);
  console.log("inputData", inputData);
  const [loading, setLoading] = useState(false);
  const [selectedBar, setSelectedBar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCount, setSelectedCount] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const stepRef = useRef();
  const [accumulatedSelectedCounts, setAccumulatedSelectedCounts] = useState(
    []
  );
  const [storedStartTime, setStoredStartTime] = useState(null);
  const [storedStartDate, setStoredStartDate] = useState(null);
  const [storedStartDateForHour, setStoredStartDateForHour] = useState(null);
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [countSum, setCountSum] = useState(0);
  const [modifiedFileText, setModifiedFileText] = useState([]);
  const [modalType, setModalType] = useState("Editeur");
  const [editorContent, setEditorContent] = useState("");
  const [addBtnText, setAddBtnText] = useState("Ajouter une étape");
  const [copyBtnText, setCopyBtnText] = useState("Copier l’étape");
  const [nextBtnText, setNextBtnText] = useState("Suivant");
  const [prevBtnText, setPrevBtnText] = useState("Précédent");
  const [validateBtnText, setValidateBtnText] = useState("Valider");
  const [link, setLink] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [timeUnit, setTimeUnit] = useState("");
  const inputDataRef = useRef(inputData);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      toggle(false);
    }
  }, [isModalOpen]);
  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    // console.log("file: ", file);
    setIsUpload(true);
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    // Check file size (6 MB = 6 * 1024 * 1024 bytes)
    if (file.size > 6 * 1024 * 1024) {
      toast.error(t("meeting.chart.error.file"));
      setIsUpload(false);
      return;
    }
    if (file && allowedFileTypes.includes(file.type)) {
      try {
        setIsDisabled(false);

        const updatedSteps = [...(inputData?.steps || [])];
        const selectedStep = updatedSteps[selectedIndex];

        const filePayload = {
          title: selectedStep.title,
          count1: selectedStep.count1 || 0,
          count2: selectedStep.count2,
          time: selectedStep.count2, // Ensure this is correct
          editor_type: "File",
          file: file,
          editor_content: null,
          status: "active",
          _method: "put",
        };

        const formData = new FormData();
        formData.append("title", filePayload.title);
        formData.append("count1", filePayload.count1);
        formData.append("count2", filePayload.count2);
        formData.append("time", filePayload.time);
        formData.append("editor_type", filePayload.editor_type);
        formData.append("file", filePayload.file);
        formData.append("editor_content", filePayload.editor_content);
        formData.append("status", filePayload.status);
        formData.append("_method", filePayload._method);

        const response = await axios.post(
          `${API_BASE_URL}/steps/${selectedStep?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          setFileName(response.data.data.file);
          setIsUpload(false);
        }
      } catch (error) {
        console.log("error while uploading file", error);
        setIsUpload(false);
      }
    } else {
      alert(
        "Please select a valid file type: PDF, Excel, PowerPoint, or Word."
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf,.xlsx,.xls,.ppt,.pptx,.doc,.docx",
    onDrop,
  });

  const [user, setUser] = useState(null);
  const handleUserSelect = (e) => {
    setUser(e.target.value);
    // setInputData((prev) => {
    //   let updatedSteps = [...prev?.steps];
    //   updatedSteps[selectedIndex].assigned_to = user;
    //   updatedSteps[selectedIndex].assigned_to_name =
    //     e.target.selectedOptions[0]?.text;
    //   return { ...prev, steps: updatedSteps };
    // });
  };

  const handleLinkUpload = (event) => {
    setLink(event.target.value);
    setShowPreview(true);
    setPreviewUrl(event.target.value);
  };
  const [isEdited, setIsEdited] = useState(false);

  const getMeetingbyId = async () => {
    try {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
      const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
      // Format date
      const year = currentTime.getFullYear();
      const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
      const day = currentTime.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const response = await axios.get(
        `${API_BASE_URL}/get-meeting/${id}?current_time=${formattedTime}&current_date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log("steps when modal closed-->", response.data?.data?.steps);
      const updatedSteps = response.data?.data?.steps;
      setMySteps(updatedSteps);
      // setSteps(updatedSteps)
      if (puller !== undefined) {
        puller(updatedSteps);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getMeetingbyId();
  }, [id, isModalOpen]);

  const closeModal = async () => {
    setIsDisabled(true);
    // setUser("");
    const updatedSteps = [...(inputData?.steps || [])];
    const selectedStep = updatedSteps[selectedIndex];
    const count2Difference = parseInt(selectedCount, 10) - selectedStep?.count2;
    if (puller !== undefined) {
      puller(updatedSteps);
    }
    const isUnique = updatedSteps.every(
      (step, index) => index === selectedIndex || step.title !== selectedValue
    );
    if (!isUnique) {
      // toast.error("Le nom de l'étape doit être unique.");
      toast.error(t("meeting.chart.error.unique"));

      setIsDisabled(false);
      setNextBtnText("Suivant");
      return;
    }
    selectedStep.editor_content = modifiedFileText[selectedIndex];
    if (modalType === "Editeur") {
      selectedStep.editor_type = "Editeur";
      setModalType("Editeur");
    } else if (modalType === "Url") {
      // selectedStep.editor_type = modalType;
      selectedStep.editor_type = "Url";
      // selectedStep.editor_content = link;
      // setLink(link);
      setModalType("Url");
    } else if (modalType === "File") {
      // selectedStep.editor_type = modalType;
      selectedStep.editor_type = "File";
      // selectedStep.file = fileUpload;
      // setFileUpload(fileUpload);
    }
    selectedStep.title = selectedValue;
    selectedStep.count2 = parseInt(selectedCount, 10);

    for (let i = selectedIndex + 1; i < updatedSteps?.length; i++) {
      const currentStep = updatedSteps[i];
      currentStep.count1 += count2Difference;
    }
    // Update the time value for each step based on count1 and count2
    // let currentTime = selectedStep?.count1 + selectedStep?.count2;
    let currentTime = selectedStep?.count2;
    selectedStep.time = currentTime;

    for (let i = selectedIndex + 1; i < updatedSteps.length; i++) {
      const currentStep = updatedSteps[i];
      currentTime += currentStep?.count2;
      currentStep.time = currentTime;
    }

    const countSum = updatedSteps.reduce((sum, step) => sum + step.count2, 0);
    setTotalTime(countSum);

    const myStep = updatedSteps[selectedIndex + 1]?.count2;
    let accumulatedSelectedCount = myStep;
    for (let i = 0; i < selectedIndex + 1; i++) {
      accumulatedSelectedCount += updatedSteps[i]?.count2;
    }

    const newStoredStartTime = moment(inputData.start_time, "HH:mm")
      .add(accumulatedSelectedCount, "minutes")
      .format("hh:mm a");
    console.log("newStoredStartTime--->", newStoredStartTime);
    setStoredStartTime(newStoredStartTime);

    const newStoredStartDate = moment(inputData?.date)
      .add(accumulatedSelectedCount, "days")
      .format("YYYY-MM-DD");
    setStoredStartDate(newStoredStartDate);

    //-------- CLOUD LOGIC ------------------------------
    const optimizedEditorContent = await optimizeEditorContent(
      selectedStep?.editor_content
    );
    // ------- CLOUD LOGIC END -------------------------

    // return;
    const updatedMeetingData = {
      ...selectedStep,
      title: selectedStep.title,
      count1: selectedStep.count1,
      count2: selectedStep.count2,
      time: selectedStep.count2,
      editor_type: selectedStep.editor_type,
      editor_content: fileName ? fileName : optimizedEditorContent,

      // editor_content: optimizedEditorContent.startsWith("<p>")
      //   ? fileName
      //   : optimizedEditorContent ? fileName : optimizedEditorContent,
      status: "active",
      file: fileName ? fileName : null,
      assigned_to: user,
      order_no: selectedStep.order_no,
      _method: "put",
    };

    try {
      setIsDisabled(true);
      const response = await axios.post(
        `${API_BASE_URL}/steps/${selectedStep?.id}`,
        updatedMeetingData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        console.log("response--> on forward", response.data.data);
        updateTotalTime(countSum);
        // setUser(null);
        setIsModalOpen(false);
        toggle(true);
      }
    } catch (error) {
      setIsModalOpen(false);

      // console.log("errors", error);
      // setIsDisabled(true);
      // toast.error("Échec de la copie de l'étape");
      toast.error(t("meeting.chart.error.failed"));
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (fromReport) {
      setIsModalOpen(true);
    }
  }, [fromReport]);
  useEffect(() => {
    const getMeeting = async () => {
      try {
        setLoading(true);
        setIsDisabled(true);
        const REQUEST_URL = fromReport
          ? `${API_BASE_URL}/showPublicMeeting/${meetingId}`
          : `${API_BASE_URL}/meetings/${meetingId}`;
        const response = await axios.get(REQUEST_URL, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status) {
          console.log("response data chart: ", response.data?.data);
          const updatedSteps = response.data?.data?.steps;
          const time = updatedSteps.reduce((acc, step) => acc + step.count2, 0);
          setInputData(response?.data?.data);

          // setInputData({ ...response.data?.data, steps: updatedSteps });
          setLoading(false);
          updateTotalTime(time);
          // setSteps(updatedSteps)

          // const formattedData = updatedSteps?.map(step => ({
          //   x: step.title,
          //   y: step.time,
          //   time_unit: step.time_unit
          // }));
          // setChartData(formattedData);
        }
      } catch (error) {
        // console.log("error", error);
        setLoading(false);
      } finally {
        setIsDisabled(false);
      }
    };

    getMeeting();
  }, [id, meetingId, isModalOpen]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getMeeting();
  //   }, 5000); //5 sec

  //   return () => clearInterval(intervalId);
  // }, [meeting, id]);
  const [isDeleted, setIsDeleted] = useState(false);

  const [allData, setAllData] = useState([]);
  useEffect(() => {
    if (inputData && inputData?.steps) {
      setModifiedFileText(inputData?.steps?.map((step) => step.editor_content));
    }

    if (inputData) {
      const { steps } = inputData;
      let accumulatedCount2 = steps?.length > 0 ? steps[0]?.count2 : 0;
      let storedTime = moment(inputData.start_time, "HH:mm"); // Initialize stored time
      console.log("accumulatedCount2->", accumulatedCount2);
      const formattedData = steps
        ?.map((step, index) => {
          let count1 = 0;
          let count2 = step.count2;

          if (index > 0) {
            accumulatedCount2 += step.count2;
            count1 = accumulatedCount2 - count2;
          }

          // Calculate new stored time if selectedIndex > 0
          if (index === selectedIndex) {
            storedTime.add(count1, "minutes");
          }

          return {
            x: step.title,
            y: [count1, count1 + count2, count2],
            // unit: step.time_unit,
          };
        })
        .reverse();

      const sumCount2UpToIndex = (steps, index) => {
        return steps
          .slice(0, index)
          .reduce((sum, step) => sum + step.count2, 0);
      };
      let sumCount2Minutes = 0;
      console.log("selectedIndex", selectedIndex);
      for (let i = 0; i < selectedIndex; i++) {
        const step = steps[i];
        if (step) {
          if (step.time_unit === "hours") {
            sumCount2Minutes += step.count2 * 60;
          } else if (step.time_unit === "minutes") {
            sumCount2Minutes += step.count2;
          } else if (step.time_unit === "seconds") {
            sumCount2Minutes += step.count2 / 60;
          }
        }
      }

      // Set stored time only if selectedIndex > 0
      if (selectedIndex > 0) {
        const totalCount2 = sumCount2UpToIndex(inputData?.steps, selectedIndex);
        // const newStoredStartTime = moment(inputData?.start_time,"HH:mm")
        //   .add(sumCount2Minutes, "minutes")
        //   .format("hh:mm a");
        // setStoredStartTime(newStoredStartTime);
        const newStoredStartTime =
          inputData?.steps[selectedIndex]?.time_unit === "seconds"
            ? moment(inputData?.start_time, "HH:mm:ss")
                .add(sumCount2Minutes, "minutes")
                .format("hh:mm:ss a") // Format to HH:mm:ss
            : moment(inputData?.start_time, "HH:mm")
                .add(sumCount2Minutes, "minutes")
                .format("hh:mm a"); // Format to HH:mm if not seconds
        setStoredStartTime(newStoredStartTime);

        const startDate = new Date(
          `${inputData?.date}T${inputData?.start_time}`
        );

        const newStoredStartDate =
          inputData?.steps[selectedIndex]?.time_unit === "seconds"
            ? moment(startDate)
                .add(sumCount2Minutes, "minutes")
                .format("DD/MM/YYYY") // Format to HH:mm:ss
            : moment(startDate)
                .add(sumCount2Minutes, "minutes")
                .format("DD/MM/YYYY"); // Format to HH:mm if not seconds
        setStoredStartDateForHour(newStoredStartDate);

        setStoredStartDate(
          moment(inputData?.date).add(totalCount2, "days").format("YYYY-MM-DD")
        );
      } else {
        setStoredStartTime(
          moment(inputData.start_time, "HH:mm").format("hh:mm a")
        );
        setStoredStartDate(moment(inputData?.date).format("YYYY-MM-DD"));
        setStoredStartDateForHour(moment(inputData?.date).format("DD/MM/YYYY"));
      }

      console.log("formattedChartData-->", formattedData);
      setChartData(formattedData);
      setAllData(inputData.steps);
    }
  }, [inputData, selectedIndex, steps]);

  const parseTimeTaken = (timeTaken) => {
    if (!timeTaken) {
      return;
    }
    let totalSeconds = 0;

    const parts = timeTaken.split(" - ");

    parts.forEach((part) => {
      const [value, unit] = part?.split(" ");

      switch (unit) {
        case "days":
        case "day":
          totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
          break;
        case "hours":
        case "hour":
          totalSeconds += parseInt(value, 10) * 3600;
          break;
        case "mins":
        case "min":
          totalSeconds += parseInt(value, 10) * 60;
          break;
        case "secs":
        case "sec":
          totalSeconds += parseInt(value, 10);
          break;
        default:
          totalSeconds += parseInt(value, 10) * 60;
          break;
      }
    });

    return totalSeconds;
  };
  const getMeeting = async () => {
    try {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
      const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
      // Format date
      const year = currentTime.getFullYear();
      const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
      const day = currentTime.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setLoading(true);
      setIsDisabled(true);
      const REQUEST_URL = fromReport
        ? `${API_BASE_URL}/showPublicMeeting/${meetingId}`
        : `${API_BASE_URL}/get-meeting/${meetingId}?current_time=${formattedTime}&current_date=${formattedDate}`;
      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        const updatedSteps = response.data?.data.steps;
        setStepNotes(updatedSteps?.map((step) => step?.note));
        setInputData(response?.data?.data);
        // setSteps(updatedSteps)

        // setInputData({ ...response.data?.data, steps: updatedSteps });
        const { steps } = response.data?.data;

        setMySteps(steps);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setIsDisabled(false);
    }
  };

  const colors = steps
    ?.map((step) => {
      if (
        step?.step_status === "in_progress" &&
        (step?.delay >= "00d:00h:01m" ||
          parseTimeTaken(step?.time_taken) >
            convertCount2ToSeconds(step?.count2?.step?.time_unit))
      ) {
        return "#FF4560"; // Red
      } else if (step?.step_status === "completed") {
        return "#00E396"; // Green
      } else if (step?.step_status === null) {
        return "#008FFB"; // Blue
      } else {
        return "#f2db43"; // Green
      }
    })
    .reverse();

  const localizeTimeTakenActive = (timeTaken) => {
    if (!timeTaken) return "";

    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    // Split the timeTaken string by " - " to separate time components
    const timeParts = timeTaken.split(" - ");

    // Initialize variables for each time component
    let days = null;
    let hours = null;
    let minutes = null;
    let seconds = null;

    // Iterate over each part and assign it to the corresponding variable
    timeParts.forEach((part) => {
      if (part.includes("day")) {
        days = part;
      } else if (part.includes("hour")) {
        hours = part;
      } else if (part.includes("min")) {
        minutes = part;
      } else if (part.includes("sec")) {
        seconds = part;
      }
    });

    // Check if days are present
    const hasDays = Boolean(days);

    // Determine what to show based on the presence of days
    let result = "";
    if (hasDays) {
      // Show days and hours if days are present
      result = [days, hours].filter(Boolean).join(" - ");
    } else if (hours) {
      // Show only hours and minutes if hours and minutes are present
      result = [hours, minutes].filter(Boolean).join(" - ");
    } else if (minutes) {
      // Show minutes only if no days or hours are present
      // result = minutes;
      result = [minutes, seconds].filter(Boolean).join(" - ");
    } else {
      result = seconds;
    }

    // Return empty string if result is undefined or empty
    if (!result) return "";

    // Localize and return the result
    return result
      .split(" ")
      .map((part) => (isNaN(part) ? timeUnits[part] || part : part))
      .join(" ");
  };
  const RemoveTime = (time_taken, time_unit) => {
    if (!time_taken) return;
    if (time_unit === "days") {
      // Split the time_taken string by ' - ' and only keep the first two parts (days and hours)
      const parts = time_taken?.split(" - ");
      if (parts?.length > 2) {
        return parts.slice(0, 2).join(" - ");
      }
    }
    return time_taken;
  };

  const options = {
    xaxis: {
      type: "category",
      labels: {
        show: false,
        // formatter: function (val) {
        //   if (data?.type === "Action") {
        //     return val + " Days";
        //   } else {
        //     return val + " Mins";
        //   }
        // },
        formatter: function (val, index, opts) {
          const step = steps[index];
          const timeTaken = step?.time_taken || "";
          const firstValue = timeTaken.split(" - ")[0]; // Get the first value before the dash

          return firstValue;
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (val, index) {
          return val;
        },
      },
    },
    chart: {
      height: 650,
      zoom: false,
      type: "rangeBar",
      events: {
        click: function (event, chartContext, config) {
          const { dataPointIndex } = config;
          console.log("dataPointIndex: ", dataPointIndex);
          setChartData((prevChartData) => {
            if (
              dataPointIndex !== undefined &&
              dataPointIndex >= 0 &&
              dataPointIndex < prevChartData?.length
            ) {
              const updatedSelectedIndex =
                prevChartData.length - 1 - dataPointIndex;
              setSelectedIndex(updatedSelectedIndex);
              const clickedChartData = prevChartData[dataPointIndex];
              if (
                clickedChartData &&
                clickedChartData.y &&
                clickedChartData.y.length === 3
              ) {
                const bar2 = clickedChartData.y;
                const selectedCounts = prevChartData
                  .slice(dataPointIndex + 1)
                  .map((item) =>
                    item.y && item.y.length === 3 ? item.y[2] : 0
                  );
                const totalSelectedCount = selectedCounts.reduce(
                  (sum, count) => sum + count,
                  0
                );

                const newAccumulatedSelectedCounts = [
                  ...accumulatedSelectedCounts,
                  totalSelectedCount,
                ];

                const startTime = moment(
                  inputDataRef.current["start_time"],
                  "HH:mm"
                )
                  .add(
                    newAccumulatedSelectedCounts.reduce(
                      (sum, count) => sum + count,
                      0
                    ),
                    "minutes"
                  )
                  .format("hh:mm a");

                setSelectedBar(clickedChartData.x);
                setSelectedValue(clickedChartData.x);
                setSelectedCount(bar2[2]);
                setTotalSelectedCount(totalSelectedCount);
                // setStoredStartTime(startTime);
                setAccumulatedSelectedCounts(newAccumulatedSelectedCounts);

                const selectedStep = data?.steps[updatedSelectedIndex];
                if (selectedStep?.step_status === "completed") {
                  setDoneMomentsModal(true);
                } else if (selectedStep?.step_status === "in_progress") {
                  // setIsModalOpen(true);
                  changeStatusAndPlay(data);
                } else {
                  setIsModalOpen(true);
                }
              }
            }

            return prevChartData;
          });
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        borderRadius: 15,
        barHeight: 35,
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      x: {
        show: true,
        formatter: function (val, opts) {
          return val; // Or any specific logic for x-axis tooltip
        },
      },
      y: {
        formatter: function (val, opts) {
          let step;
          let sanitizedTimeTaken = 0; // Default message

          if (steps && opts && opts.dataPointIndex !== undefined) {
            const reversedIndex = steps.length - 1 - opts.dataPointIndex;
            step = steps[reversedIndex];

            if (
              step &&
              step.time_taken &&
              step?.step_status === "in_progress"
            ) {
              sanitizedTimeTaken = step.time_taken;
            }
          }

          console.log("Tooltip options:", opts);
          console.log("Step:", step);
          console.log("Sanitized Time Taken:", sanitizedTimeTaken);

          return sanitizedTimeTaken && sanitizedTimeTaken;
        },
      },
    },

    // tooltip: {
    //   enabled: true,
    //   x: {
    //     show: false,
    //     // formatter: function (val) {
    //     //   return val;
    //     // },
    //     // formatter: function (val, index, opts) {
    //     //   const step = data?.steps[index];
    //     //   const timeTaken = step?.time_taken || '';
    //     //   return timeTaken;
    //     // },
    //   },
    // },
    grid: {
      row: {
        colors: ["#fff", "#fff"],
        opacity: 1,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["black"],
        fontSize: "8px",
      },
      formatter: function (val, opts) {
        const reversedIndex = steps?.length - 1 - opts.dataPointIndex;
        console.log("reverseIndex", reversedIndex);
        const step = steps[reversedIndex];
        console.log("Step Data:", step);

        const sanitizedTimeTaken = localizeTimeTakenActive(
          step?.time_taken?.replace("-", "")
        );
        console.log("Sanitized Time Taken:", sanitizedTimeTaken);

        if (
          step?.step_status === "in_progress" ||
          step?.step_status === "completed"
        ) {
          return (
            RemoveTime(sanitizedTimeTaken, step?.time_unit) +
            " / " +
            step?.count2 +
            " " +
            t(`time_unit.${step.time_unit}`)
          );
        } else {
          return step?.count2 + " " + t(`time_unit.${step.time_unit}`);
        }
      },
    },
    colors: colors,
  };

  const [assignUser, setAssignUser] = useState(null);
  const [stepOrder, setStepOrder] = useState(null);
  //   const [isModalOpen,setIsModalOpen] = useState(false);
  useEffect(() => {
    if (inputData && inputData?.steps && selectedIndex >= 0) {
      const updatedStep = [...(inputData?.steps || [])];
      const selectedStep = updatedStep[selectedIndex];

      //   if(selectedStep.step_status === null){
      //     setIsModalOpen(true)
      //   }
      console.log("selected-----------------", selectedStep);
      const currentStep = updatedStep[selectedIndex]?.count2;
      console.log("selected step:", selectedStep);
      setFileName(selectedStep?.file);
      setShowPreview(true);
      setLink(selectedStep?.url);
      setPreviewUrl(selectedStep?.url);
      // setFileName(
      //   selectedStep?.file !== null
      //     ? selectedStep?.editor_content
      //     : selectedStep?.file
      // );
      // setType(selectedStep?.editor_content);
      setSelectedCount(currentStep);
      setStepOrder(selectedStep?.order);
      setModalType(
        selectedStep?.editor_type !== null
          ? selectedStep?.editor_type
          : "Editeur"
      );
      setTimeUnit(selectedStep?.time_unit);

      setAssignUser(
        selectedStep?.assigned_to_name === null
          ? inputData?.user?.last_name !== null
            ? inputData?.user?.name + " " + inputData?.user?.last_name
            : inputData?.user?.name
          : selectedStep?.assigned_to_name
      );
    }
  }, [inputData, selectedIndex, user, assignUser]);

  // -------------------------OPTIMIZE EDITOR CONTENT-------------------------
  const { total_Time, updateTotalTime } = useTotalTime();
  const [stepNotes, setStepNotes] = useState([]);

  const [mySteps, setMySteps] = useState([]);

  const [nextId, setNextId] = useState(null);
  const handleEdit = async () => {
    setIsEdited(true);
    setIsDisabled(true);
    setFileUpload(null);
    // setUser("");
    const updatedSteps = [...(inputData?.steps || [])];
    const selectedStep = updatedSteps[selectedIndex];

    console.log("selectedStep", selectedStep);
    const count2Difference = parseInt(selectedCount, 10) - selectedStep?.count2;
    if (puller !== undefined) {
      puller(updatedSteps);
    }
    const isUnique = updatedSteps.every(
      (step, index) => index === selectedIndex || step.title !== selectedValue
    );
    if (!isUnique) {
      // toast.error("Le nom de l'étape doit être unique.");
      toast.error(t("meeting.chart.error.unique"));
      setIsEdited(false);
      setIsDisabled(false);
      setNextBtnText("Suivant");
      return;
    }
    // setEditorContent(selectedStep?.editor_content);
    selectedStep.editor_content = modifiedFileText[selectedIndex];
    if (modalType === "Editeur") {
      selectedStep.editor_type = "Editeur";
      setModalType("Editeur");
    } else if (modalType === "Url") {
      // selectedStep.editor_type = modalType;
      selectedStep.editor_type = "Url";
      selectedStep.editor_content = "";
      selectedStep.link = link;
      setLink(link);
      setModalType("Url");
    } else if (modalType === "File") {
      // selectedStep.editor_type = modalType;
      selectedStep.editor_type = "File";
      // selectedStep.file = fileUpload;
      // setFileUpload(fileUpload);
    }
    selectedStep.title = selectedValue;
    selectedStep.count2 = parseInt(selectedCount, 10);
    // console.log("time->", selectedCount);
    // selectedStep.count1 = selectedCount;
    for (let i = selectedIndex + 1; i < updatedSteps?.length; i++) {
      const currentStep = updatedSteps[i];
      currentStep.count1 += count2Difference;
    }
    // Update the time value for each step based on count1 and count2
    // let currentTime = selectedStep?.count1 + selectedStep?.count2;
    let currentTime = selectedStep?.count2;
    selectedStep.time = currentTime;

    for (let i = selectedIndex + 1; i < updatedSteps.length; i++) {
      const currentStep = updatedSteps[i];
      currentTime += currentStep?.count2;
      currentStep.time = currentTime;
    }

    const countSum = updatedSteps.reduce((sum, step) => sum + step.count2, 0);
    setTotalTime(countSum);

    const myStep = updatedSteps[selectedIndex + 1]?.count2;
    // let accumulatedSelectedCount = myStep;
    let accumulatedSelectedCount = 0;
    for (let i = 0; i < selectedIndex + 1; i++) {
      accumulatedSelectedCount += updatedSteps[i]?.count2;
    }
    console.log("accumulatedSelectedCount--->", accumulatedSelectedCount);

    // if (selectedIndex > 0) {
    const newStoredStartTime = moment(inputData.start_time, "HH:mm")
      .add(accumulatedSelectedCount, "minutes")
      .format("hh:mm a");
    console.log("newStoredStartTime--->", newStoredStartTime);
    setStoredStartTime(newStoredStartTime);
    // }

    //-------- CLOUD LOGIC ------------------------------
    const optimizedEditorContent = await optimizeEditorContent(
      selectedStep.editor_content
    );
    const updatedMeetingData = {
      title: selectedStep.title,
      count1: selectedStep.count1,
      count2: selectedStep.count2,
      time_unit:
        data?.type === "Action"
          ? "days"
          : data?.type === "Task"
          ? "hours"
          : data?.type === "Quiz"
          ? "seconds"
          : "minutes",
      time: selectedStep.count2,
      editor_type: selectedStep.editor_type,
      editor_content:
        selectedStep.editor_type === "Editeur"
          ? optimizedEditorContent || ""
          : null,
      file:
        selectedStep.editor_type === "File"
          ? fileName
            ? fileName
            : null
          : null,
      url: selectedStep.editor_type === "Url" ? (link ? link : null) : null,
      status: "active",
      assigned_to: user,
      order_no: selectedStep.order_no,
      _method: "put",
    };

    try {
      // setIsDisabled(true);
      const response = await axios.post(
        `${API_BASE_URL}/steps/${selectedStep?.id}`,
        updatedMeetingData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        setIsEdited(false);
        setIsDisabled(false);
        setIsModalOpen(false);
        toggle(true);
        // setNextId(response.data.data?.id);
        // await getStep();
        updateTotalTime(countSum);
        // setNextBtnText("Suivant");
        // setUser(null);
        // LOGIC FOR GOIG TO NEXT STEP ----------------
        const nextIndex = selectedIndex + 1;
        const index = selectedIndex;
        getMeeting();

        // --------------------------------------------
      }
    } catch (error) {
      setNextBtnText("Suivant");
      // toast.error("Échec de la copie de l'étape");
      toast.error(t("meeting.chart.error.failed"));
    } finally {
      setIsEdited(false);
      setIsDisabled(false);
    }
  };

  console.log("inputData?.steps[selectedIndex]", selectedIndex);
  const getStep = async () => {
    if (!inputData) {
      return;
    }
    try {
      const response = await axios.get(
        `${API_BASE_URL}/steps/${inputData?.steps[selectedIndex]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      // console.clear("clear");
      if (response.status) {
        setShowPreview(true);
        setFileName(response.data.data.file);
        setLink(response.data?.data?.url);
        setAssignUser(response.data?.data?.assigned_to_name);

        setPreviewUrl(response?.data?.data?.url);
        setUser(response.data?.data?.assigned_to);
      }
    } catch (error) {
      console.log("error while processing get step", error);
    }
  };

  useEffect(() => {
    getStep();
  }, [id, selectedIndex, nextId, meetingId, inputData]);

  useEffect(() => {
    getMeeting();
  }, [id, meetingId]);

  const handleChange1 = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleIncrementCount = () => {
    setSelectedCount((prevCount) => prevCount + 1);
  };
  const handleDecrementCount = () => {
    setSelectedCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    toggle(true);
  };

  const [previousId, setPreviousId] = useState(null);

  const getPreviousStep = async () => {
    const updatedSteps = [...(inputData?.steps || [])];
    const selectedStep = updatedSteps[selectedIndex];

    try {
      const response = await axios.get(`${API_BASE_URL}/steps/${previousId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      // console.clear("clear")
      if (response.status) {
        setFileName(response.data.data.file);
        setLink(response.data.data?.url);
        setPreviewUrl(response.data.data?.url);
        // setModifiedFileText[previousId](response.data?.data?.editor_content);
      }
    } catch (error) {
      console.log("error while processing get step", error);
    }
  };

  useEffect(() => {
    getPreviousStep();
  }, []);

  const nextStep = () => {
    const updatedSteps = [...(inputData?.steps || [])];
    const selectedStep = updatedSteps[selectedIndex + 1]?.count2;

    const updatedChartData = [...chartData];
    const slideIndex = updatedChartData.length - selectedIndex - 1;
    updatedChartData[slideIndex].x = selectedValue;

    let accumulatedSelectedCount = selectedStep;
    for (let i = 0; i < selectedIndex + 1; i++) {
      accumulatedSelectedCount += updatedSteps[i]?.count2;
    }

    const newStoredStartTime = moment(inputData.start_time, "HH:mm")
      .add(accumulatedSelectedCount, "minutes")
      .format("hh:mm a");
    // Update the state with new storedStartTime
    setStoredStartTime(newStoredStartTime);
    // LOGIC FOR GOIG TO NEXT STEP ----------------

    const nextIndex = selectedIndex + 1;
    if (nextIndex < updatedSteps?.length) {
      const nextStep = updatedSteps[nextIndex];
      const nextChartDataIndex = updatedChartData.length - nextIndex - 1;
      const nextSelectedBar = updatedChartData[nextChartDataIndex].x;
      const nextSelectedValue = nextStep?.title;
      const nextSelectedCount = nextStep?.count2;

      setSelectedBar(nextSelectedBar);
      setSelectedValue(nextSelectedValue);
      setSelectedCount(nextSelectedCount);
      setSelectedIndex(nextIndex);
      // setStoredStartTime(newStoredStartTime);
    } else {
      setIsModalOpen(false);
      setSelectedIndex(null);
      setSelectedValue(null);
      setSelectedCount(null);
    }
  };

  const editorRef = useRef(null);
  // const changeStatusAndPlay = async (item) => {
  //   console.log("change", item);
  //   let current_time;
  //    let start_date;

  //   /*
  //   jo step in_progress ma ha us ka current_time ly lia ha k wo kis time play hua tha or phir us current_time ko real_time sy minus kia ha delay find krny k liye
  //   */
  //   // Find the step with step_status "in_progress"
  //   for (const step of item?.steps || []) {
  //     if (step?.step_status === "in_progress" && step?.current_time) {
  //       current_time = step.current_time;
  //       start_date = step?.start_date
  //       break;
  //     }
  //   }

  //   if (!current_time) {
  //     console.log("No in-progress step with current_time found.");
  //     return;
  //   }

  //   console.log("current_time from in-progress step:", current_time);

  //   // Convert current_time to a Date object
  //   const [currentHours, currentMinutes, currentSeconds] = current_time
  //     .split(":")
  //     .map(Number);
  //   const currentDateTime = new Date(start_date);
  //   // const currentDateTime = new Date();
  //   currentDateTime.setHours(currentHours, currentMinutes, currentSeconds, 0);

  //   console.log("currentDateTime:", currentDateTime);

  //   const realCurrentTime = new Date();
  //   console.log("realCurrentTime:", realCurrentTime);

  //   const differenceInMilliseconds = realCurrentTime - currentDateTime;
  //   const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  //   console.log("difference in seconds:", differenceInSeconds);
  //   let endDate1;
  //   // const meetingDate = item.date; // Get the meeting date

  //   // Filter steps to find the first step that is in progress and has an end_time
  //   const inProgressStep = (item?.steps || []).find(
  //     (step) => step?.status === "in_progress" && step?.end_time
  //   );

  //   const meetingDate = start_date?.split('T')[0]
  //   if (inProgressStep) {
  //     endDate1 = `${meetingDate}T${inProgressStep.end_time}`; // Combine date and end_time
  //   } else {
  //     console.log("No in-progress step with end_time found.");
  //     return;
  //   }

  //   // Parse the combined date and time into a Date object
  //   endDate1 = new Date(endDate1);

  //   if (isNaN(endDate1)) {
  //     console.log("Invalid endDate1:", endDate1);
  //     return;
  //   }

  //   const differenceInMillisecondsSaveWala = currentDateTime - endDate1;
  //   const differenceInSecondsSave = Math.floor(
  //     differenceInMillisecondsSaveWala / 1000
  //   );

  //   if (isNaN(differenceInSeconds)) {
  //     console.error("differenceInSeconds is NaN");
  //     return;
  //   }

  //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //   try {
  //     let totalNegativeTime = 0;
  //     const convertSecondsToDDHHMMSS = (seconds) => {
  //       if (isNaN(seconds)) {
  //         console.error("seconds is NaN");
  //         return "NaN";
  //       }

  //       const days = Math.floor(seconds / (24 * 3600));
  //       seconds %= 24 * 3600;
  //       const hours = Math.floor(seconds / 3600);
  //       seconds %= 3600;
  //       const minutes = Math.floor(seconds / 60);
  //       seconds %= 60;
  //       return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
  //         2,
  //         "0"
  //       )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
  //         2,
  //         "0"
  //       )}s`;
  //     };

  //     const delay = convertSecondsToDDHHMMSS(differenceInSeconds);
  //     console.log("formatted delay:", delay);
  //     const updatedSteps = item?.steps?.map((step) => {
  //       console.log("my step--->",step)
  //       if (step.step_status === "in_progress") {
  //         const savedTime = Number(step.savedTime) || 0;
  //         // const actualStepTimeInDays = Number(step.count2 * 86400)
  //         // Calculate actual step time in days if the time_unit is in days
  //         let actualStepTimeInDays = 0;
  //         if (step.time_unit === "days") {
  //           actualStepTimeInDays = Number(step.count2 * 86400); // if count2 is in days convert into seconds
  //         } else {
  //           actualStepTimeInDays = Number(step.count2 * 60); // convert minute into seconds
  //         }
  //         console.log('actualStepTimeInDays', actualStepTimeInDays)
  //         const negativeTime = Number(step.negative_time) || 0;
  //         console.log("saved time: ", savedTime);
  //         console.log("negative time: ", negativeTime);
  //         let newSavedTime = savedTime - differenceInSeconds;
  //         let newSavedTime2 = savedTime - differenceInSecondsSave;
  //         let newSavedTimeAfterDiff =
  //           actualStepTimeInDays - differenceInSeconds;

  //         let newSavedTimeAfterDiff2 =
  //           actualStepTimeInDays - differenceInSecondsSave;

  //         let newNegativeTime = negativeTime;

  //         if (newSavedTime < 0) {
  //           newNegativeTime += Math.abs(actualStepTimeInDays);
  //           actualStepTimeInDays = 0;
  //         } else if (savedTime === 0) {
  //           newNegativeTime += differenceInSeconds;
  //         }

  //         console.log("new saved time1: ", newSavedTime);
  //         console.log("new saved time2: ", newSavedTime2);
  //         console.log("new negative time: ", newNegativeTime);
  //         return {
  //           ...step,
  //           // savedTime: newSavedTime,
  //           savedTime: newSavedTimeAfterDiff,
  //           negative_time: newNegativeTime > 0 ? "99" : 0,
  //           // delay: newSavedTime === 0 && delay,
  //           // delay: step.savedTime === 0 || (step.savedTime === null && delay),
  //           delay: actualStepTimeInDays === 0 && delay,

  //           // delay: delay,
  //         };
  //       } else {
  //         return {
  //           ...step,
  //         };
  //       }
  //     });

  //     const payload = {
  //       ...item,
  //       _method: "put",
  //       end_time: item.end_time, // Ensure this is still set correctly
  //       delay: delay,
  //       steps: updatedSteps,
  //     };

  //     const response = await axios.post(
  //       `${API_BASE_URL}/meetings/${item.id}`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     if (response.status) {
  //       navigate(`/play/${item.id}`);
  //     }
  //   } catch (error) {
  //     console.log("error while updating meeting status", error);
  //   }
  // };
  const convertSecondsToDDHHMMSS = (seconds) => {
    if (isNaN(seconds)) {
      console.error("seconds is NaN");
      return "NaN";
    }

    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
      2,
      "0"
    )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
      2,
      "0"
    )}s`;
  };


  const parseDelayStringToSeconds = (delayString) => {
    if (!delayString) return 0;

    console.log("delayString", delayString);

    // Use a regular expression to match numbers, and then use map(Number) to convert them to numbers.
    const [daysPart, hoursPart, minutesPart, secondsPart] = delayString
      .match(/\d+/g)
      .map(Number);

    // Calculate the total seconds
    const totalSeconds =
      daysPart * 24 * 3600 + hoursPart * 3600 + minutesPart * 60 + secondsPart;
    return totalSeconds;
  };
  const userId = parseInt(sessionStorage.getItem("user_id"));

  const changeStatusAndPlay = async (item) => {
    if (parseInt(item.user_id) !== userId) {
      return;
    }
    console.log("change->", item);
    let current_time;
    let current_date;
    let end_date;

    // Find the step with step_status "in_progress"
    const inProgressStep = item?.steps?.find(
      (step) => step?.step_status === "in_progress"
    );
    // Find the step with step_status "in_progress"
    for (const step of item?.steps || []) {
      if (step?.step_status === "in_progress" && step?.step_time) {
        current_date = step.current_date || step?.start_date;
        current_time = step.step_time;
        end_date = step?.end_date;
        break;
      }
    }

    if (!current_time || !current_date) {
      console.log("No in-progress step with current_time found.");
      return;
    }

    console.log("step_time from in-progress step:", current_time);
    console.log("end_date from in-progress step:", end_date);

    // const [currentHours, currentMinutes, currentSeconds] = current_time
    //   ?.split(":")
    //   .map(Number);

    // Parse time with AM/PM
    const [time, meridiem] = current_time.split(" ");
    let [currentHours, currentMinutes, currentSeconds] = time
      .split(":")
      .map(Number);
    if (meridiem.toLowerCase() === "pm" && currentHours < 12) {
      currentHours += 12;
    } else if (meridiem.toLowerCase() === "am" && currentHours === 12) {
      currentHours = 0;
    }
    // Split the date string and create a new Date object
    const [day, month, year] = current_date?.split("-").map(Number);
    console.log("Parsed year:", year, "month:", month, "day:", day);
    const myDate = `${day}/${month}/${year}`;
    const currentDateTime = new Date(myDate);

    // Update the Date object with the extracted hours, minutes, and seconds
    currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);
    console.log("currentDateTime:", currentDateTime);

    const realCurrentTime = new Date();
    console.log("realCurrentTime:", realCurrentTime);

    const count2InSeconds = convertCount2ToSeconds(
      inProgressStep?.count2,
      inProgressStep?.time_unit
    );

    const differenceInMilliseconds = realCurrentTime - currentDateTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    console.log(
      "difference in seconds: -> end_time - real_time",
      differenceInSeconds
    );

    // Subtract count2InSeconds from differenceInSeconds
    const adjustedDifferenceInSeconds = differenceInSeconds - count2InSeconds;

    console.log("Adjusted difference in seconds:", adjustedDifferenceInSeconds);

    localStorage.setItem("difference", differenceInSeconds);
    if (isNaN(differenceInSeconds)) {
      console.error("differenceInSeconds is NaN");
      return;
    }

    const convertSecondsToDDHHMMSS = (seconds) => {
      if (isNaN(seconds)) {
        console.error("seconds is NaN");
        return "NaN";
      }

      const days = Math.floor(seconds / (24 * 3600));
      seconds %= 24 * 3600;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
        2,
        "0"
      )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
        2,
        "0"
      )}s`;
    };

    const delay = convertSecondsToDDHHMMSS(adjustedDifferenceInSeconds);
    console.log("formatted delay:", delay);

    const updatedSteps = item?.steps?.map((step) => {
      console.log("my step--->", step);
      if (step.step_status === "in_progress") {
        let savedTime = Number(step.savedTime) || 0;

        let actualStepTime = 0;
        if (step.time_unit === "days") {
          actualStepTime = Number(step.count2 * 86400);
        } else if (step.time_unit === "hours") {
          actualStepTime = Number(step.count2 * 3600);
        } else if (step.time_unit === "minutes") {
          actualStepTime = Number(step.count2 * 60);
        } else {
          actualStepTime = Number(step.count2);
        }

        console.log("actualStepTime", actualStepTime);
        const negativeTime = Number(step.negative_time) || 0;
        console.log("saved time: ", savedTime);
        console.log("negative time: ", negativeTime);

        let newSavedTime = savedTime - differenceInSeconds;
        let newNegativeTime = negativeTime;

        let newTime = actualStepTime - differenceInSeconds;
        localStorage.setItem("newTime", newTime);
        // Adjust savedTime
        if (newTime >= 0) {
          savedTime = newTime;
          if (newTime < 0) savedTime = 0;
        }
        if (differenceInSeconds <= actualStepTime) {
          console.log("difference smaller than actualStepTime");
          step.delay = null;
          step.savedTime = savedTime;
          step.negative_time = newNegativeTime;
        } else {
          console.log("difference greater than actualStepTime");
          step.delay = delay;
          step.savedTime = 0;
          step.negative_time = "99";
        }

        console.log("new saved time1: ", newSavedTime);
        console.log("new negative time: ", newNegativeTime);

        return {
          ...step,
          delay: step.delay,
          savedTime: step.savedTime,
          negative_time: step.negative_time,
        };
      } else {
        return {
          ...step,
        };
      }
    });

    console.log("updatedSteps->", updatedSteps);

    const payload = {
      ...item,
      _method: "put",
      end_time: item.end_time,
      delay: delay,
      steps: updatedSteps,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${item.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        navigate(`/play/${item.id}`);
        if (item?.meet_link) {
          openGoogleMeet(item?.meet_link);
        }
      }
    } catch (error) {
      console.log("error while updating meeting status", error);
    }
  };
  //   const changeStatusAndPlay = async (item) => {
  //     console.log("change->", item);
  //     let current_time;
  //     let current_date;
  //     let end_date;

  //     /*
  // jo step in_progress ma ha us ka end_time ly lia ha k wo kis time play hua tha or phir us end_time ko real_time sy minus kia ha delay find krny k liye
  // */
  //     // Find the step with step_status "in_progress"
  //     for (const step of item?.steps || []) {
  //       if (step?.step_status === "in_progress" && step?.current_time) {
  //         current_date = step.current_date;
  //         current_time = step.current_time;
  //         end_date = step?.end_date;
  //         break;
  //       }
  //     }

  //     if (!current_time || !current_date) {
  //       console.log("No in-progress step with current_time found.");
  //       return;
  //     }

  //     console.log("current_time from in-progress step:", current_time);
  //     console.log("end_date from in-progress step:", end_date);

  //     const [currentHours, currentMinutes, currentSeconds] = current_time
  //       ?.split(":")
  //       .map(Number);

  //     // Split the date string and create a new Date object
  //     const [day, month, year] = current_date?.split("-").map(Number);
  //     console.log("Parsed year:", year, "month:", month, "day:", day);
  //     const myDate = `${day}/${month}/${year}`;
  //     const currentDateTime = new Date(myDate);
  //     // Update the Date object with the extracted hours, minutes, and seconds
  //     currentDateTime.setHours(currentHours, currentMinutes, currentSeconds);
  //     console.log("currentDateTime:", currentDateTime);

  //     const realCurrentTime = new Date();
  //     console.log("realCurrentTime:", realCurrentTime);

  //     const differenceInMilliseconds = realCurrentTime - currentDateTime;
  //     const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  //     console.log(
  //       "difference in seconds: -> end_time - real_time",
  //       differenceInSeconds
  //     ); //dubugging successfull

  //     if (isNaN(differenceInSeconds)) {
  //       console.error("differenceInSeconds is NaN");
  //       return;
  //     }

  //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //     try {
  //       let totalNegativeTime = 0;
  //       const convertSecondsToDDHHMMSS = (seconds) => {
  //         if (isNaN(seconds)) {
  //           console.error("seconds is NaN");
  //           return "NaN";
  //         }

  //         const days = Math.floor(seconds / (24 * 3600));
  //         seconds %= 24 * 3600;
  //         const hours = Math.floor(seconds / 3600);
  //         seconds %= 3600;
  //         const minutes = Math.floor(seconds / 60);
  //         seconds %= 60;
  //         return `${String(days).padStart(2, "0")}d:${String(hours).padStart(
  //           2,
  //           "0"
  //         )}h:${String(minutes).padStart(2, "0")}m:${String(seconds).padStart(
  //           2,
  //           "0"
  //         )}s`;
  //       };

  //       const delay = convertSecondsToDDHHMMSS(differenceInSeconds);

  //       console.log("formatted delay:", delay); // debugging successfull
  //       // ------------------------------------delay tak toh sahi ha calculation

  //       const updatedSteps = item?.steps?.map((step) => {
  //         console.log("my step--->", step);
  //         if (step.step_status === "in_progress") {
  //           const savedTime = Number(step.savedTime) || 0;
  //           // -------------------------------------------------------CORRECT-----------------------------
  //           const parseTimeTaken = (timeTaken) => {
  //             if (!timeTaken) {
  //               return;
  //             }
  //             let totalSeconds = 0;

  //             const parts = timeTaken.split(" - ");

  //             parts.forEach((part) => {
  //               const [value, unit] = part?.split(" ");

  //               switch (unit) {
  //                 case "days":
  //                 case "day":
  //                   totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
  //                   break;
  //                 case "hours":
  //                 case "hour":
  //                   totalSeconds += parseInt(value, 10) * 3600;
  //                   break;
  //                 case "mins":
  //                 case "min":
  //                   totalSeconds += parseInt(value, 10) * 60;
  //                   break;
  //                 case "secs":
  //                 case "sec":
  //                   totalSeconds += parseInt(value, 10);
  //                   break;
  //                 default:
  //                   totalSeconds += parseInt(value, 10) * 60;
  //                   break;
  //               }
  //             });

  //             return totalSeconds;
  //           };

  //           const totalSeconds = parseTimeTaken(step.time_taken);
  //           console.log("totalSeconds: ", totalSeconds);
  //           const time_taken_delay = convertSecondsToDDHHMMSS(totalSeconds);
  //           console.log("time_taken_delay", time_taken_delay);
  //           // Calculate actual step time in days if the time_unit is in days
  //           let actualStepTime = 0;
  //           if (step.time_unit === "days") {
  //             actualStepTime = Number(step.count2 * 86400); // if count2 is in days convert into seconds
  //           } else if (step.time_unit === "hours") {
  //             actualStepTime = Number(step.count2 * 3600); // convert hour into seconds
  //             // actualStepTime = Number(step.count2 * 60); // convert minute into seconds
  //           } else if (step.time_unit === "minutes") {
  //             actualStepTime = Number(step.count2 * 60); // convert minute into seconds
  //           } else {
  //             actualStepTime = Number(step.count2); // convert minute into seconds
  //           }

  //           console.log("actualStepTime", actualStepTime);
  //           // --------------------------------------------------CORRECT ----------------------------------
  //           const negativeTime = Number(step.negative_time) || 0;
  //           console.log("saved time: ", savedTime);
  //           console.log("negative time: ", negativeTime);

  //           // step savedTime - differenceInSeconds(step?.current_time - real_time)
  //           let newSavedTime = savedTime - differenceInSeconds; //---> differenceInSeconds means step_start_time - real_time
  //           // 106 - 55 = 51 //correct
  //           let newSavedTimeAbsolute = Math.abs(savedTime - differenceInSeconds);

  //           // let newSavedTime2 = savedTime - differenceInSecondsSave;
  //           let newSavedTimeAfterDiff = Math.abs(
  //             actualStepTime - differenceInSeconds
  //           );
  //           // let newSavedTimeAfterDiff =
  //           //   actualStepTimeInDays - differenceInSeconds;
  //           //120 - 55 = 65

  //           // let newSavedTimeAfterDiff2 =
  //           //   actualStepTimeInDays - differenceInSecondsSave;

  //           let newNegativeTime = negativeTime;

  //           if (newSavedTime >= 0) {
  //             //agr 0 sy bara ha or newSavedTime -ve ma ha tb bhi
  //             console.log("0 sy greater than or equal wali condition");
  //             newNegativeTime = 0;
  //             step.delay = null;
  //             step.savedTime = newSavedTime;
  //             // newNegativeTime += Math.abs(actualStepTimeInDays);
  //             // actualStepTimeInDays = 0;
  //           } else {
  //             step.negative_time = "99";
  //             step.savedTime = 0;
  //             newNegativeTime += Math.abs(actualStepTime);
  //             console.log("newNegativeTime->", newNegativeTime);
  //             actualStepTime = 0;
  //           }
  //           // if (newSavedTime < 0) {
  //           //   newNegativeTime += Math.abs(actualStepTimeInDays);
  //           //   actualStepTimeInDays = 0;
  //           // } else if (newSavedTime === 0) {
  //           //   newNegativeTime += differenceInSeconds;
  //           // }

  //           console.log("new saved time1: ", newSavedTime);
  //           console.log("newSavedTimeAfterDiff: ", newSavedTimeAbsolute);
  //           // console.log("new saved time2: ", newSavedTime2);
  //           console.log("new negative time: ", newNegativeTime);

  //           const currentTime = new Date();
  //           const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //           const formattedCurrentTine = currentTime.toLocaleString("en-GB", {
  //             timeZone: userTimeZone,
  //             hour: "2-digit",
  //             minute: "2-digit",
  //             second: "2-digit",
  //             hour12: false,
  //           });
  //           const formattedCurrentDate = currentTime.toISOString().split("T")[0];
  //           return {
  //             ...step,
  //             // savedTime: newSavedTime,
  //             // savedTime: newSavedTimeAfterDiff,
  //             savedTime: newSavedTime >= 0 ? newSavedTimeAfterDiff : 0,
  //             negative_time: newNegativeTime > 0 ? "99" : 0,
  //             // delay: newSavedTime === 0 && delay,
  //             // delay: step.savedTime === 0 || (step.savedTime === null && delay),
  //             delay: actualStepTime === 0 ? delay : null,
  //             // new_current_time:
  //             //   actualStepTime === 0 ? formattedCurrentTine : null,
  //             // new_current_date:
  //             //   actualStepTime === 0 ? formattedCurrentDate : null,
  //           };
  //         } else {
  //           return {
  //             ...step,
  //           };
  //         }
  //       });

  //       console.log("updatedSteps->", updatedSteps);

  //       const payload = {
  //         ...item,
  //         _method: "put",
  //         end_time: item.end_time, // Ensure this is still set correctly
  //         delay: delay,
  //         steps: updatedSteps,
  //       };

  //       const response = await axios.post(
  //         `${API_BASE_URL}/meetings/${item.id}`,
  //         payload,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       );

  //       if (response.status) {
  //         navigate(`/play/${item.id}`);
  //       }
  //     } catch (error) {
  //       console.log("error while updating meeting status", error);
  //     }
  //   };

  const deleteStep = async () => {
    setIsDeleted(true);
    const updatedSteps = [...(inputData.steps || [])];
    const selectedStep = inputData?.steps[selectedIndex];
    const deletedStep = updatedSteps.splice(selectedIndex, 1)[0];
    setCountSum((prevCountSum) => prevCountSum - deletedStep.count2);

    // // Update the subsequent steps' count1 values
    for (let i = selectedIndex; i < updatedSteps.length; i++) {
      const currentStep = updatedSteps[i];
      const previousStep = updatedSteps[i - 1];

      if (previousStep) {
        currentStep.count1 = previousStep.count1 + previousStep.count2;
      } else {
        // If there is no previous step, set the count1 to 0
        currentStep.count1 = 0;
      }
    }

    const newLastCountSum = updatedSteps.reduce(
      (sum, step) => sum + step.count2,
      0
    );
    const formattedData = updatedSteps
      .map((item) => ({
        x: item.title,
        y: [item.count1, item.count1 + item.count2, item.count2],
        // y: [item.counts[0], item.counts[0] + item.counts[1], item.counts[1]],
      }))
      .reverse();
    try {
      setIsDisabled(true);
      const response = await axios.delete(
        `${API_BASE_URL}/steps/${deletedStep?.id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        setIsDeleted(false);
        toggle(true);
        // closeModal()
        // toast.success(t("meeting.chart.error.delete"));
        setChartData(formattedData);
        // // setInputData(updatedMeetingData);
        setTotalTime(newLastCountSum);
        updateTotalTime(newLastCountSum);
        setIsModalOpen(false);
        // // setSelectedValue(selectedStep.title)
        setSelectedValue(updatedSteps[selectedIndex - 1].title);
        setSelectedIndex(selectedIndex - 1);
        getMeeting();
      }
    } catch (error) {
      // console.log("error: ", error);
    } finally {
      setIsDisabled(false);
      setIsDeleted(false);
    }
    // toast.success("Data has been deleted permanently.");
  };

  const [isValidate, setIsValidate] = useState(false);
  // const handleEdit = async () => {
  //   setIsEdited(true);
  //   setIsDisabled(true);
  //   setFileUpload(null);
  //   // setLink(null);
  //   // setLink(null);
  //   // setModalType("Editeur")
  //   // setNextBtnText("Suivant...");
  //   setUser("");
  //   const updatedSteps = [...(inputData?.steps || [])];
  //   const selectedStep = updatedSteps[selectedIndex];

  //   console.log("selectedStep", selectedStep);
  //   // if(!selectedStep?.userPID){
  //   //   toast.error('you are not authorized to edit this step.')
  //   //   setIsEdited(false);
  //   //   setIsDisabled(false);
  //   //   return
  //   // }
  //   const count2Difference = parseInt(selectedCount, 10) - selectedStep?.count2;
  //   if (puller !== undefined) {
  //     puller(updatedSteps);
  //   }
  //   const isUnique = updatedSteps.every(
  //     (step, index) => index === selectedIndex || step.title !== selectedValue
  //   );
  //   if (!isUnique) {
  //     // toast.error("Le nom de l'étape doit être unique.");
  //     toast.error(t("meeting.chart.error.unique"));
  //     setIsEdited(false);
  //     setIsDisabled(false);
  //     setNextBtnText("Suivant");
  //     return;
  //   }
  //   // setEditorContent(selectedStep?.editor_content);
  //   selectedStep.editor_content = modifiedFileText[selectedIndex];
  //   if (modalType === "Editeur") {
  //     selectedStep.editor_type = "Editeur";
  //     setModalType("Editeur");
  //   } else if (modalType === "Url") {
  //     // selectedStep.editor_type = modalType;
  //     selectedStep.editor_type = "Url";
  //     selectedStep.editor_content = "";
  //     selectedStep.link = link;
  //     setLink(link);
  //     setModalType("Url");
  //   } else if (modalType === "File") {
  //     // selectedStep.editor_type = modalType;
  //     selectedStep.editor_type = "File";
  //     // selectedStep.file = fileUpload;
  //     // setFileUpload(fileUpload);
  //   }
  //   selectedStep.title = selectedValue;
  //   selectedStep.count2 = parseInt(selectedCount, 10);
  //   // console.log("time->", selectedCount);
  //   // selectedStep.count1 = selectedCount;
  //   for (let i = selectedIndex + 1; i < updatedSteps?.length; i++) {
  //     const currentStep = updatedSteps[i];
  //     currentStep.count1 += count2Difference;
  //   }
  //   // Update the time value for each step based on count1 and count2
  //   // let currentTime = selectedStep?.count1 + selectedStep?.count2;
  //   let currentTime = selectedStep?.count2;
  //   selectedStep.time = currentTime;

  //   for (let i = selectedIndex + 1; i < updatedSteps.length; i++) {
  //     const currentStep = updatedSteps[i];
  //     currentTime += currentStep?.count2;
  //     currentStep.time = currentTime;
  //   }

  //   const countSum = updatedSteps.reduce((sum, step) => sum + step.count2, 0);
  //   setTotalTime(countSum);

  //   const myStep = updatedSteps[selectedIndex + 1]?.count2;
  //   // let accumulatedSelectedCount = myStep;
  //   let accumulatedSelectedCount = 0;
  //   for (let i = 0; i < selectedIndex + 1; i++) {
  //     accumulatedSelectedCount += updatedSteps[i]?.count2;
  //   }
  //   console.log("accumulatedSelectedCount--->", accumulatedSelectedCount);

  //   // if (selectedIndex > 0) {
  //   const newStoredStartTime = moment(inputData.start_time, "HH:mm")
  //     .add(accumulatedSelectedCount, "minutes")
  //     .format("hh:mm a");
  //   console.log("newStoredStartTime--->", newStoredStartTime);
  //   // setStoredStartTime(newStoredStartTime);

  //   const newStoredStartDate = moment(inputData?.date)
  //     .add(accumulatedSelectedCount, "days")
  //     .format("YYYY-MM-DD");
  //   setStoredStartDate(newStoredStartDate);

  //   // const newStoredStartDate =
  //   // inputData?.steps[selectedIndex]?.time_unit === "seconds"
  //   //   ? moment(inputData?.date)
  //   //       .add(sumCount2Minutes, "minutes")
  //   //       .format("DD/MM/YYYY") // Format to HH:mm:ss
  //   //   : moment(inputData?.date)
  //   //       .add(sumCount2Minutes, "minutes")
  //   //       .format("DD/MM/YYYY"); // Format to HH:mm if not seconds
  //   //       setStoredStartDateForHour(newStoredStartDate);
  //   // }

  //   //-------- CLOUD LOGIC ------------------------------
  //   const optimizedEditorContent = await optimizeEditorContent(
  //     selectedStep.editor_content
  //   );

  //   // console.log("optimizedEditorContent: ", optimizedEditorContent);
  //   // // ------- CLOUD LOGIC END -------------------------

  //   // console.log("count1 after add time-->", accumulatedSelectedCount);
  //   // console.log("selectedStep.count1-->", selectedStep.count1);
  //   // return;
  //   const updatedMeetingData = {
  //     ...selectedStep,
  //     title: selectedStep.title,
  //     count1: selectedStep.count1,
  //     count2: selectedStep.count2,
  //     // time_unit: data?.type === "Action" ? "days" : 'minutes',
  //     // time_unit:
  //     //   data?.type === "Action"
  //     //     ? "days"
  //     //     : data?.type === "Quiz"
  //     //     ? "seconds"
  //     //     : "minutes",
  //     time_unit:
  //       data?.type === "Action1"
  //         ? "days"
  //         : data?.type === "Task"
  //         ? "hours"
  //         : data?.type === "Quiz"
  //         ? "seconds"
  //         : "minutes",
  //     time: selectedStep.count2,
  //     editor_type: selectedStep.editor_type,
  //     editor_content:
  //       selectedStep.editor_type === "Editeur"
  //         ? optimizedEditorContent || ""
  //         : null,
  //     file:
  //       selectedStep.editor_type === "File"
  //         ? fileName
  //           ? fileName
  //           : null
  //         : null,
  //     url: selectedStep.editor_type === "Url" ? (link ? link : null) : null,
  //     status: "active",
  //     assigned_to: user,
  //     order_no: selectedStep.order_no,
  //     _method: "put",
  //   };

  //   try {
  //     // setIsDisabled(true);
  //     const response = await axios.post(
  //       `${API_BASE_URL}/steps/${selectedStep?.id}`,
  //       updatedMeetingData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     if (response.status) {
  //       setIsEdited(false);
  //       setIsDisabled(false);
  //       setNextId(response.data.data?.id);
  //       await getStep();

  //       updateTotalTime(countSum);
  //       setNextBtnText("Suivant");
  //       setUser(null);

  //       // LOGIC FOR GOIG TO NEXT STEP ----------------
  //       const nextIndex = selectedIndex + 1;
  //       const index = selectedIndex;
  //       if (nextIndex < updatedSteps?.length) {
  //         const nextStep = updatedSteps[nextIndex];
  //         setNextId(nextStep.id);
  //         const step = updatedSteps[index];
  //         const nextSelectedValue = nextStep?.title;
  //         const nextSelectedCount = nextStep?.count2;
  //         const count = step.count2;
  //         setSelectedValue(nextSelectedValue);
  //         setSelectedCount(nextSelectedCount);
  //         setSelectedIndex(nextIndex);
  //         setStoredStartTime(newStoredStartTime);
  //         setStoredStartDate(newStoredStartDate);
  //         setFileName(nextStep?.editor_content);
  //         setLink(nextStep?.editor_content);
  //         setPreviewUrl(nextStep?.editor_content);

  //         // setAssignUser(step?.assigned_to_name);
  //         // setModalType("Editeur");
  //       } else {
  //         setModalType("");
  //         setFileUpload(null);
  //         setFileName(null);
  //         setLink(null);
  //         setIsModalOpen(false);
  //         setSelectedIndex(null);
  //         setSelectedValue(null);
  //         setSelectedCount(null);
  //       }
  //       // --------------------------------------------
  //     }
  //   } catch (error) {
  //     // console.log("errors", error);
  //     setNextBtnText("Suivant");
  //     // toast.error("Échec de la copie de l'étape");
  //     toast.error(t("meeting.chart.error.failed"));
  //   } finally {
  //     setIsEdited(false);
  //     setIsDisabled(false);
  //   }
  // };

  const [doneMomentsModal, setDoneMomentsModal] = useState(false);
  const handleViewDoneMoments = (item) => {
    setDoneMomentsModal(true);
    // const currentURL = `/present/invite/${item?.id}`;
    // copy(currentURL);
    // navigate(currentURL,  { state: { item, from: 'meeting',disabled:'yes' } })
  };
  return (
    <>
      <div
        id="chart-container"
        className="chart-content "
        // style={{
        //   width: "100%",
        //   height: "auto",
        //   overflow: "hidden",
        //   border: "1px solid #ececec",
        //   borderRadius: "15px",
        //   marginTop: "40px",
        //   padding: "10px",
        // }}
      >
        <ReactApexChart
          options={options}
          key={steps && JSON.stringify(steps)}
          series={[{ data: chartData }]}
          type="rangeBar"
          height={500}
        />
      </div>

      <div
        id="chart-containe"
        className="chart-content"
        // style={{ width: "auto", height: "auto", overflow: "hidden" }}
      >
        {isModalOpen && selectedBar !== null && (
          <div className="new-meeting">
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="modal-nav">
                  <div>
                    {
                      <h4>
                        {location.includes("step-details")
                          ? ""
                          : t("meeting.newMeeting.Edit a step")}
                      </h4>
                    }
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      disabled={isDisabled}
                      className="cross-btn"
                      onClick={handleCloseModal}
                    >
                      <RxCross2 size={18} />
                    </button>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-7">
                    <div className="d-flex justify-content-arround align-items-center gap-4">
                      <div className="input-field">
                        <div
                          style={{
                            flexGrow: 1,
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          <img
                            src="/Assets/Vector.svg"
                            alt="Edit"
                            className="img-fluid edit-icon"
                          />
                        </div>
                        <div style={{ flexGrow: 1, textAlign: "center" }}>
                          <input
                            className="text-center step-name"
                            type="text"
                            placeholder={t("stepModal.title")}
                            value={selectedValue}
                            onChange={handleChange1}
                            disabled={
                              inputData?.steps[selectedIndex]?.participant
                                ?.userPID !==
                                parseInt(sessionStorage.getItem("user_id")) &&
                              parseInt(sessionStorage.getItem("user_id")) !==
                                inputData?.user_id
                                ? true
                                : false ||
                                  window.location.href.includes(
                                    "/meetingDetail"
                                  ) ||
                                  fromReport
                                ? true
                                : false
                            }
                          />
                        </div>

                        <div
                          style={{
                            flexGrow: 1,
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          {selectedIndex + 1}/{chartData?.length}
                        </div>
                      </div>
                      {/* <br /> */}
                      <select
                        className="form-select"
                        style={{
                          width: "13rem",
                        }}
                        // value={modalType === "Editeur" ? "Editeur" : "File"}
                        value={
                          modalType === "Editeur"
                            ? "Editeur"
                            : modalType === "File"
                            ? "File"
                            : "Url"
                        }
                        onChange={(e) => setModalType(e.target.value)}
                        disabled={
                          fromReport ||
                          // (parseInt(sessionStorage.getItem("user_id")) !==
                          //   inputData?.user_id &&
                          //   !inputData?.steps[selectedIndex]?.userPID)
                          (inputData?.steps[selectedIndex]?.participant
                            ?.userPID !==
                            parseInt(sessionStorage.getItem("user_id")) &&
                            parseInt(sessionStorage.getItem("user_id")) !==
                              inputData?.user_id)
                            ? true
                            : false
                        }
                      >
                        <option value={"Editeur"}>
                          {t("stepModal.editor")}
                        </option>
                        <option value={"File"}>{t("stepModal.pdf")}</option>
                        {/* <option value={"Url"}>Url</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-3 modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="text-center col-md-2 col-6">
                        <div className="p-2 card timecard">
                          <p>{t("meeting.newMeeting.The stage starts at")}</p>
                          {/* <h5>{storedStartTime}</h5> */}

                          {data?.type === "Action1" ? (
                            <>
                              <h6 style={{ fontSize: "14px" }}>
                                {storedStartDate}
                              </h6>
                              {/* <h6 style={{fontSize:'14px'}}>{storedStartTime}</h6> */}
                            </>
                          ) : (
                            <>
                              <h5>{storedStartTime}</h5>
                              {storedStartDateForHour}
                            </>
                          )}
                        </div>
                        <br />
                        {!window.location.href.includes("/meetingDetail") && (
                          <div className="p-2 card timecard ">
                            <p>
                              {t(
                                "meeting.newMeeting.Estimated time of the stage"
                              )}
                            </p>
                            <div className="d-flex align-items-center justify-content-around">
                              <div>
                                <img
                                  src="/Assets/minus1.svg"
                                  alt="minus"
                                  className="img-fluid "
                                  width={"15px"}
                                  style={{ cursor: "pointer" }}
                                  // onClick={handleDecrementCount}
                                  onClick={() => {
                                    if (
                                      fromReport ||
                                      // // (parseInt(
                                      // //   sessionStorage.getItem("user_id")
                                      // // ) !== inputData?.user_id &&
                                      //   !inputData?.steps[selectedIndex]
                                      //     ?.userPID)
                                      (inputData?.steps[selectedIndex]
                                        ?.participant?.userPID !==
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) &&
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) !== inputData?.user_id)
                                        ? true
                                        : false
                                    )
                                      return;
                                    handleDecrementCount();
                                  }}
                                />{" "}
                                {/* &nbsp; &nbsp; */}
                                {/* <span>{selectedCount} Min</span>&nbsp;&nbsp; */}
                                <span>{selectedCount}</span>{" "}
                                {/* &nbsp;&nbsp; */}
                                <img
                                  src="/Assets/plus1.svg"
                                  alt="plus"
                                  className="img-fluid"
                                  width={"15px"}
                                  style={{ cursor: "pointer" }}
                                  // onClick={handleIncrementCount}
                                  onClick={() => {
                                    if (
                                      fromReport ||
                                      // (parseInt(
                                      //   sessionStorage.getItem("user_id")
                                      // ) !== inputData?.user_id &&
                                      //   !inputData?.steps[selectedIndex]
                                      //     ?.userPID)
                                      (inputData?.steps[selectedIndex]
                                        ?.participant?.userPID !==
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) &&
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) !== inputData?.user_id)
                                        ? true
                                        : false
                                    ) {
                                      return;
                                    }
                                    handleIncrementCount();
                                  }}
                                />
                              </div>
                              <div>
                                {data?.type === "Action1" ? (
                                  <span> {t("days")} </span>
                                ) : data?.type === "Task" ? (
                                  <span> {t("hours")} </span>
                                ) : data?.type === "Quiz" ? (
                                  <span> {t("sec")} </span>
                                ) : (
                                  <span>mins</span>

                                  // <select
                                  //   className="select-dropdown"
                                  //   value={timeUnit}
                                  //   onChange={(e) =>
                                  //     setTimeUnit(e.target.value)
                                  //   }
                                  // >
                                  //   {/* <option
                                  //     value="seconds"
                                  //     className="option-dropdown"
                                  //   >
                                  //     Seconds
                                  //   </option> */}
                                  //   <option
                                  //     value="minutes"
                                  //     className="option-dropdown"
                                  //   >
                                  //     Minutes
                                  //   </option>
                                  //   {/* <option
                                  //     value="hours"
                                  //     className="option-dropdown"
                                  //   >
                                  //     Hours
                                  //   </option> */}
                                  // </select>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="p-2 mt-3 card timecard">
                          <p>Guide</p>

                          {/* <label className="form-label">
                            {assignUser === null
                              ? `${inputData?.user?.name} ${
                                  inputData?.user?.last_name !== null
                                    ? inputData.user.last_name
                                    : " "
                                }`
                              : assignUser}
                          </label> */}

                          {inputData?.user_with_participants?.length > 0 && (
                            // !inputData?.participants?.every(
                            //   (participant) => participant?.isCreator === 1
                            // ) && (
                            <select
                              hidden={fromReport}
                              className="select"
                              value={user}
                              onChange={handleUserSelect}
                              disabled={
                                // parseInt(
                                //   sessionStorage.getItem("user_id")
                                // ) !== inputData?.user_id &&
                                // !inputData?.steps[selectedIndex]?.userPID
                                inputData?.steps[selectedIndex]?.participant
                                  ?.userPID !==
                                  parseInt(sessionStorage.getItem("user_id")) &&
                                parseInt(sessionStorage.getItem("user_id")) !==
                                  inputData?.user_id
                                  ? true
                                  : false ||
                                    window.location.href.includes(
                                      "/meetingDetail"
                                    ) ||
                                    fromReport
                                  ? true
                                  : false
                              }
                            >
                              {/* <option value="">
                              {participants?.length === 0
                                ? t("No Guests Available")
                                : t("meeting.newMeeting.Select Guests")}
                            </option> */}
                              <option value="" disabled>
                                {inputData?.user_with_participants?.length === 0
                                  ? t("No Guests Available")
                                  : t("meeting.newMeeting.Select Guests")}
                              </option>
                              {inputData?.user_with_participants
                                // ?.filter(
                                //   (participant) =>
                                //     `${participant.first_name} ${participant.last_name}` !==
                                //     assignUser
                                // )
                                // && participants
                                ?.reduce((uniqueParticipants, item) => {
                                  const isDuplicate = uniqueParticipants.some(
                                    (participant) =>
                                      participant.first_name ===
                                        item.first_name &&
                                      participant.last_name ===
                                        item.last_name &&
                                      participant.email === item.email &&
                                      participant.post === item.post
                                  );

                                  if (!isDuplicate) {
                                    uniqueParticipants.push(item);
                                  }
                                  return uniqueParticipants;
                                }, [])
                                .map((item, index) => {
                                  // if (item?.isCreator === 1) {
                                  //   return;
                                  // }
                                  return (
                                    <>
                                      {(item.first_name === null) &
                                        (item.last_name === null) &&
                                      item.email === null &&
                                      item.post === null ? (
                                        <>
                                          <option value="" disabled>
                                            {t(
                                              "meeting.newMeeting.No Guest Available"
                                            )}
                                          </option>
                                        </>
                                      ) : (
                                        <option key={index} value={item.id}>
                                          {/* {item.first_name} */}
                                          {`${item.first_name} ${item.last_name}`}
                                        </option>
                                      )}
                                    </>
                                  );
                                })}

                              {/* {inputData?.participants &&
                              inputData?.participants?.map((item, index) => (
                                <>
                                  {(item.first_name === null) &
                                    (item.last_name === null) &&
                                  item.email === null &&
                                  item.post === null ? (
                                    <>
                                      <option value="" disabled>
                                        {t(
                                          "meeting.newMeeting.No Guest Available"
                                        )}
                                      </option>
                                    </>
                                  ) : (
                                    <option key={index} value={item.id}>
                                      {`${item.first_name} ${item.last_name}`}
                                    </option>
                                  )}
                                </>
                              ))} */}
                            </select>
                          )}
                        </div>
                        <div className="mt-4 modal-button">
                          {/* Add Step button */}
                          {/* {window.location.href.includes("/meetingDetail") ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                // onClick={handleCopyStep}
                                // onClick={handleEdit}
                                style={{ width: "100%" }}
                                disabled={
                                  fromReport === true ||
                                  window.location.href.includes(
                                    "/meetingDetail"
                                  )
                                    ? true
                                    : false
                                }
                              >
                                {addBtnText}
                              </button>
                            </div>
                          ) : (
                            <>
                              {isAdd ? (
                                <>
                                  <div>
                                    <Button
                                      variant="blue"
                                      disabled
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#3aa5ed",
                                        border: "none",
                                      }}
                                    >
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <button
                                    disabled={isDisabled || fromReport}
                                    hidden={fromReport}
                                    className="btn btn-primary"
                                    onClick={handleAddStep}
                                    style={{ width: "100%" }}
                                  >
                                    {addBtnText}
                                  </button>
                                </div>
                              )}
                            </>
                          )} */}

                          {window.location.href.includes("/meetingDetail") ? (
                            <div>
                              <button
                                //  disabled={isDisabled}
                                className="btn btn-danger"
                                onClick={deleteStep}
                                style={{ width: "100%" }}
                                disabled={
                                  // parseInt(
                                  //   sessionStorage.getItem("user_id")
                                  // ) !== inputData?.user_id &&
                                  // !inputData?.steps[selectedIndex]?.userPID
                                  inputData?.steps[selectedIndex]?.participant
                                    ?.userPID !==
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) &&
                                  parseInt(
                                    sessionStorage.getItem("user_id")
                                  ) !== inputData?.user_id
                                    ? true
                                    : false ||
                                      window.location.href.includes(
                                        "/meetingDetail"
                                      ) ||
                                      fromReport
                                    ? true
                                    : false
                                }
                              >
                                {t("meeting.chart.buttons.delete")}
                              </button>
                            </div>
                          ) : (
                            <>
                              {isDeleted ? (
                                <>
                                  <div>
                                    <Button
                                      variant="dark"
                                      style={{
                                        backgroundColor: "#3aa5ed",
                                        border: "none",
                                      }}
                                      disabled
                                      className="w-100"
                                    >
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <button
                                    disabled={
                                      isDisabled ||
                                      fromReport ||
                                      // (parseInt(
                                      //   sessionStorage.getItem("user_id")
                                      // ) !== inputData?.user_id &&
                                      //   !inputData?.steps[selectedIndex]
                                      //     ?.userPID)
                                      (inputData?.steps[selectedIndex]
                                        ?.participant?.userPID !==
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) &&
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) !== inputData?.user_id)
                                        ? true
                                        : false
                                    }
                                    className="btn btn-danger"
                                    onClick={deleteStep}
                                    style={{ width: "100%" }}
                                    hidden={fromReport}
                                  >
                                    {/* Supprimer */}
                                    {t("meeting.chart.buttons.delete")}
                                  </button>
                                </div>
                              )}
                            </>
                          )}

                          {window.location.href.includes("/meetingDetail") ? (
                            <>
                              <div>
                                {selectedIndex <
                                  inputData?.steps?.length - 1 && (
                                  <button
                                    // disabled={isDisabled}
                                    className="btn btn-primary buttons"
                                    onClick={nextStep}
                                    style={{ width: "100%" }}
                                    // disabled={
                                    //   window.location.href.includes(
                                    //     "/meetingDetail"
                                    //   )
                                    //     ? true
                                    //     : false
                                    // }
                                  >
                                    {/* {nextBtnText} */}
                                    {t("meeting.chart.buttons.validate")}
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            <div>
                              {isEdited &&
                              selectedIndex < inputData?.steps.length - 1 ? (
                                <>
                                  <>
                                    <div>
                                      <Button
                                        variant="dark"
                                        disabled
                                        style={{
                                          backgroundColor: "#3aa5ed",
                                          border: "none",
                                        }}
                                        className="w-100"
                                      >
                                        <Spinner
                                          as="span"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border"
                                        />
                                      </Button>
                                    </div>
                                  </>
                                </>
                              ) : (
                                selectedIndex <
                                  inputData?.steps?.length - 1 && (
                                  <button
                                    disabled={
                                      isDisabled
                                        ? // ||
                                          // (parseInt(
                                          //   sessionStorage.getItem("user_id")
                                          // ) !== inputData?.user_id &&
                                          //   !inputData?.steps[selectedIndex]
                                          //     ?.userPID)
                                          true
                                        : false
                                    }
                                    className="btn btn-primary buttons"
                                    // onClick={handleRightNavigation}
                                    onClick={() => {
                                      if (
                                        fromReport ||
                                        // (parseInt(
                                        //   sessionStorage.getItem("user_id")
                                        // ) !== inputData?.user_id &&
                                        //   !inputData?.steps[selectedIndex]
                                        //     ?.userPID)
                                        (inputData?.steps[selectedIndex]
                                          ?.participant?.userPID !==
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) &&
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) !== inputData?.user_id)
                                      ) {
                                        nextStep();
                                        return;
                                      }
                                      handleEdit();
                                    }}
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {nextBtnText} */}
                                    {t("meeting.chart.buttons.validate")}
                                  </button>
                                )
                              )}
                            </div>
                          )}
                        </div>
                        {window.location.href.includes("/meetingDetail") ? (
                          <>
                            {selectedIndex === inputData?.steps?.length - 1 &&
                            !fromReport ? (
                              <button
                                // disabled={isDisabled}
                                hidden={fromReport}
                                className="mt-3 btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={nextStep}
                              >
                                {/* {validateBtnText} */}
                                {t("meeting.chart.buttons.validate")}
                              </button>
                            ) : (
                              <button
                                // disabled={isDisabled}
                                className="mt-3 btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={closeModal}
                              >
                                {t("meeting.chart.buttons.close")}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {selectedIndex === inputData?.steps?.length - 1 &&
                            !fromReport ? (
                              <>
                                {isEdited ? (
                                  <div>
                                    <Button
                                      variant="dark"
                                      disabled
                                      style={{
                                        backgroundColor: "#3aa5ed",
                                        border: "none",
                                      }}
                                      className="w-100 mt-3"
                                    >
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </Button>
                                  </div>
                                ) : (
                                  <button
                                    disabled={
                                      isDisabled ||
                                      // (parseInt(
                                      //   sessionStorage.getItem("user_id")
                                      // ) !== inputData?.user_id &&
                                      //   !inputData?.steps[selectedIndex]
                                      //     ?.userPID)
                                      (inputData?.steps[selectedIndex]
                                        ?.participant?.userPID !==
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) &&
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) !== inputData?.user_id)
                                        ? true
                                        : false
                                    }
                                    className="mt-3 btn btn-primary"
                                    style={{ width: "100%" }}
                                    onClick={handleEdit}
                                  >
                                    {/* {validateBtnText} */}
                                    {t("meeting.chart.buttons.validate")}
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                disabled={
                                  isDisabled ||
                                  (parseInt(
                                    sessionStorage.getItem("user_id")
                                  ) !== inputData?.user_id &&
                                    !inputData?.steps[selectedIndex]?.userPID)
                                    ? true
                                    : false
                                }
                                className="mt-3 btn btn-danger"
                                style={{ width: "100%" }}
                                onClick={closeModal}
                              >
                                {t("meeting.chart.buttons.cancel")}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {modalType === "Editeur" ? (
                        <div className="col-md-10">
                          {inputData?.steps?.map((step, index) => (
                            <div
                              key={index}
                              style={{
                                display:
                                  index === selectedIndex ? "block" : "none",
                              }}
                            >
                              <Editor
                                disabled={
                                  fromReport ||
                                  // (parseInt(
                                  //   sessionStorage.getItem("user_id")
                                  // ) !== inputData?.user_id &&
                                  //   !inputData?.steps[selectedIndex]?.userPID)
                                  (inputData?.steps[selectedIndex]?.participant
                                    ?.userPID !==
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) &&
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) !== inputData?.user_id)
                                    ? true
                                    : false
                                }
                                apiKey={TINYMCEAPI}
                                value={modifiedFileText[index]}
                                name="text"
                                init={{
                                  selector: "dfree-body",
                                  statusbar: false,
                                  branding: false,
                                  height: 600,
                                  menubar: true,
                                  language: "fr_FR",
                                  plugins:
                                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern quickbars",
                                  toolbar:
                                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | image | imagePicker link media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img { max-width: 100%; height: 370px; }",
                                  file_picker_types: "image",
                                  importcss_append: true,
                                  quickbars_insert_toolbar:
                                    "image media quicktable",
                                  quickbars_selection_toolbar:
                                    "bold italic underline | blocks | blockquote quicklink",

                                  images_upload_handler:
                                    image_upload_handler_callback,
                                }}
                                onEditorChange={(content) => {
                                  const updatedModifiedFileText = [
                                    ...modifiedFileText,
                                  ];
                                  updatedModifiedFileText[index] = content;
                                  setModifiedFileText(updatedModifiedFileText);
                                }}
                                onInit={(evt, editor) => {
                                  editorRef.current = editor;
                                }}
                                onNodeChange={(e) => {
                                  if (
                                    e &&
                                    e.element.nodeName.toLowerCase() == "img"
                                  ) {
                                    editorRef.current.dom.setAttribs(
                                      e.element,
                                      {
                                        width: "700px",
                                        height: "394px",
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          ))}
                          <div></div>
                        </div>
                      ) : modalType === "File" ? (
                        <>
                          <div className="col-md-10">
                            {!isUpload ? (
                              <>
                                <div
                                  className={`d-flex align-items-center gap-4 ${
                                    fileName ? "" : "h-100"
                                  }`}
                                >
                                  <div
                                    {...getRootProps()}
                                    style={{
                                      border: "1px solid #cccccc",
                                      padding: "5px 7px",
                                      width: fileName ? "auto" : "100%", // Set width to auto when a file is uploaded
                                      borderRadius: "6px",
                                      outline: "none",
                                      margin: fileName ? "" : "0 auto",
                                      height: "100%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <input
                                      {...getInputProps()}
                                      disabled={
                                        // parseInt(
                                        //   sessionStorage.getItem("user_id")
                                        // ) !== inputData?.user_id &&
                                        // !inputData?.steps[selectedIndex]
                                        //   ?.userPID
                                        inputData?.steps[selectedIndex]
                                          ?.participant?.userPID !==
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) &&
                                        parseInt(
                                          sessionStorage.getItem("user_id")
                                        ) !== inputData?.user_id
                                          ? true
                                          : false
                                      }
                                    />
                                    {isUpload ? (
                                      <p>Uploading...</p>
                                    ) : fileName ? (
                                      <div>Selected file: {fileName}</div>
                                    ) : (
                                      <p
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "inherit",
                                        }}
                                      >
                                        Drag 'n' drop files here, or click to
                                        select files
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {fileName && (
                                  <div className="mt-2">
                                    <div className="pdf-preview">
                                      <iframe
                                        title="PDF Preview"
                                        // src={Assets_URL + fileName}
                                        src={`${Assets_URL}/${fileName}#toolbar=0&view=fitH`}
                                        width="100%"
                                        height="500px"
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <Spinner
                                  animation="border"
                                  role="status"
                                  className="center-spinner"
                                ></Spinner>
                              </>
                            )}
                          </div>
                        </>
                      ) : modalType === "Url" ? (
                        <>
                          <div className="col-md-10">
                            <div className="box">
                              <input
                                type="text"
                                placeholder="https://www.google.com"
                                value={link}
                                onChange={handleLinkUpload}
                                style={{ width: "50%" }}
                                name="url"
                              />
                              {/* <div className="text-center">
                                <button
                                  disabled={isDisabled}
                                  className="my-3 btn btn-danger"
                                  onClick={previewUrlResult}
                                >
                                  Afficher la page
                                </button>
                              </div> */}
                              {showPreview && (
                                <div className="preview-container mt-5">
                                  <iframe
                                    title="Preview"
                                    src={previewUrl}
                                    width="100%"
                                    height="500px"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {doneMomentsModal && (
        <>
          <Modal
            show={doneMomentsModal}
            onHide={() => setDoneMomentsModal(false)}
            size="xl"
            centered
            className="modal-fade report-steps-note-modal"
          >
            <Modal.Body
              style={{
                padding: "25px 53px",
              }}
            >
              <div>
                {meeting?.steps[selectedIndex]?.editor_type === "File" ? (
                  <div>
                    <iframe
                      src={
                        Assets_URL +
                        "/" +
                        (meeting?.steps[selectedIndex]?.file +
                          "#toolbar=0&view=fitH")
                      }
                      width="100%"
                      height="500px"
                    />
                  </div>
                ) : (
                  <div
                    className="rendered-content-report-step-chart"
                    dangerouslySetInnerHTML={{
                      __html:
                        (inputData &&
                          meeting?.steps &&
                          meeting?.steps[selectedIndex]?.editor_content !==
                            null) ||
                        meeting?.steps[selectedIndex]?.editor_content !== ""
                          ? meeting?.steps[selectedIndex]?.editor_content
                          : " ",
                    }}
                  />
                )}
                <h3>{meeting?.steps[selectedIndex]?.title}</h3>
                {/* <p>{selectedBar.description}</p> */}
                {/* <div dangerouslySetInnerHTML={{__html: selectedBar.content}} / */}
              </div>
              <div>
                {/* <div
                    className="notes-section d-flex justify-content-between align-items-center "
                    style={{ margin: "5px 0px 10px 0px", fontSize: "18px" }}
                  >
                    <h5></h5>
                    <span>
                      <LuFileEdit
                        className="eye-icon"
                        // color="#20acd4"
                        color={open ? "#20acd4" : "black"}
                        size={18}
                        style={{
                          margin: "2px",
                        }}
                        onClick={handleShow}
                      />
                    </span>
                  </div> */}

                {/* {open ? ( */}
                <>
                  <Editor
                    onBlur={(value) => {
                      console.log("value", value);
                    }}
                    disabled
                    key={selectedIndex}
                    apiKey={TINYMCEAPI}
                    value={meeting?.steps[selectedIndex].note}
                    // value={item?.note}
                    init={{
                      statusbar: false,
                      branding: false,
                      height: 400,
                      menubar: true,
                      language: "fr_FR",
                      // language: "en_EN",
                      plugins:
                        "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                      toolbar:
                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                      image_advtab: true,
                      file_picker_types: "image",

                      file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "image") {
                          const input = document.createElement("input");
                          input.setAttribute("type", "file");
                          input.setAttribute("accept", "image/*");

                          input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();

                            reader.onload = function (e) {
                              const img = new Image();
                              img.src = e.target.result;

                              img.onload = function () {
                                const canvas = document.createElement("canvas");
                                const ctx = canvas.getContext("2d");
                                const maxWidth = 700;
                                const maxHeight = 394;

                                let newWidth = img.width;
                                let newHeight = img.height;

                                if (img.width > maxWidth) {
                                  newWidth = maxWidth;
                                  newHeight =
                                    (img.height * maxWidth) / img.width;
                                }

                                if (newHeight > maxHeight) {
                                  newHeight = maxHeight;
                                  newWidth =
                                    (img.width * maxHeight) / img.height;
                                }

                                canvas.width = newWidth;
                                canvas.height = newHeight;

                                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                                const resizedImageData = canvas.toDataURL(
                                  file.type
                                );

                                // Pass the resized image data to the callback function
                                callback(resizedImageData, {
                                  alt: file.name,
                                });
                              };

                              img.src = e.target.result;
                            };

                            reader.readAsDataURL(file);
                          };

                          input.click();
                        }
                      },
                    }}
                    // onEditorChange={(value) => {
                    //   setNotes(value);
                    // }}
                    // onEditorChange={(value) => {
                    //   if (value !== stepNotes[selectedIndex]) {
                    //     setStepNotes((prev) => {
                    //       const newStepNotes = [...prev];
                    //       newStepNotes[selectedIndex] = value;
                    //       return newStepNotes;
                    //     });
                    //   }
                    // }}
                    // onEditorChange={(value) => {
                    //   setStepNotes((prev) => {
                    //     let newStepNotes = [...prev];
                    //     newStepNotes[selectedIndex] = value;
                    //     return newStepNotes;
                    //   });
                    // }}
                  />
                </>
                {/* // ) : (
                  //   <>
                  //     <div
                  //       dangerouslySetInnerHTML={{
                  //         __html: inputData?.steps[selectedIndex]?.note,
                  //       }}
                  //     ></div>
                  //   </>
                  // )} */}
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                //   variant={open ? "primary" : "secondary"}
                variant="danger"
                // className='save-note-btn'
                onClick={() => setDoneMomentsModal(false)}
              >
                {t("buttons.cancel")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        // <div className="new-meeting">
        //   <StepEditChart
        //   meetingId={meeting1?.id}
        //   show={isModalOpen}
        //   stepId={stepId}
        //   stepIndex={stepIndex}
        //   closeModal={handleCloseModal}
        // />
        // </div>
      )}
    </>
  );
};

export default InviteStepChart;
