import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScheduledMeeting from "./ScheduledMeeting";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
// import CompleteMeeting from "./CompleteMeeting";
import axios from "axios";
import { Button } from "react-bootstrap";
import DraftMeetings from "./DraftMeetings";
import CompletedMeetings from "./CompletedMeetings";
import { Header } from "antd/es/layout/layout";
import { useMeetings } from "../../../context/MeetingsContext";
import moment from "moment";
import { useTabs } from "../../../context/TabContext";
// import NewMeetingModal from "./NewMeetingModal/NewMeetingModal";
import { API_BASE_URL } from "../../Apicongfig";

/**
 * Represents a component for managing meeting tabs.
 * @component
 * @example
 * return (
 *   <MeetingTabs />
 * )
 */

function MeetingTabs() {
  const { searchTerm } = useOutletContext();
  console.log("search term", searchTerm);
  const navigate = useNavigate();
  const onChange = (key) => {
    console.log(key);
  };
  /**
   * Custom hook for managing header title.
   * @name useHeaderTitle
   * @type {Object}
   * @property {string} title - The title of the header.
   * @property {function} pushHeaderTitle - Function to push a new title to the header.
   * @property {function} popHeaderTitle - Function to pop the last title from the header.
   * @property {function} resetHeaderTitle - Function to reset the header title.
   */
  const { resetHeaderTitle } = useHeaderTitle();
  /**
   * Resets the header title on component mount.
   */
  React.useEffect(() => {
    resetHeaderTitle();
  }, []);
  const location = useLocation();
  const [t] = useTranslation("global");
  // const [activeTab, setActiveTab] = useState("Evènements programmés");
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  const queryParams = new URLSearchParams(location.search);
  const fromPresentation2 = queryParams.get("from") === "presentation";
  const fromPresentation3 = queryParams.get("from") === "completeedit";

  /**IF LAST URL IS PLAY THEN NAVIGATE TO TERMINATED MEETINGS TAB. */
  const lastUrl = localStorage.getItem("lastURL");
  const { allMeetings, getMeetings, getClosedMeetings, allClosedMeetings} = useMeetings();
  // const [allClosedMeetings, setAllClosedMeetings] = useState([]);
  console.log("allmeetings->tabs", allMeetings);
  console.log("allClosedMeetings", allClosedMeetings);

  // useEffect(() => {
  //   const getClosedMeetings = async () => {
  //     try {
  //       // setIsLoading(true);
  //       const response = await axios.get(`${API_BASE_URL}/closed/meetings`, {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       });
  //       if (response.status) {
  //         setAllClosedMeetings(response?.data?.data);
  //         // setIsLoading(false);
  //       }
  //     } catch (error) {
  //       // setIsLoading(false);
  //     }
  //   };
  //   getClosedMeetings();
  // }, []);
  useEffect(() => {
    setActiveTab("tab2");
    if (fromPresentation2) {
      setActiveTab("tab3");
    }
    if (fromPresentation3) {
      setActiveTab("Evènements terminés");
    }
    if (lastUrl === "/play") {
      setActiveTab("tab3");
      localStorage.removeItem("lastURL");
    }
    getMeetings();
    getClosedMeetings();
  }, []);

  // Filter meetings to exclude drafts
  const activeMeetingsCount = allMeetings?.filter(
    (meeting) => meeting.status !== "draft"
  ).length;
  // Filter meetings to exclude active,inprogress and complete
  const draftMeetingsCount = allMeetings?.filter(
    (meeting) => meeting.status === "draft"
  ).length;

  const completedMeetingsCount = allClosedMeetings?.filter(
    (meeting) => meeting.status === "closed" || meeting.status === "abort"
    // meeting.status === "in_progress"
  ).length;

  const tabsRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  // const [activeTab, setActiveTab] = useState("tab2");
  const { activeTab, setActiveTab } = useTabs();

  const handleScroll = () => {
    if (tabsRef.current) {
      setIsSticky(window.scrollY > 100);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filteredMeetings, setFilteredMeetings] = useState(allMeetings);
  const [draftMeetings, setDraftMeetings] = useState(allMeetings);
  console.log("filteredMeetings-> search", filteredMeetings);
  const [closedFilteredMeetings, setClosedFilteredMeetings] =
    useState(allClosedMeetings);

  const [isInviteSelected, setIsInviteSelected] = useState(false);
  const [isMyMeetingsSelected, setIsMyMeetingsSelected] = useState(false);
  const [isAllMeetingsSelected, setIsAllMeetingsSelected] = useState(false);
  const [isClosedInviteSelected, setIsClosedInviteSelected] = useState(false);
  const [isMyClosedMeetingsSelected, setIsClosedMyMeetingsSelected] =
    useState(false);

  const dropdownRef = useRef(null);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  useEffect(() => {
    setIsMyMeetingsSelected(true);
    setIsClosedMyMeetingsSelected(true);
    const now = moment();
    let filtered = [...allMeetings];

    if (selectedFilter === "thisMonth") {
      filtered = filtered.filter((meeting) =>
        moment(meeting.date).isSame(now, "month")
      );
    } else if (selectedFilter === "previousMonth") {
      const previousMonth = moment().subtract(1, "month");
      filtered = filtered.filter((meeting) =>
        moment(meeting.date).isSame(previousMonth, "month")
      );
    } else if (selectedFilter === "previousYear") {
      const previousYear = moment().subtract(1, "year");
      filtered = filtered.filter((meeting) =>
        moment(meeting.date).isSame(previousYear, "year")
      );
    }

    // Further filter the meetings based on status and user_id
    filtered = filtered.filter(
      (meeting) =>
        meeting.status === "active" || meeting.status === "in_progress"
      //  &&  meeting.user_id === userId
    );
    // let myClosedMeetings = allClosedMeetings.filter(
    //   (meeting) => meeting.user_id === userId
    // );
    let myClosedMeetings = [...allClosedMeetings];

    let draftMeetings = allMeetings?.filter(
      (meeting) => meeting.status === "draft"
    );
    if (searchTerm) {
      filtered = filtered.filter((meeting) =>
        meeting.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      myClosedMeetings = myClosedMeetings.filter((meeting) =>
        meeting.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      draftMeetings = draftMeetings?.filter(
        (meeting) =>
          meeting?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          meeting?.objective?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setDraftMeetings(draftMeetings);
    setFilteredMeetings(filtered);
    setClosedFilteredMeetings(myClosedMeetings);
  }, [selectedFilter, allMeetings, userId, allClosedMeetings, searchTerm]);

  // useEffect(() => {
  //   if (dropdownVisible) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [dropdownVisible]);

  // const showOtherUserMeetings = () => {
  //   const otherUserMeetings = allMeetings.filter(
  //     (meeting) => meeting.user_id !== userId
  //   );
  //   setFilteredMeetings(otherUserMeetings);
  //   setIsMyMeetingsSelected(false);
  //   setIsAllMeetingsSelected(false)
  //   setIsInviteSelected(true); // Mark the invite button as selected
  // };
  // const showMyMeetings = () => {
  //   const myMeetings = allMeetings.filter(
  //     (meeting) => meeting.user_id === userId
  //   );

  //   setFilteredMeetings(myMeetings);
  //   setIsAllMeetingsSelected(false)
  //   setIsInviteSelected(false); // Mark the invite button as selected
  //   setIsMyMeetingsSelected(true);
  // };
  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setDropdownVisible(false);
  //   }
  // };

  // const showMyClosedMeetings = () => {
  //   const myClosedMeetings = allClosedMeetings.filter(
  //     (meeting) => meeting.user_id === userId
  //   );
  //   setClosedFilteredMeetings(myClosedMeetings);
  //   setIsAllMeetingsSelected(false)
  //   setIsMyMeetingsSelected(false);
  //   setIsInviteSelected(false); // Mark the invite button as selected
  //   setIsClosedInviteSelected(false); // Mark the invite button as selected
  //   setIsClosedMyMeetingsSelected(true); // Mark the invite button as selected
  // }

  // const showOhterClosedMeetings = () => {
  //   const otherClosedMeetings = allClosedMeetings.filter(
  //     (meeting) => meeting.user_id !== userId
  //   );
  //   setClosedFilteredMeetings(otherClosedMeetings);
  //   setIsMyMeetingsSelected(false);
  //   setIsAllMeetingsSelected(false)
  //   setIsInviteSelected(false); // Mark the invite button as selected
  //   setIsClosedMyMeetingsSelected(false); // Mark the invite button as selected
  //   setIsClosedInviteSelected(true); // Mark the invite button as selected
  // }
  // const showAllMoments = () => {
  //   setFilteredMeetings(allMeetings);
  //   setClosedFilteredMeetings(allClosedMeetings);
  //   setIsInviteSelected(false);
  //   setIsMyMeetingsSelected(false);
  //   setIsClosedMyMeetingsSelected(false);
  //   setIsClosedInviteSelected(false);
  //   setIsAllMeetingsSelected(true);
  //   // setIsAllMomentsSelected(true);
  // };

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="tabs-container container-fluid">
        <div
          ref={tabsRef}
          className={`tabs-header ${isSticky ? "sticky" : ""}`}
        >
          <h4 className="meeting-title">{t("meeting.title")}</h4>
          <div   style={{ padding: isSticky ? '' : '4px 11px', display: 'flex', flexDirection: 'row' }}
          >
            <div
              className="col-md-11"
              style={{ borderBottom: "2px solid #F2F2F2" }}
            >
              <div className="tabs">
                <div>
                  <button
                    className={`tab ${activeTab === "tab1" ? "active" : ""}`}
                    onClick={() => setActiveTab("tab1")}
                  >
                    {t("meeting.draftTab")}
                    <span className={activeTab === "tab1" ? "future" : "draft"}>
                      {draftMeetingsCount}
                    </span>
                  </button>
                  <button
                    className={`tab ${activeTab === "tab2" ? "active" : ""}`}
                    onClick={() => setActiveTab("tab2")}
                  >
                    {t("meeting.activeMeetingsTab")}
                    <span className={activeTab === "tab2" ? "future" : "draft"}>
                      {activeMeetingsCount}
                    </span>
                  </button>
                  <button
                    className={`tab ${activeTab === "tab3" ? "active" : ""}`}
                    onClick={() => setActiveTab("tab3")}
                  >
                    {t("meeting.completedMeetingsTab")}
                    <span className={activeTab === "tab3" ? "future" : "draft"}>
                      {completedMeetingsCount}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`col-md-1 d-flex  ${
                isSticky ? "sticky-button " : " justify-content-end"
              }`}
            >
              {/* <Link to="/meeting/new" className="moment-btn-text"> */}
              <button
                className={`btn moment-btn`}
                // style={{ padding: isSticky ? "11px 28px 11px 23px" : ' 0px 25px 0px 20px' }}
                onClick={() => navigate("/meeting/new")}
                // onClick={() => setOpen(true)}
              >
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 14.75V1.25M1.25 8H14.75"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                &nbsp;{t("meeting.buttons.create")}
              </button>
              {/* </Link> */}
            </div>
          </div>

          {/* {open && <NewMeetingModal show={open} />} */}
          {/* {activeTab === "tab2" && (
            <div className="row mt-3">
              <div className="sub-tabs d-flex gap-3">
                <button
                  onClick={showOtherUserMeetings}
                  className={isInviteSelected ? "selected" : ""}
                >
                  {t("meeting.buttons.invite")}
                </button>

                <div className="d-flex align-items-center">
                  <div className="dropdown-container" ref={dropdownRef}>
                    <button
                      onClick={showMyMeetings}
                      className={isMyMeetingsSelected ? "selected" : ""}
                    >
                      {t("meeting.buttons.moments")}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="dropdown-container" ref={dropdownRef}>
                    <button
                      onClick={showAllMoments}
                      className={isAllMeetingsSelected ? "selected" : ""}
                    >
                      {t("meeting.buttons.allMoments")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {/* {activeTab === "tab3" && (
            <div className="row mt-3">
              <div className="sub-tabs d-flex gap-3">
                <button
                  onClick={showOhterClosedMeetings}
                  className={isClosedInviteSelected ? "selected" : ""}
                >
                  {t("meeting.buttons.invite")}
                </button>

                <div className="d-flex align-items-center">
                  <div className="dropdown-container" ref={dropdownRef}>
                    <button
                      onClick={showMyClosedMeetings}
                      className={isMyClosedMeetingsSelected ? "selected" : ""}
                    >
                      {t("meeting.buttons.moments")}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="dropdown-container" ref={dropdownRef}>
                    <button
                      onClick={showAllMoments}
                      className={isAllMeetingsSelected ? "selected" : ""}
                    >
                      {t("meeting.buttons.allMoments")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div className="content">
          {activeTab === "tab1" && (
            <div>
              <DraftMeetings allMeetings={draftMeetings} />
            </div>
          )}
          {activeTab === "tab2" && (
            <div>
              <ScheduledMeeting allMeetings={filteredMeetings} />
            </div>
          )}
          {activeTab === "tab3" && (
            <div>
              <CompletedMeetings allClosedMeetings={closedFilteredMeetings} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MeetingTabs;
