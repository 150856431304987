import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./style/main.scss";
// import "./style/LandindPages.scss"
import { HeaderTitleProvider } from "./context/HeaderTitleContext";
import { DraftMeetingsProvider } from "./context/DraftMeetingContext";
import { TotalTimeProvider } from "./context/TotalTimeContext";
import { StepProvider } from "./context/stepContext";
import { StepProvider1 } from "./context/Step";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MeetingsProvider } from "./context/MeetingsContext";
import { TabsProvider } from "./context/TabContext";
import { UserProvider } from "./context/UserContext";
import { DestinationTabsProvider } from "./context/DestinationTabContext";
import { MeetingTabsProvider } from "./context/MeetingTabsContext";
import { SidebarProvider } from "./context/SidebarContext";
import { DestinationsProvider } from "./context/DestinationsContext";
// import { ChartProvider } from "./context/ChartContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="50204800014-ldiqa8nnkv4d8jukk59d6qkgpi0bsljt.apps.googleusercontent.com">
    {/* <ChartProvider> */}

    <SidebarProvider>
      <DestinationsProvider>
        <MeetingsProvider>
          <TabsProvider>
            <MeetingTabsProvider>
              <DestinationTabsProvider>
                <UserProvider>
                  <StepProvider1>
                    <StepProvider>
                      <TotalTimeProvider>
                        <DraftMeetingsProvider>
                          <HeaderTitleProvider>
                            <BrowserRouter>
                              <App />
                            </BrowserRouter>
                          </HeaderTitleProvider>
                        </DraftMeetingsProvider>
                      </TotalTimeProvider>
                    </StepProvider>
                  </StepProvider1>
                </UserProvider>
              </DestinationTabsProvider>
            </MeetingTabsProvider>
          </TabsProvider>
        </MeetingsProvider>
      </DestinationsProvider>
    </SidebarProvider>
    {/* </ChartProvider> */}
  </GoogleOAuthProvider>
);
